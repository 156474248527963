import { Component, NgZone } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { Tags } from '../common/tags';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';

@Component({
  selector: 'app-codconfirmation-success',
  templateUrl: './codconfirmation-success.component.html',
  styleUrls: ['./codconfirmation-success.component.css']
})
export class CODConfirmationSuccessComponent {

  applicationNumber : string = "";
  passcode : string = "";

  constructor(private router: Router, private ngZone: NgZone, private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, private toast: Toast, public constants: Constants, 
              private loaderService: LoaderService, private tags: Tags, public utils: Utils) { 
    activatedRoute.params.subscribe((params: Params) => {
      this.applicationNumber = this.utils.decryptString(params['applicationNumber']);
      this.passcode = this.utils.decryptString(params['passcode']);
    });
  }

  openCODMainPage(){
    this.ngZone.run(()=>this.router.navigate(["main"]));
  }

  printApplication(){
    window.print();
  }

}