<app-header></app-header>

<div class="banner-img-name"></div>

<div class="main">
  <h3 class="w100" style="font-size:20px;">Make Payment</h3>
    <form action="" method="post" name="paymentForm" #paymentForm="ngForm" (ngSubmit)="paymentForm.form.valid && makePayment()" autocomplete="off" id="msform1" style="width: 100%; float: left;">
        <input type="hidden" name="applicationId" value="{{applicationPaymentDetailsObject.applicationId}}">
        <input type="hidden" name="currencyCode" value="{{applicationPaymentDetailsObject.currencyCode}}">
        <input type="hidden" name="totalAmount" value="{{applicationPaymentDetailsObject.totalFee}}">
        <input type="hidden" name="transactionFee" value="{{applicationPaymentDetailsObject.transactionFee}}">
        <input type="hidden" name="serviceCharge" value="{{applicationPaymentDetailsObject.serviceCharge}}">
        <input type="hidden" name="paymentMode" value="{{paymentFormObject.paymentMethod}}">
        <input type="hidden" name="paymentType" value="{{utils.decryptString(encryptedPaymentType)}}">
        <input type="hidden" name="authToken" value="">

        <div class="pay-info">
            <p>Application Number <span style="color:green;">{{applicationPaymentDetailsObject.applicationId}}</span></p>
            <p>|</p>
            <p>{{referenceLabel}} <span style="color:green;">{{applicationPaymentDetailsObject.referenceNumber}}</span></p>
        </div>

        <div class="box_row">
            <div class="grid-two">

                <div class="passport_fees">
                    <h4>Fee Detail</h4>
                    <table>
                        <tr>
                            <td>Applicant Name</td>
                            <td style="text-align:right;"><b>{{applicationPaymentDetailsObject.fullName}}</b></td>
                        </tr>

                        <tr>
                            <td>Passport Type</td>
                            <td style="text-align:right;"><b>{{applicationPaymentDetailsObject.passportType}}</b></td>
                        </tr>

                        <tr>
                            <td>Application Fee</td>
                            <td style="text-align:right;"><b>{{applicationPaymentDetailsObject.currencySymbol}} {{applicationPaymentDetailsObject.applicationFee | number : '1.2' : 'en-US'}}</b></td>
                        </tr>

                        <tr *ngIf="applicationPaymentDetailsObject.addressVerificationFee > 0">
                            <td>Address Verification Fee</td>
                            <td style="text-align:right;"><b>{{applicationPaymentDetailsObject.currencySymbol}} {{applicationPaymentDetailsObject.addressVerificationFee | number : '1.2' : 'en-US'}}</b></td>
                        </tr>

                        <tr *ngIf="applicationPaymentDetailsObject.addressVerificationServiceCharge > 0">
                            <td>Address Verification Service Charge</td>
                            <td style="text-align:right;"><b>{{applicationPaymentDetailsObject.currencySymbol}} {{applicationPaymentDetailsObject.addressVerificationServiceCharge | number : '1.2' : 'en-US'}}</b></td>
                        </tr>

                        <tr>
                            <td>Transaction Fee ({{utils.isEmpty(paymentFormObject.paymentMethod) === true ? 'Select gateway & mode' : applicationPaymentDetailsObject.paymentMethodLabel}})</td>
                            <td style="text-align:right;"><b>{{applicationPaymentDetailsObject.currencySymbol}} {{applicationPaymentDetailsObject.transactionFee | number : '1.2' : 'en-US'}}</b></td>
                        </tr>

                        <tr *ngIf="applicationPaymentDetailsObject.serviceCharge > 0">
                            <td>Service Charge</td>
                            <td style="text-align:right;"><b>{{applicationPaymentDetailsObject.currencySymbol}} {{applicationPaymentDetailsObject.serviceCharge | number : '1.2' : 'en-US'}}</b></td>
                        </tr>

                        <tr style="border-top:1px solid #cacbca; font-size:18px;">
                            <td>Total Fee</td>
                            <td style="text-align:right;"><b>{{applicationPaymentDetailsObject.currencySymbol}} {{applicationPaymentDetailsObject.totalFeeIncludeServiceFee | number : '1.2' : 'en-US'}}</b></td>
                        </tr>
                    </table>

                    <p class="gateway-fee-note"><b>Note:</b> There might be additional Payment Gateway fees.</p>

                </div>

                <div style="padding:10px 20px 5px 0;">

                    <h4>Choose Payment Method</h4>

                    <div class='faq'>

                        <ng-container *ngIf="isV2Enable === true">

                            <ng-container *ngFor="let gatewayObject of gatewayDetailsList;">

                                <input type='checkbox' class="gateway" [id]='gatewayObject.key' checked="checked" disabled="disabled">

                                <label [for]='gatewayObject.key' class="faq-l">
                                    <p class="faq-heading">{{gatewayObject.label}}</p>

                                    <div class='faq-arrow'>
                                        <img [src]="gatewayObject.imageName" [ngStyle]="{'width': gatewayObject.key === constants.PAYFIXY ? '110px' : '85px'}">
                                    </div>
                                    <!-- <div class='faq-arrow'>
                                        <img [src]="gatewayObject.imageName">
                                    </div> -->

                                    <p class="faq-text">
                                        <ng-container *ngFor="let gatewayItemObject of gatewayObject.items;">
                                            <input type="radio" name="paymentMethod" [(ngModel)]="paymentFormObject.paymentMethod" #paymentMethod="ngModel" [value]="gatewayItemObject.paymentMethod" (ngModelChange)="setTransactionFee(); setIsSubmitForm(gatewayItemObject.isSubmitReq);" required="required" [id]="gatewayItemObject.paymentMethod">
                                            <label [for]="gatewayItemObject.paymentMethod"> {{gatewayItemObject.paymentMethodLabel}}</label>
                                            <span *ngIf="gatewayItemObject.lastItem === true && paymentForm.submitted && paymentMethod.invalid" class="alert alert-danger">
                                                <span *ngIf="paymentMethod.errors.required">
                                                    Payment Method is required!
                                                </span>
                                            </span>
                                        </ng-container>
                                    </p>
                                </label>

                            </ng-container>

                        </ng-container>

                        <ng-container *ngIf="isV2Enable === false">

                            <ng-container *ngIf="isEnableIOPGateway === true">
                                <input type='checkbox' class="gateway" [id]='constants.INNOVATE1PAY' checked="checked" disabled="disabled">
                                <label [for]='constants.INNOVATE1PAY' class="faq-l">
                                    <p class="faq-heading">Innovate1Pay</p>
                                    <div class='faq-arrow'><span><img src="assets/images/innovate_OLD.png"></span></div>
                                    <!-- <p class="faq-heading">Payfixy</p>
                                    <div class='faq-arrow' style="padding-top:10px;"><span><img src="assets/images/innovate.png"></span></div> -->
                                    <p class="faq-text">
                                        <span *ngFor="let iopPaymentMethod of iopPaymentMethodsArray;">
                                            <input type="radio" name="paymentMethod" [(ngModel)]="paymentFormObject.paymentMethod" #paymentMethod="ngModel" [value]="iopPaymentMethod.key" (ngModelChange)="fetchTransactionCharges(iopPaymentMethodsArray); setIsSubmitForm(iopPaymentMethod.isSubmitReq);" [id]="iopPaymentMethod.key" required="required" />
                                            <label [for]="iopPaymentMethod.key">{{iopPaymentMethod.value}}</label>
                                        </span>
                                    </p>
                                </label>
                            </ng-container>

                            <ng-container *ngIf="isEnableSanaPayGateway === true">
                                <input type='checkbox' class="gateway" [id]='constants.SANAPAY' checked="checked" disabled="disabled">
                                <label [for]='constants.SANAPAY' class="faq-l">
                                    <p class="faq-heading">SaanaPay</p>
                                    <div class='faq-arrow'><span><img src="assets/images/saanapay.png"></span></div>
                                    <p class="faq-text">
                                        <span *ngFor="let sanaPayPaymentMethod of sanaPayPaymentMethodsArray;">
                                            <input type="radio" name="paymentMethod" [(ngModel)]="paymentFormObject.paymentMethod" #paymentMethod="ngModel" [value]="sanaPayPaymentMethod.key" (ngModelChange)="fetchTransactionCharges(sanaPayPaymentMethodsArray); setIsSubmitForm(sanaPayPaymentMethod.isSubmitReq);" [id]="sanaPayPaymentMethod.key" required="required" />
                                            <label [for]="sanaPayPaymentMethod.key">{{sanaPayPaymentMethod.value}}</label>
                                        </span>
                                    </p>
                                </label>
                            </ng-container>

                            <ng-container *ngIf="isEnableUnifiedGateway === true">
                                <input type='checkbox' class="gateway" [id]='constants.UNIFIED' checked="checked" disabled="disabled">
                                <label [for]='constants.UNIFIED' class="faq-l">
                                    <p class="faq-heading">Unified Payments</p>
                                    <div class='faq-arrow'><span><img src="assets/images/PayArena.png"></span></div>
                                    <p class="faq-text">
                                        <span *ngFor="let unifiedPaymentMethod of unifiedPaymentMethodsArray;">
                                            <input type="radio" name="paymentMethod" [(ngModel)]="paymentFormObject.paymentMethod" #paymentMethod="ngModel" [value]="unifiedPaymentMethod.key" (ngModelChange)="fetchTransactionCharges(unifiedPaymentMethodsArray); setIsSubmitForm(unifiedPaymentMethod.isSubmitReq);" [id]="unifiedPaymentMethod.key" required="required" />
                                            <label [for]="unifiedPaymentMethod.key">{{unifiedPaymentMethod.value}}</label>
                                        </span>
                                    </p>
                                </label>
                            </ng-container>

                            <ng-container *ngIf="isEnableTeasyGateway === true">
                                <input type='checkbox' class="gateway" [id]='constants.TEASY' checked="checked" disabled="disabled">
                                <label [for]='constants.TEASY' class="faq-l">
                                    <p class="faq-heading">TeasyPay</p>
                                    <div class='faq-arrow'><span><img src="assets/images/teasy.jpg"></span></div>
                                    <p class="faq-text">
                                        <span *ngFor="let teasyPaymentMethod of teasyPaymentMethodsArray;">
                                            <input type="radio" name="paymentMethod" [(ngModel)]="paymentFormObject.paymentMethod" #paymentMethod="ngModel" [value]="teasyPaymentMethod.key" (ngModelChange)="fetchTransactionCharges(teasyPaymentMethodsArray); setIsSubmitForm(teasyPaymentMethod.isSubmitReq);" [id]="teasyPaymentMethod.key" required="required" />
                                            <label [for]="teasyPaymentMethod.key">{{teasyPaymentMethod.value}}</label>
                                        </span>
                                    </p>
                                </label>
                            </ng-container>

                            <ng-container *ngIf="isEnablePaystackGateway === true">
                                <input type='checkbox' class="gateway" [id]='constants.PAYSTACK' checked="checked" disabled="disabled">
                                <label [for]='constants.PAYSTACK' class="faq-l">
                                    <p class="faq-heading">Paystack</p>
                                    <div class='faq-arrow'><span><img src="assets/images/paystack.jpeg"></span></div>
                                    <p class="faq-text">
                                        <span *ngFor="let paystackPaymentMethod of paystackPaymentMethodsArray;">
                                            <input type="radio" name="paymentMethod" [(ngModel)]="paymentFormObject.paymentMethod" #paymentMethod="ngModel" [value]="paystackPaymentMethod.key" (ngModelChange)="fetchTransactionCharges(paystackPaymentMethodsArray); setIsSubmitForm(paystackPaymentMethod.isSubmitReq);" [id]="paystackPaymentMethod.key" required="required" />
                                            <label [for]="paystackPaymentMethod.key">{{paystackPaymentMethod.value}}</label>
                                        </span>
                                    </p>
                                </label>
                            </ng-container>

                            <span class="alert alert-danger" role="alert" *ngIf="paymentForm.submitted">
                                <span *ngIf="utils.isEmpty(paymentFormObject.paymentMethod) === true">
                                    Payment Method is required!
                                </span>
                            </span>

                        </ng-container>

                    </div>
                </div>

            </div>
        </div>

        <div class="imp_info mt20 w100">
            <div  class="element">
                <h4>Important Instructions</h4>
                <ol  style="padding-left: 15px;">
                    <li>This application will expire after 90 days from {{applicationPaymentDetailsObject.applicationDate}}. Please make payment before that.</li>
                    <li>Select gateway & mode at the right side.</li>
                    <li>Transaction fee depends on gateway & mode of payment.</li>
                    <li>If you are selecting cash @ bank or POS as payment mode, please print acknowledgement slip. Note the expiry date of acknowledgement slip. </li>
                    <li>Multiple acknowledgement slips can be generated till application expires. </li>
                </ol>
            </div>
        </div>

        <div style="text-align: center; margin-bottom: 40px;">
            <input type="button" (click)="backToApplicationView()" value="Cancel" class="previous action-button-previous back_btn">
            <input type="submit" value="Pay {{applicationPaymentDetailsObject.currencySymbol}} {{applicationPaymentDetailsObject.totalFeeIncludeServiceFee | number : '1.2' : 'en-US'}}" [disabled]="isPayButtonDisabled === true || applicationPaymentDetailsObject.totalFeeIncludeServiceFee === 0" class="next action-button custom_btn">
        </div>

    </form>
</div>

<app-footer></app-footer>
