<app-header></app-header>

<div class="banner-img-name"></div>

<section class="main">
  <h3 class="mb10">{{pageTitle}}</h3>
    <div class="form_main">

        <fieldset style="margin: 0px;">

            <div class="step_info"><span>Application Search</span></div>

            <form #searchApplicationForm="ngForm" (ngSubmit)="searchApplicationForm.form.valid && searchApplication()" [validateDateOfBirth]="['dateOfBirthDay', 'dateOfBirthMonth', 'dateOfBirthYear', '', constants.FALSE]" autocomplete="off" id="" appFocus>

                <div class="form-card">
                  <details style="float:none;">
                      <summary>Instructions for Applicant </summary>
                      <div class="content">
                          <ul>
                            <li>You can search application by entering reference number and one of application number, date of birth or email id.</li>
                            <li>Reference number & application number is provided post application form is filled and shared on your email id.</li>
                            <li>Welcome to latest version of Immigration Portal, If you have paid your application on/before 27th Nov 2021, please <a [href]="oldApplicationRedirectURL" style="color: orange; text-decoration: underline;">click here</a> to visit Old Portal.</li>

                          </ul>
                      </div>
                  </details>
                    <div class="search_grid">
                        <div class="grid-box">

                            <div class="form_box ">
                                <label class="fieldlabels">Reference Number <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <input type="text" name="referenceNumber" hint numberOnly [(ngModel)]="searchApplicationFormObject.referenceNumber" #referenceNumber="ngModel" (focusout)="openRedirectionMessageModal();" class="effect" required="required" autocomplete="off" tabindex="1" maxlength="15" firstFocus>
                                    <span class="tooltip"><span class='tooltipArrow'></span>Enter your application reference number.</span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="searchApplicationForm.submitted && referenceNumber.invalid">
                                    <span *ngIf="referenceNumber.errors.required">
                                        Reference Number is required!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box mt20 margin-top">
                                <label class="fieldlabels">Search By <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <select name="searchBy" [(ngModel)]="searchApplicationFormObject.searchBy" #searchBy="ngModel" (ngModelChange)="searchApplicationFormObject.searchValue = constants.BLANK" class="effect" required="required" tabindex="2">
                                        <option value="">Choose Option</option>
                                        <option *ngFor="let searchByObjectKey of searchByParamListObjectKeys(searchByParamListObject)" [value]="searchByObjectKey">{{searchByParamListObject[searchByObjectKey]}}</option>
                                    </select>
                                    <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                                    <span class="tooltip"><span class='tooltipArrow'></span></span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="searchApplicationForm.submitted && searchBy.invalid">
                                    <span *ngIf="searchBy.errors.required">
                                        Search By is required!
                                    </span>
                                </span>
                            </div>

                            <div *ngIf="utils.isEmpty(searchApplicationFormObject.searchBy) === false">

                                <div *ngIf="searchApplicationFormObject.searchBy === constants.APPLICATION_ID" class="form_box mt20 margin-top">
                                    <label class="fieldlabels">Application Number <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <input type="text" name="searchValue" hint numberOnly [(ngModel)]="searchApplicationFormObject.searchValue" #searchValue="ngModel" class="effect" required="required" autocomplete="off" tabindex="3" maxlength="12">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Enter your application number.</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger" role="alert" *ngIf="searchApplicationForm.submitted && searchValue.invalid">
                                        <span *ngIf="searchValue.errors.required">
                                            Application Number is required!
                                        </span>
                                    </span>
                                </div>

                                <div *ngIf="searchApplicationFormObject.searchBy === constants.EMAIL_ID" class="form_box mt20 margin-top">
                                    <label class="fieldlabels">Email Id <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <input type="text" name="searchValue" hint [(ngModel)]="searchApplicationFormObject.searchValue" #searchValue="ngModel" class="effect" required="required" autocomplete="off" tabindex="3" maxlength="100" pattern="^[a-zA-Z0-9.-_]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z0-9]{2,}$">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Enter your Email Id.</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger" role="alert" *ngIf="searchApplicationForm.submitted && searchValue.invalid">
                                        <span *ngIf="searchValue.errors.required">
                                            Email Id is required!
                                        </span>
                                        <span *ngIf="searchValue.errors.pattern">
                                            Email Id should be valid email!
                                        </span>
                                    </span>
                                </div>

                                <div *ngIf="searchApplicationFormObject.searchBy === constants.DATE_OF_BIRTH" class="form_box mt20 margin-top">
                                    <label class="fieldlabels">Date of Birth (dd/mm/yyyy) <span class="medatory">*</span></label>
                                    <div class="grid-date">
                                        <div class="input_box">
                                            <input type="text" name="dateOfBirthDay" numberOnly [(ngModel)]="searchApplicationFormObject.dateOfBirthDay" #dateOfBirthDay="ngModel" class="effect" required="required" autocomplete="off" tabindex="3" maxlength="2">
                                            <span class="focus-border "><i></i></span>
                                        </div>

                                        <div class="input_box">
                                            <select name="dateOfBirthMonth" [(ngModel)]="searchApplicationFormObject.dateOfBirthMonth" #dateOfBirthMonth="ngModel" class="effect" required="required" autocomplete="off" tabindex="4">
                                                <option *ngFor="let object of monthsList;" [value]="object.value">{{object.text}}</option>
                                            </select>
                                            <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                                            <span class="focus-border "><i></i></span>
                                        </div>

                                        <div class="input_box">
                                            <input type="text" name="dateOfBirthYear" numberOnly [(ngModel)]="searchApplicationFormObject.dateOfBirthYear" #dateOfBirthYear="ngModel" class="effect" required="required" autocomplete="off" tabindex="5" minlength="4" maxlength="4">
                                            <span class="focus-border "><i></i></span>
                                        </div>
                                    </div>
                                    <span class="alert alert-danger" role="alert" *ngIf="searchApplicationForm.submitted && (dateOfBirthDay.invalid || dateOfBirthMonth.invalid || dateOfBirthYear.invalid)">
                                        <span *ngIf="(dateOfBirthDay.errors?.required || dateOfBirthMonth.errors?.required || dateOfBirthYear.errors?.required)">
                                            Date of Birth is required!
                                        </span>
                                        <span *ngIf="dateOfBirthYear.errors?.minlength">
                                            Date of Birth's year should be 4 digits!
                                        </span>
                                        <span *ngIf="!dateOfBirthYear.errors?.minlength && dateOfBirthDay.errors?.hasError">
                                            {{dateOfBirthDay.errors.errorMessage}}
                                        </span>
                                    </span>
                                </div>

                            </div>

                            <!-- <div class="captcha mt20">
                                <ngx-recaptcha2 name="captchaVerified" (ngModel)="searchApplicationFormObject.captchaVerified" [siteKey]="projectConfig.CAPTCHA_SITE_KEY" (load)="captcha.handleCaptchaLoad(searchApplicationFormObject)" (success)="captcha.handleCaptchaResponse(searchApplicationFormObject, $event)" (expire)="captcha.handleCaptchaExpire(searchApplicationFormObject)" size="normal" hl="en" theme="light" type="image"></ngx-recaptcha2>
                                <span class="alert alert-danger" *ngIf="searchApplicationForm.submitted && searchApplicationFormObject.captchaVerified === false">
                                    Captcha is required!
                                </span>
                            </div> -->

                            <input type="button" (click)="gui.openHomePage()" class="back_btn" value="Back" />
                            <input type="submit" value="Search" class="save_btn">

                        </div>

                        <div class="grid-box clearfix"></div>

                        <div class="grid-box">
                            <div  class="imp_info mt20">
                                <div  class="element none_dis">
                                    <h4>Important Instructions</h4>
                                    <ol style="padding-left: 15px;">
                                        <li>You can search application by entering reference number and one of application number, date of birth or email id.</li>
                                        <li>Reference number & application number is provided post application form is filled and shared on your email id.</li>
                                        <li>Welcome to latest version of Immigration Portal, If you have paid your application on/before 27th Nov 2021, please <a [href]="oldApplicationRedirectURL" style="color: orange; text-decoration: underline;">click here</a> to visit Old Portal.</li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <br/>

                    </div>
                </div>
            </form>
        </fieldset>

    </div>
</section>

<footer class="">
    <div class="main-footer">
        <div class="footer_flex">
            <p>Copyright © {{latestYear}} | All rights reserved.</p>
            <img src="assets/images/newworks-logo.png" >
        </div>
   </div>
</footer>
