import { Component, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Captcha } from '../common/captcha';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { ProjectConfig } from '../common/project-config';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';

@Component({
  selector: 'app-codrequest-search',
  templateUrl: './codrequest-search.component.html',
  styleUrls: ['./codrequest-search.component.css']
})
export class CODRequestSearchComponent {

  latestYear:number;

  codSearchFormObject:any = {};
  serverResponseCode:number = 0;
  showSearchButton:boolean = true;
  private dataCorrectionId:string="";

  constructor(private router: Router, private ngZone: NgZone, private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, private toast: Toast, public utils: Utils,
              public constants: Constants, private loaderService: LoaderService, public projectConfig: ProjectConfig, public captcha: Captcha) {
    this.latestYear = new Date().getFullYear();
    captcha.handleCaptchaLoad(this.codSearchFormObject);
  }

  searchCODApplication(){
    if(this.codSearchFormObject.captchaVerified === false){
      return;
    }
    this.loaderService.startLoader();
    this.httpUtils.validatePasscode(this.codSearchFormObject.passcode.trim(), this.constants.COD_APPLICATION_SEARCH).then(serverResponse => {
      this.loaderService.stopLoader();
      this.serverResponseCode = serverResponse.responseCode;
      if(this.serverResponseCode === this.constants.STATUS_SUCCESS){
        this.ngZone.run(()=>this.router.navigate(["perspective", this.utils.encryptString(serverResponse.passcode)]));
      } else if(this.serverResponseCode === this.constants.COD_APPLICATION_NOT_CONFIRMED_ERROR_CODE){
        this.dataCorrectionId = serverResponse.dataCorrectionId;
        this.showSearchButton = false;
        this.toast.showErrorToast(serverResponse.responseMsg);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  openCODConfirmationPage(){
    this.ngZone.run(()=>this.router.navigate(["confirmation", this.utils.encryptString(this.dataCorrectionId)]));
  }

  openHomePage(){
    this.ngZone.run(()=>this.router.navigate(["main"]));
  }

}
