import { Component, } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Constants } from '../common/constants';
import { GUI } from '../common/gui';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { LocalStorage } from '../common/local-storage';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';

@Component({
  selector: 'app-payment-receipt',
  templateUrl: './payment-receipt.component.html',
  styleUrls: ['./payment-receipt.component.css']
})
export class PaymentReceiptComponent {

  private encryptedTxnReferenceNumber : string = this.constants.BLANK;
  
  passportDetailsObject : any = {};

  personalInfoDetailsObject : any = {};

  processingInfoDetailsObject : any = {};

  feeDetailsObject : any = {};

  bankNotesList : any = [];

  clientNotesList : any = [];
  
  paymentType : string = this.constants.BLANK;

  constructor(private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, public constants: Constants, private loaderService: LoaderService, private toast: Toast, 
              public utils: Utils, private localStorage: LocalStorage, private gui: GUI) { 
    activatedRoute.params.subscribe((params: Params) => {
      this.encryptedTxnReferenceNumber = params['txnReferenceNumber'];
    });
    this.storeApplicationPaymentReceiptDetails();
  }

  private storeApplicationPaymentReceiptDetails(){
    this.loaderService.startLoader();
    this.httpUtils.fetchApplicationPaymentReceiptDetails(this.utils.decryptString(this.encryptedTxnReferenceNumber)).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.passportDetailsObject = serverResponse.passportDetails;
        this.personalInfoDetailsObject = serverResponse.personalInfo;
        this.processingInfoDetailsObject = serverResponse.processingInfo;
        this.feeDetailsObject = serverResponse.feeDetails;
        this.feeDetailsObject.gatewayName = serverResponse.gatewayName;
        this.feeDetailsObject.paymentMethod = serverResponse.paymentMethod;
        this.personalInfoDetailsObject.txnRefNumber = serverResponse.txnRefNumber;
        this.personalInfoDetailsObject.receiptDate = serverResponse.receiptDate;
        this.personalInfoDetailsObject.paymentType = serverResponse.paymentType;
        this.personalInfoDetailsObject.expiryDate = serverResponse.expiryDate;
        this.bankNotesList = serverResponse.bankNote;
        this.clientNotesList = serverResponse.clientNote;
        this.paymentType = serverResponse.paymentType;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  backToPaymentPage(){
    const encryptedApplicationId = this.utils.encryptString(this.personalInfoDetailsObject.applicationId);
    this.gui.openPaymentPage(encryptedApplicationId, this.personalInfoDetailsObject.paymentType);
  }

  printReceipt(){
    window.print()
  }

}