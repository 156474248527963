import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface InformationModalInterface {
  message: string;
}
@Component({
  selector: 'app-information-modal',
  templateUrl: './information-modal.component.html',
  styleUrls: ['./information-modal.component.css']
})
export class InformationModalComponent extends SimpleModalComponent<InformationModalInterface, boolean> implements InformationModalInterface {

  message: string;
  
  constructor() { 
    super();
  }

  confirm() {
    this.result = true;
    this.close();
  }

}