import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { ProjectConfig } from './app/common/project-config';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

const applicationHostName = window.location.hostname;
console.log(applicationHostName);

if(applicationHostName.includes("localhost") === true || applicationHostName.includes("192") === true || applicationHostName.includes("122.160.87.36") === true) {
  ProjectConfig.loadLocalEnvironmentConfig();
} else if (applicationHostName.includes("ppt") === true) {
  ProjectConfig.loadStagingEnvironmentConfig();
} else {
	ProjectConfig.loadProductionEnvironmentConfig();
}
