import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Constants } from '../common/constants';
import { GUI } from '../common/gui';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { ProjectConfig } from '../common/project-config';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';

@Component({
  selector: 'app-payment-success',
  templateUrl: './payment-success.component.html',
  styleUrls: ['./payment-success.component.css']
})
export class PaymentSuccessComponent implements OnInit {

  private serverEncryptedTxnRef:string="";
  paymentDetailsObject: any = {};

  private paymentSuccessForm:HTMLFormElement;

  constructor(private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, private toast: Toast, public constant: Constants, private utils: Utils, 
              private loaderService: LoaderService, private projectConfig: ProjectConfig, private gui: GUI) {
    this.activatedRoute.params.subscribe((params: Params) => {
      this.serverEncryptedTxnRef = params['txnRef'];
    });
    this.checkPaymentStatus();
  }

  ngOnInit(): void {
    this.paymentSuccessForm = document.forms['paymentSuccessForm'];
  }

  private checkPaymentStatus(){
    this.loaderService.startLoader();
    const serverDecryptedTxnRef = this.utils.decryptServerEncryptedString(this.serverEncryptedTxnRef);
    this.httpUtils.checkPaymentStatus(serverDecryptedTxnRef).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constant.STATUS_SUCCESS){
        this.toast.showSuccessToast(serverResponse.responseMsg);
        this.paymentDetailsObject = serverResponse;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
        this.gui.openPaymentFailurePage(this.serverEncryptedTxnRef, serverResponse.referenceNumber, serverResponse.applicationId);
      }
    });
  }

  openDashboard(){
    this.gui.openHomePage();
  }

  viewApplication(){
    if(this.paymentDetailsObject.paymentType === this.constant.APPLICATION_PAYMENT){
      this.gui.openApplicationView(this.paymentDetailsObject.applicantId, this.constant.NEXT_OF_KIN_INFO_STEP);
    } else if(this.paymentDetailsObject.paymentType === this.constant.COD_PAYMENT){
      this.gui.openCODView(this.paymentDetailsObject.passcode);
    }
  }

  printApplication(){
    window.print();
  }

  bookAppointment(){
    const base64String = btoa(this.paymentDetailsObject.applicationId + "|" + this.paymentDetailsObject.referenceNumber); 
    window.location.href = this.projectConfig.APPOINTMENT_APPLICATION_URL + "?a=" + base64String;
  }

  downloadPDF(downloadType: string){
    if(this.paymentDetailsObject.paymentType === this.constant.APPLICATION_PAYMENT){
      this.paymentSuccessForm.action = this.projectConfig.SERVER_BASE_URL + "v1/application/downloadApplicationPDF";
    } else if (this.paymentDetailsObject.paymentType === this.constant.COD_PAYMENT) {
      this.paymentSuccessForm.action = this.projectConfig.SERVER_BASE_URL + "v1/cod/downloadCODApplication";
    }
    this.paymentSuccessForm.downloadType.value = downloadType;
    this.paymentSuccessForm.submit();
  }

}