import { Component, NgZone } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { Tags } from '../common/tags';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';

@Component({
  selector: 'app-codconfirmation',
  templateUrl: './codconfirmation.component.html',
  styleUrls: ['./codconfirmation.component.css']
})
export class CODConfirmationComponent {

  private encryptedCorrectionId : string = "";

  codConfirmationFormObject:any = {};

  applicationId: string = "";
  passportNumber: string = "";
  passcode: string = "";
  changedFieldsArray : any = [];
  changedFieldsArraySize : number = 0;
  feeDetailsObject:any = {};
  private dataChangedReason:string = "";
  isApprovalRequired: boolean = true;
  isServerResponseCome: boolean = false;

  constructor(private router: Router, private ngZone: NgZone, private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, private toast: Toast, public constants: Constants,
              private loaderService: LoaderService, private simpleModalService: SimpleModalService, private tags: Tags, public utils: Utils) {
    activatedRoute.params.subscribe((params: Params) => {
      this.encryptedCorrectionId = params['correctionId'];
    });
    this.codConfirmationFormObject.isConfirmationChecked = false;
    this.storeCODConfirmationData();
  }

  private storeCODConfirmationData(){
    this.loaderService.startLoader();
    this.httpUtils.fetchCODConfirmationData(this.utils.decryptString(this.encryptedCorrectionId)).then(serverResponse => {
      this.loaderService.stopLoader();
      this.isServerResponseCome = true;
      const serverResponseCode = serverResponse.responseCode;
      if(serverResponseCode === this.constants.STATUS_SUCCESS){
        this.applicationId = serverResponse.applicationId;
        this.passportNumber = serverResponse.passportNumber;
        this.passcode = serverResponse.passcode;
        this.changedFieldsArray = serverResponse.itemsData;
        this.changedFieldsArraySize = serverResponse.itemsData.length;
        this.dataChangedReason = this.changedFieldsArraySize > 0 ? this.changedFieldsArray[0].reason : this.constants.BLANK;
        this.feeDetailsObject.currencyCode = serverResponse.currencyCode;
        this.feeDetailsObject.currencySymbol = serverResponse.currencySymbol;
        this.feeDetailsObject.adminCharges = serverResponse.adminCharges;
        this.feeDetailsObject.adminChargesFormatted = serverResponse.adminChargesFormatted;
        this.isApprovalRequired = serverResponse.isMarraigeCase === this.constants.FALSE;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  showConfirmationForApplicationSubmission(){
    const subMessage = this.feeDetailsObject.adminCharges > 0 ? "pay" : "apply";
    const message = "Please note down your application number <b>"+this.applicationId+"</b> & passcode <b>"+this.passcode+"</b> to retrieve or "+subMessage+" application later.";
    this.simpleModalService.addModal(ConfirmationModalComponent, {message: message}, {autoFocus: true}).subscribe((isConfirmed: boolean)=> {
      if(isConfirmed === true){
        this.confirmCODApplication();
      }
    });
  }

  confirmCODApplication(){
    this.loaderService.startLoader();
    this.codConfirmationFormObject.dataCorrectionId = this.utils.decryptString(this.encryptedCorrectionId);
    this.codConfirmationFormObject.adminCharges = this.feeDetailsObject.adminCharges;
    this.httpUtils.confirmCODData(this.codConfirmationFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      const serverResponseCode = serverResponse.responseCode;
      if(serverResponseCode === this.constants.STATUS_SUCCESS){
        if(this.codConfirmationFormObject.adminCharges === this.constants.FALSE && this.dataChangedReason !== this.constants.OTHERS) {
           //this.ngZone.run(()=>this.router.navigate(["application", this.utils.encryptString(this.passcode)]));
            this.toast.showSuccessToast('Request submitted successfully');
            this.ngZone.run(()=>this.router.navigate(["/"]));

        } else {

          this.ngZone.run(()=>this.router.navigate(["perspective", this.utils.encryptString(this.passcode)]));
        }
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  printApplication(){
    window.print();
  }

  openCODHomePage(){
    this.ngZone.run(()=>this.router.navigate(["request", this.utils.encryptString(this.passcode)]));
  }

}
