import { LocationStrategy } from '@angular/common';
import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '../common/constants';
import { LocalStorage } from '../common/local-storage';
import { ProjectConfig } from '../common/project-config';
import { Tags } from '../common/tags';
import { Utils } from '../common/utils';

declare function enableMobileToggle(): any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {

  constructor(private router: Router, private ngZone: NgZone, private location: LocationStrategy, private utils: Utils, private constants: Constants, private tags: Tags, 
              private localStorage: LocalStorage, private projectConfig: ProjectConfig) {
    history.pushState(null, null, window.location.href);  
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.href);
    });
  }

  openSearchForm(){
    this.ngZone.run(()=>this.router.navigate(["search", this.utils.encryptString(this.constants.PAYMENT_STATUS)]));
  }

  public doUnload(): void {
    this.doBeforeUnload();
  }

  public doBeforeUnload(): void {
   this.localStorage.removeStorageKey(this.tags.IS_NOTICE_MODAL_OPEN);
  }

  callMobileView(){
    enableMobileToggle();
  }

  openTrackApplicationForm(){
    window.location.href = this.projectConfig.TRACK_APPLICATION_URL;
  }

}