import { Component, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Captcha } from '../common/captcha';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { ProjectConfig } from '../common/project-config';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';

@Component({
  selector: 'app-codapprove',
  templateUrl: './codapprove.component.html',
  styleUrls: ['./codapprove.component.css']
})
export class CODApproveComponent {

  latestYear:number;

  codApproveFormObject:any = {};
  serverResponseCode:number = 0;
  showValidateButton:boolean = true;
  private dataCorrectionId:string="";

  constructor(private router: Router, private ngZone: NgZone, private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, private toast: Toast, public utils: Utils,
              public constants: Constants, private loaderService: LoaderService, public projectConfig: ProjectConfig, public captcha: Captcha) {
    this.latestYear = new Date().getFullYear();
    captcha.handleCaptchaLoad(this.codApproveFormObject);
  }

  validatePasscode(){
    if(this.codApproveFormObject.captchaVerified === false){
      return;
    }
    this.loaderService.startLoader();
    this.httpUtils.validatePasscode(this.codApproveFormObject.passcode.trim(), this.constants.COD_APPLICATION_APPROVE).then(serverResponse => {
      this.loaderService.stopLoader();
      this.serverResponseCode = serverResponse.responseCode;
      if(this.serverResponseCode === this.constants.STATUS_SUCCESS){
        this.showValidateButton = false;
        this.toast.showSuccessToast(serverResponse.responseMsg);
      } else if(this.serverResponseCode === this.constants.COD_PAYMENT_NOT_COMPLETE_ERROR_CODE){
        this.showValidateButton = false;
        this.toast.showErrorToast(serverResponse.responseMsg);
      } else if(this.serverResponseCode === this.constants.COD_APPLICATION_NOT_CONFIRMED_ERROR_CODE){
          this.dataCorrectionId = serverResponse.dataCorrectionId;
          this.showValidateButton = false;
          this.toast.showErrorToast(serverResponse.responseMsg);
      } else {
        this.showValidateButton = true;
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  openApplicationPage(){
    this.ngZone.run(()=>this.router.navigate(["application", this.utils.encryptString(this.codApproveFormObject.passcode)]));
  }

  openCODApplicationViewPage(){
    this.ngZone.run(()=>this.router.navigate(["perspective", this.utils.encryptString(this.codApproveFormObject.passcode)]));
  }

  openCODConfirmationPage(){
    this.ngZone.run(()=>this.router.navigate(["confirmation", this.utils.encryptString(this.dataCorrectionId)]));
  }

  openHomePage(){
    this.ngZone.run(()=>this.router.navigate(["main"]));
  }

}
