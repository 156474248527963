import { Injectable } from '@angular/core';

@Injectable({ 
    providedIn: 'root'
})
export class Constants {
    
    STATUS_INITIATE = 100;
    STATUS_REJECTED = 150;
    STATUS_SUCCESS = 200;
    
    
    STATUS_AUTH_TOKEN_NOT_VALID = 1102;
    STATUS_POTENTIAL_SECURITY_BREACH = 1119;
    DATA_CORRECTION_APPLICATION_EXISTS = 1129;
    INTERNAL_SERVER_ERROR = 500;
    TRUE = 1;
    FALSE = 0;
    ANY = -1;

    ENCRYPT_DECRYPT_KEY = "NIS_MASTER_ENCRYPT_DECRYPT_KEY";

    ITEM_PER_PAGE = 10;

    PASSPORT_STEP = 1;
    PERSONAL_INFO_STEP = 2;
    CONTACT_INFO_STEP = 3;
    NEXT_OF_KIN_INFO_STEP = 4;

    BLANK = ""
    GMAIL_LOGIN_SOURCE = "GMAIL";

    INCOMPLETE = "INCOMPLETE";
    UNPAID = "UNPAID";
    PAID = "PAID";

    OTHER = "OTHER";

    FILE_TYPE_PDF = "application/pdf";

    MR = "MR";
    MRS = "MRS";
    MISS = "MISS";

    MALE = "MALE";
    FEMALE = "FEMALE";

    DIVORCED = "DIVORCED";
    MARRIED = "MARRIED";
    SEPARATED = "SEPARATED";
    SINGLE = "SINGLE";
    WIDOWED = "WIDOWED";

    EMPLOYER = "EMPLOYER";

    SELF = "SELF";

    FRESH = "FRESH";
    RENEW = "RENEW";
    REISSUE = "REISSUE";
  
    USER_IMAGE_REQUIRED_WIDTH = 200;
    USER_IMAGE_REQUIRED_HEIGHT = 450;

    MINOR = "MINOR";
    ADULT = "ADULT";
    SENIOR_CITIZEN = "SENIOR_CITIZEN";

    BROWN = "BROWN";
    BLUE = "BLUE";
    GREEN = "GREEN";
    GRAY = "GRAY";
    BLACK = "BLACK";
    WHITE = "WHITE";

    FATHER = "FATHER";
    MOTHER = "MOTHER";
    SON = "SON";
    DAUGHTER = "DAUGHTER";
    HUSBAND = "HUSBAND";
    WIFE = "WIFE";
    FRIEND = "FRIEND";
    RELATIVE = "RELATIVE";
    BROTHER = "BROTHER";
    SISTER = "SISTER";


    APPLICATION_ID = "applicationId";
    MOBILE_NUMBER = "mobileNumber";
    DATE_OF_BIRTH = "dateOfBirth";
    EMAIL_ID = "emailId";

   

    RESUME_APPLICATION = "RESUME_APPLICATION";
    PAYMENT_STATUS = "PAYMENT_STATUS";
    PAYMENT_HISTORY = "PAYMENT_HISTORY";
    GUARANTOR_FORM = "GUARANTOR_FORM";

    OFFICIAL_PASSPORT = "OFFICIAL_E_PASSPORT";
    STANDARD_PASSPORT = "STANDARD_E_PASSPORT";
    STANDARD_PASSPORT_RENEWAL_REISSUE = "STANDARD_E_PASSPORT_RENEWAL_REISSUE";
    STANDARD_PASSPORT_COD = "STANDARD_E_PASSPORT_COD";
    
    EXPIRED = "EXPIRED";
	UNEXPIRED = "UNEXPIRED";
	PAGE_EXHAUSTED = "PAGE_EXHAUSTED";
	DAMAGED_PASSPORT = "DAMAGED_PASSPORT";
	LOST_REPLACEMENT_REQUEST = "LOST_REPLACEMENT_REQUEST";
    RELATION_REASON = "RELATION_REASON";
    OTHER_REASON = "OTHER";

    BOOKLET_32_PAGE = "32_PAGE";
    BOOKLET_64_PAGE = "64_PAGE";

    VALIDITY_5_YEARS = "5_YEARS";
    VALIDITY_10_YEARS = "10_YEARS";

    PAYFIXY = "PAYFIXY";
    // INNOVATE1PAY = "INNOVATE1PAY";
    // INNOVATE1PAY_CASH_AT_BANK = "IOP-CASH_AT_BANK";
    // INNOVATE1PAY_CARD = "IOP-PAYMENT_GATEWAY";
    // INNOVATE1PAY_POS = "IOP-POS";
    
    // SANAPAY = "SAANAPAY";
    // SAANAPAY_CARD_PAYMENT = "SAANAPAY-SAANAPAY_CARD_PAYMENT"
    // SAANAPAY_CASH_AT_BANK = "SAANAPAY-SAANAPAY_CASH_AT_BANK"
    // SAANAPAY_E_BILLS_PAY = "SAANAPAY-SAANAPAY_E_BILLS_PAY"
    // UNIFIED_CASH_PAYMENT = "UNIFIED_CASH_PAYMENT";

    // PAYSTACK = "PAYSTACK";
    // PAYSTACK_CARD_PAYMENT = "PAYSTACK-PAYSTACK_CARD_PAYMENT";
    // PAYSTACK_BANK_TRANSFER = "PAYSTACK-PAYSTACK_BANK_TRANSFER";
    // PAYSTACK_USSD = "PAYSTACK-PAYSTACK_USSD";

    MARRIAGE = "MARRIAGE";
    DIVORCE = "DIVORCE";
    DECEASED_SPOUSE = "DECEASED_SPOUSE";
    OTHERS = "OTHERS";

    PAYMENT_TYPE = "PAYMENT_TYPE";
    APPLICATION_PAYMENT = "APPLICATION_PAYMENT";
    COD_PAYMENT = "COD_PAYMENT";
    COD_APPLICATION = "COD_APPLICATION";
    PASSPORT_APPLICATION = "PASSPORT_APPLICATION";

    NOT_FOUND = "NOT_FOUND";

    COD_PAYMENT_NOT_COMPLETE_ERROR_CODE = 1113;
    COD_APPLICATION_NOT_CONFIRMED_ERROR_CODE = 1144;
    PAYMENT_ALREADY_COMPLETE = 1107;
    PAYMENT_ALREADY_INITIATED = 1108;

    COD_APPLICATION_APPROVE = "COD_APPLICATION_APPROVE";
    COD_APPLICATION_SEARCH = "COD_APPLICATION_SEARCH";

    APPLICATION_DOWNLOAD = "APPLICATION_DOWNLOAD";
    RECEIPT_DOWNLOAD = "RECEIPT_DOWNLOAD";

    ENCRYPT_DECRYPT_MASTER_KEY = "61085G5GH96QW96D";
    ENCRYPT_DECRYPT_MASTER_PASSWORD = "PS606AA85DRG3J93800Q9L90672IYV50";
    ENCRYPT_DECRYPT_MASTER_SALT = "PASSPORT_NGN";

    FCT_STATE_ID = "20.00000";
	FCT_HQ_OFFICE_ID = "39";

    MIN_DATE_OF_BIRTH_YEAR = 1900;
    
    TEASY = "TEASY";
    TEASY_EWALLET = "TEASY-TEASY_EWALLET";

    UNIFIED = "UNIFIED";
    UNIFIED_PAYMENTS = "UNIFIED-UNIFIED_PAYMENTS";

    // CASH_AT_BANK_EXTRA_CHARGE = 200;

    NEW_PORTAL_REFERENCE_NUMBER_PREFIX = 202;

    SERVER_COMMUNICATION_ERROR_MESSAGE = "Something went wrong. Please try again later.";

    REQUEST_RESOURCE_BACK_OFFICE = "BACK_OFFICE";

    DEFAULT_SELECT_OPTION_LABEL = "Choose Option";

}