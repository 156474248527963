<app-header class="hide"></app-header>

<div class="center_body">
    <form action="" method="post" name="paymentSuccessForm" #paymentSuccessForm="ngForm" (ngSubmit)="paymentSuccessForm.form.valid" autocomplete="off" id="msform1">
        <input type="hidden" name="applicationId" value="{{paymentDetailsObject.applicationId}}">
        <input type="hidden" name="txnReference" value="{{paymentDetailsObject.txnRefNumber}}">
        <input type="hidden" name="downloadType" value="">

        <div class="card">
            <div style="border-radius:200px; height:100px; width:100px; background: #e4f4cc; margin:0 auto; border:1px solid #f0f0f0">
                <i class="checkmark">✓</i>
            </div>

            <h1>Payment Successful!</h1>

            <p>Your payment was successful. Please visit processing center with a copy of application & print receipt.</p>

            <div class="info_paid">
                <span><b>Transaction Ref:</b> {{paymentDetailsObject.txnRefNumber}}</span>
                <span><b>{{paymentDetailsObject.referenceLabel}} : </b> {{paymentDetailsObject.referenceValue}}</span>
                <span *ngIf="paymentDetailsObject.paymentType === constant.COD_PAYMENT"><b>Passcode : </b> {{paymentDetailsObject.passcode}}</span>
                <span><b>Total Amount:</b> {{paymentDetailsObject.currencySymbol}} {{paymentDetailsObject.totalAmount | number : '1.2' : 'en-US'}}</span>
            </div>

            <h6>Paid Application Details</h6>

            <table style="width: 100%;" class="pay_ment">
                <thead>
                    <tr>
                        <th width="19%" nowrap>Application No.</th>
                        <th width="30%" nowrap>Full Name</th>
                        <th width="27%" nowrap>Passport Type</th>
                        <th width="14%" nowrap>Fee</th>
                        <th width="10%">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td data-label="Application ID" width="19%">{{paymentDetailsObject.applicationId}}</td>
                        <td data-label="Full Name" width="30%">{{paymentDetailsObject.fullName}}</td>
                        <td data-label="Passport Type " width="27%">{{paymentDetailsObject.passportType}}</td>
                        <td data-label="Fee" width="14%" style="text-align:right;">{{paymentDetailsObject.currencySymbol}} {{paymentDetailsObject.totalAmount | number : '1.2' : 'en-US'}}</td>
                        <td data-label="Action" width="10%"><span tooltip="View" placement="bottom" (click)="viewApplication()" style="margin-left:20px; cursor: pointer;"><i class="fa fa-eye" aria-hidden="true"></i></span></td>
                    </tr>
                </tbody>
            </table>
            
            <!-- <input type="button" (click)="openDashboard()" value="Go to Dashboard" class="custom_btn hide"> -->
            <input *ngIf="paymentDetailsObject.paymentType === constant.COD_PAYMENT" type="button" (click)="printApplication()" value="Print" class="edit_btn hide">
            <input type="button" (click)="downloadPDF(constant.APPLICATION_DOWNLOAD)" value="Download Application" class="btn hide">
            <input type="button" (click)="downloadPDF(constant.RECEIPT_DOWNLOAD)" value="Download Receipt" class="save_btn hide">
            <input *ngIf="paymentDetailsObject.paymentType === constant.APPLICATION_PAYMENT" type="button" (click)="bookAppointment()" value="Book Appointment" class="edit_btn">

            <div *ngIf="paymentDetailsObject.paymentType === constant.APPLICATION_PAYMENT" class="book-appointment-note">
                <p><b>Note:</b>You are required to book an appointment before going for biometric capture. <br/>Click on Book Appointment to book your slot.</p>
            </div>
        </div>
        
    </form>
</div>
<br/><br/>
<app-footer class="hide"></app-footer>
