import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from './constants';
import { Utils } from './utils';

@Injectable({ 
    providedIn: 'root'
})
export class GUI {
    
    constructor(private ngZone: NgZone, private router: Router, private constants: Constants, private utils: Utils){

    }

    openHomePage(){
        this.ngZone.run(()=>this.router.navigate([""]));
    }

    openApplicationAddEdit(applicantId: string, applicationStage: number){
        applicationStage = applicationStage < this.constants.NEXT_OF_KIN_INFO_STEP ? ++applicationStage : applicationStage;
        this.ngZone.run(()=>this.router.navigate(["application", this.utils.encryptString(applicantId), this.utils.encryptString(applicationStage.toString())]));
    }

    openApplicationView(applicantId: string, applicationStage: number){
        if(applicationStage < this.constants.CONTACT_INFO_STEP){
            this.openApplicationAddEdit(applicantId, applicationStage);
            return;
        } 
        
        this.ngZone.run(()=>this.router.navigate(["view", this.utils.encryptString(applicantId)]));  
    }

    openApplicationSearch(searchType: string){
        this.ngZone.run(()=>this.router.navigate(["search", this.utils.encryptString(searchType)]));
    }

    openApplicationGuarantor(applicantId: string){
        this.ngZone.run(()=>this.router.navigate(["guarantor", this.utils.encryptString(applicantId)]));
    }

    openCODApprovedPage(){
        this.ngZone.run(()=>this.router.navigate(["approve"]));
    }

    openCODView(passcode: string){
        this.ngZone.run(()=>this.router.navigate(["perspective", this.utils.encryptString(passcode)]));
    }

    openPaymentPage(encryptedApplicantId: string, paymentType: string){
        this.ngZone.run(()=>this.router.navigate(["payment", encryptedApplicantId, this.utils.encryptString(paymentType)]));
    }

    openPaymentSuccessPage(serverEncryptedTxnRef: string){
        this.ngZone.run(()=>this.router.navigate(["success", serverEncryptedTxnRef]));
    }

    openPaymentFailurePage(serverEncryptedTxnRef: string, serverEncryptedReferenceNumber: string, serverEncryptedApplicationId: string){
        this.ngZone.run(()=>this.router.navigate(["failure", serverEncryptedTxnRef, serverEncryptedReferenceNumber, serverEncryptedApplicationId]));
    }

    openPaymentReceipt(txnRefNumber: string){
        this.ngZone.run(()=>this.router.navigate(["receipt", this.utils.encryptString(txnRefNumber)]));
    }

}