import { Injectable } from '@angular/core';
import { Tags } from './tags';

@Injectable({
    providedIn: 'root'
})
export class ProjectConfig {

    public TOASTER_DELAY = 3000;

    private static tags = new Tags();
    private static PROJECT_CONFIG_MAP : any = {};

    //New NOTE: These methods are calling from the main.ts file that is entry point of our application
    static loadLocalEnvironmentConfig(){
        this.PROJECT_CONFIG_MAP[this.tags.CAPTCHA_SITE_KEY] = "6LcGFyEiAAAAACFa4bDQUGljqa3zVMc2pLNE0hB9";
        //this.PROJECT_CONFIG_MAP[this.tags.SERVER_BASE_URL] = "http://192.168.1.4:8091/ngnPassport/";
         this.PROJECT_CONFIG_MAP[this.tags.SERVER_BASE_URL] = "https://cod.immigration.gov.ng:8443/ngnPassport/";
        //  this.PROJECT_CONFIG_MAP[this.tags.SERVER_BASE_URL] = "https://ppt.swglobalstaging.com:8443/ngnPassport/";
        // this.PROJECT_CONFIG_MAP[this.tags.SERVER_BASE_URL] = "http://192.168.1.31:8080/ngnPassport/";
        this.PROJECT_CONFIG_MAP[this.tags.APPOINTMENT_APPLICATION_URL] = "https://apt-stage.newworkssolution.com/";
        this.PROJECT_CONFIG_MAP[this.tags.TRACK_APPLICATION_URL] = "http://track.immigration.gov.ng/";
        this.PROJECT_CONFIG_MAP[this.tags.USD_PASSPORT_APPLICATION_URL] = "https://passportintl.immigration.gov.ng/";
        this.PROJECT_CONFIG_MAP[this.tags.OLD_NGN_PASSPORT_APPLICATION_SEARCH_URL] = "https://portal.immigration.gov.ng/passport/passportPaymentStatus";
        this.PROJECT_CONFIG_MAP[this.tags.OLD_NGN_PASSPORT_APPLICATION_GUARANTOR_URL] = "https://portal.immigration.gov.ng/passport/passportGuarantor";
        this.PROJECT_CONFIG_MAP[this.tags.CENTRE_AVAILABILITY_URL] = "http://10.200.6.246/availability";
    }

    static loadStagingEnvironmentConfig(){
        this.PROJECT_CONFIG_MAP[this.tags.CAPTCHA_SITE_KEY] = "6LchEo4iAAAAAHAG9DGqAfZMxfoH5RRFL1pLGLGQ";
        this.PROJECT_CONFIG_MAP[this.tags.SERVER_BASE_URL] = "https://ppt.swglobalstaging.com:8443/ngnPassport/";
        this.PROJECT_CONFIG_MAP[this.tags.APPOINTMENT_APPLICATION_URL] = "https://apt-stage.newworkssolution.com/";
        this.PROJECT_CONFIG_MAP[this.tags.TRACK_APPLICATION_URL] = "http://track.immigration.gov.ng/";
        this.PROJECT_CONFIG_MAP[this.tags.USD_PASSPORT_APPLICATION_URL] = "https://passportintl.immigration.gov.ng/";
        this.PROJECT_CONFIG_MAP[this.tags.OLD_NGN_PASSPORT_APPLICATION_SEARCH_URL] = "https://portal.immigration.gov.ng/passport/passportPaymentStatus";
        this.PROJECT_CONFIG_MAP[this.tags.OLD_NGN_PASSPORT_APPLICATION_GUARANTOR_URL] = "https://portal.immigration.gov.ng/passport/passportGuarantor";
        this.PROJECT_CONFIG_MAP[this.tags.CENTRE_AVAILABILITY_URL] = "http://10.200.6.246/availability";
    }

    static loadProductionEnvironmentConfig(){
        this.PROJECT_CONFIG_MAP[this.tags.CAPTCHA_SITE_KEY] = "6LdORNoaAAAAAEjbPbxOFDp08bNpqKPm-xgRts4k";
        this.PROJECT_CONFIG_MAP[this.tags.SERVER_BASE_URL] = "https://cod.immigration.gov.ng:8443/ngnPassport/";
        // this.PROJECT_CONFIG_MAP[this.tags.SERVER_BASE_URL] = "https://cod.immigration.gov.ng:8443/ngnPassport/";
        this.PROJECT_CONFIG_MAP[this.tags.APPOINTMENT_APPLICATION_URL] = "https://appointments.immigration.gov.ng/availability";
        this.PROJECT_CONFIG_MAP[this.tags.TRACK_APPLICATION_URL] = "http://track.immigration.gov.ng/";
        this.PROJECT_CONFIG_MAP[this.tags.USD_PASSPORT_APPLICATION_URL] = "https://passportintl.immigration.gov.ng/";
        this.PROJECT_CONFIG_MAP[this.tags.OLD_NGN_PASSPORT_APPLICATION_SEARCH_URL] = "https://portal.immigration.gov.ng/passport/passportPaymentStatus";
        this.PROJECT_CONFIG_MAP[this.tags.OLD_NGN_PASSPORT_APPLICATION_GUARANTOR_URL] = "https://portal.immigration.gov.ng/passport/passportGuarantor";
        this.PROJECT_CONFIG_MAP[this.tags.CENTRE_AVAILABILITY_URL] = "https://appointments.immigration.gov.ng/availability";
    }

    get CAPTCHA_SITE_KEY() {
        return ProjectConfig.PROJECT_CONFIG_MAP[ProjectConfig.tags.CAPTCHA_SITE_KEY];
    }

    get SERVER_BASE_URL() {
        return ProjectConfig.PROJECT_CONFIG_MAP[ProjectConfig.tags.SERVER_BASE_URL];
    }

    get APPOINTMENT_APPLICATION_URL() {
        return ProjectConfig.PROJECT_CONFIG_MAP[ProjectConfig.tags.APPOINTMENT_APPLICATION_URL];
    }

    get TRACK_APPLICATION_URL() {
        return ProjectConfig.PROJECT_CONFIG_MAP[ProjectConfig.tags.TRACK_APPLICATION_URL];
    }

    get USD_PASSPORT_APPLICATION_URL() {
        return ProjectConfig.PROJECT_CONFIG_MAP[ProjectConfig.tags.USD_PASSPORT_APPLICATION_URL];
    }

    get OLD_NGN_PASSPORT_APPLICATION_SEARCH_URL() {
        return ProjectConfig.PROJECT_CONFIG_MAP[ProjectConfig.tags.OLD_NGN_PASSPORT_APPLICATION_SEARCH_URL];
    }

    get OLD_NGN_PASSPORT_APPLICATION_GUARANTOR_URL() {
        return ProjectConfig.PROJECT_CONFIG_MAP[ProjectConfig.tags.OLD_NGN_PASSPORT_APPLICATION_GUARANTOR_URL];
    }

    get CENTRE_AVAILABILITY_URL() {
        return ProjectConfig.PROJECT_CONFIG_MAP[ProjectConfig.tags.CENTRE_AVAILABILITY_URL];
    }
}
