import { Injectable } from '@angular/core';
import { Ngxalert } from 'ngx-dialogs';
import { ToastrService } from 'ngx-toastr';
import { ProjectConfig } from './project-config';

@Injectable({ 
    providedIn: 'root'
})
export class Toast {
    // private toasterOptions: {};

    // constructor(private toaster: ToastrService, private projectconfig: ProjectConfig){
    //     this.toasterOptions = {
    //         progressBar: true,
    //         timeOut: projectconfig.TOASTER_DELAY,
    //         closeButton: true
    //     }
    // }

    // showSuccessToast(message: string){
    //     this.toaster.success(message, "", this.toasterOptions);
    // }

    // showErrorToast(message: string){
    //     this.toaster.error(message, "", this.toasterOptions);
    // }

    // showWarningToast(message: string){
    //     this.toaster.warning(message, "", this.toasterOptions);
    // }

    private ngxalert = new Ngxalert();

    constructor(){
    }

    showSuccessToast(message: string){
        this.showDialog("Confirmation", message, 'dialog-success', null);
    }

    showErrorToast(message: string){
        this.showDialog("Error", message, 'dialog-error', null);
    }

    showErrorToastWithCallbackFunction(message: string, callbackFunction: Function){
        this.showDialog("Error", message, 'dialog-error-no-close', callbackFunction);
    }

    showWarningToast(message: string){
        this.showDialog("Warning", message, 'dialog-error', null);
    }

    private showDialog(title: string, message: string, styleClass: string, callbackFunction: Function){
        this.ngxalert.create({ 
	    	title: title,
	      	message:  message,
	      	strict:  true,
            customCssClass: styleClass,
	      	buttons: [
	        	{
	          		title :  'OK',
	          		class:  'ok-btn',
	          		event : () => {
                        if(callbackFunction !== null){
                            callbackFunction();
                        }
	            		const closeSpan = <HTMLElement>document.querySelectorAll('.close-dialog')[0];
	            		closeSpan.click();
	          		}
	        	}
	      	]
	    });
    }

}