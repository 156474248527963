<app-header></app-header>

<div class="banner-img-name"></div>

<div class="w100">
    <div class="main">
      <h3 class="mb10">Check Change Request Status</h3>
        <form #codSearchForm="ngForm" (ngSubmit)="codSearchForm.form.valid && searchCODApplication()" autocomplete="off" id="" appFocus>
            <div class="half_w">
                <div class="form_box">
                    <label class="fieldlabels">Passcode <span class="medatory">*</span></label>
                    <div class="input_box">
                        <input type="text" name="passcode" hint [(ngModel)]="codSearchFormObject.passcode" #passcode="ngModel" class="effect" required="required" autocomplete="off" tabindex="1" maxlength="15" firstFocus>
                        <span class="tooltip"><span class='tooltipArrow'></span>Enter your passcode.</span>
                        <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="codSearchForm.submitted && passcode.invalid">
                        <span *ngIf="passcode.errors.required">
                            Passcode is required!
                        </span>
                    </span>
                </div>
            </div>

            <!-- <div class="captcha mt15">
                <ngx-recaptcha2 name="captchaVerified" (ngModel)="codSearchFormObject.captchaVerified" [siteKey]="projectConfig.CAPTCHA_SITE_KEY" (load)="captcha.handleCaptchaLoad(codSearchFormObject)" (success)="captcha.handleCaptchaResponse(codSearchFormObject, $event)" (expire)="captcha.handleCaptchaExpire(codSearchFormObject)" size="normal" hl="en" theme="light" type="image"></ngx-recaptcha2>
                <span class="alert alert-danger" *ngIf="codSearchForm.submitted && codSearchFormObject.captchaVerified === false">
                    Captcha is required!
                </span>
            </div> -->

            <div class="w100">
                <input type="button" (click)="openHomePage()" class="back_btn" value="Back" style="margin-right: 5px;"/>
                <input *ngIf="showSearchButton === true" type="submit" class="action-button save_btn" value="Search">
                <input *ngIf="serverResponseCode === constants.COD_APPLICATION_NOT_CONFIRMED_ERROR_CODE" type="button" (click)="openCODConfirmationPage()" class="action-button save_btn" value="Confirm">
            </div>
        </form>
    </div>
</div>

<footer>
    <div class="main-footer">
        <div class="footer_flex">
            <p>Copyright © {{latestYear}} | All rights reserved.</p>
            <img src="assets/images/newworks-logo.png" >
        </div>
   </div>
</footer>
