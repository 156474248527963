import { Injectable } from '@angular/core';

@Injectable({ 
    providedIn: 'root'
})
export class LocalStorage {
    private storage: Storage;

    constructor(){
        this.storage = window.localStorage;
    }

    setStorageKey(keyName: string, keyValue: string){
        this.storage.setItem(keyName, keyValue);
    }

    getStorageKey(keyName: string){
        return this.storage.getItem(keyName) != null ? this.storage.getItem(keyName) : "";
    }

    removeStorageKey(keyName: string){
        this.storage.removeItem(keyName);
    }

    removeAllStorageData(){
        this.storage.clear();
    }

}