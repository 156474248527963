<app-header></app-header>

<div class="banner-img-name"></div>

<div class="main">

    <h3 class="mb10">Apply For Change Request</h3>

	<div style="width:100%;float:left;">
		<section class="tabs-container">
			<label *ngIf="applicationHighestStage >= 1" (click)="openSection(1);">Current Passport Details <i class="fa fa-angle-right link" aria-hidden="true"></i></label>
			<label *ngIf="applicationHighestStage >= 2" (click)="openSection(2);">Name Changes <i class="fa fa-angle-right link" aria-hidden="true"></i></label>
			<label *ngIf="applicationHighestStage >= 3" (click)="openSection(3);">Date of Birth Change <i class="fa fa-angle-right link" aria-hidden="true"></i></label>
			<label *ngIf="applicationHighestStage >= 4" (click)="openSection(4);">Gender Change <i class="fa fa-angle-right link" aria-hidden="true"></i></label>
		</section>

		<section *ngIf="applicationStage === 1" class="tab-content">

            <h3>Current Passport Details</h3>

            <form #personalDetailsForm="ngForm" (ngSubmit)="personalDetailsForm.form.valid && openNameChangeForm()" [validateDateOfBirth]="['currentDateOfBirthDay', 'currentDateOfBirthMonth', 'currentDateOfBirthYear', '', constants.FALSE]" autocomplete="off" id="msform" appFocus>

                <div class="grid-two">

                    <div class="form_box">
                        <label class="fieldlabels">Passport Type <span class="medatory">*</span></label>
                        <div class="input_box">
                            <select name="currentPassportType" [(ngModel)]="personalDetailsFormObject.currentPassportType" #currentPassportType="ngModel" class="effect" required="required" tabindex="1">
                                <option [value]="constants.STANDARD_PASSPORT">Standard Passport</option>
                            </select>
                            <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <span class="tooltip"><span class='tooltipArrow'></span>Please choose passport type field.</span>
                            <span class="focus-border "><i></i></span>
                        </div>
                        <span class="alert alert-danger" role="alert" *ngIf="personalDetailsForm.submitted && currentPassportType.invalid">
                            <span *ngIf="currentPassportType.errors.required">
                                Passport Type is required!
                            </span>
                        </span>
                    </div>

                    <div class="form_box">
                        <label class="fieldlabels">Passport Number <span class="medatory">*</span></label>
                        <div class="input_box">
                            <input type="text" name="currentPassportNumber" hint alphaNumericOnly [(ngModel)]="personalDetailsFormObject.currentPassportNumber" #currentPassportNumber="ngModel" pattern="^[A-Za-z].*$" (input)="personalDetailsFormObject.currentPassportNumber = personalDetailsFormObject.currentPassportNumber.toUpperCase()" class="effect" required="required" autocomplete="off" tabindex="2" minlength="9" maxlength="9">
                            <span class="tooltip"><span class='tooltipArrow'></span>Enter your passport number as it appears on your passport. The passport number may contain numbers and/or characters. Please closely distinguish between the number zero and the letter O. The passport number is required to complete the application or to check the status of your application.</span>
                            <span class="focus-border "><i></i></span>
                        </div>
                        <span class="alert alert-danger" role="alert" *ngIf="personalDetailsForm.submitted && currentPassportNumber.invalid">
                            <span *ngIf="currentPassportNumber.errors?.required">
                                Passport Number is required!
                            </span>
                            <span *ngIf="currentPassportNumber.errors?.minlength">
                                Passport Number should be 9 digits!
                            </span>
                            <span *ngIf="!currentPassportNumber.errors?.minlength && currentPassportNumber.errors?.pattern">
                                Passport Number should start with alphabet!
                            </span>

                        </span>
                    </div>

                    <div class="form_box">
                        <label class="fieldlabels">Title <span class="medatory">*</span></label>
                        <div class="input_box">
                            <select name="currentTitle" [(ngModel)]="personalDetailsFormObject.currentTitle" #currentTitle="ngModel" (ngModelChange)="setGender();" class="effect" required="required" tabindex="3">
                                <option value="">Choose Option</option>
                                <option [value]="constants.MR">Mr.</option>
                                <option [value]="constants.MRS">Mrs.</option>
                                <option [value]="constants.MISS">Miss</option>
                            </select>
                            <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <span class="tooltip"><span class='tooltipArrow'></span></span>
                            <span class="focus-border "><i></i></span>
                        </div>
                        <span class="alert alert-danger" role="alert" *ngIf="personalDetailsForm.submitted && currentTitle.invalid">
                            <span *ngIf="currentTitle.errors.required">
                                Title is required!
                            </span>
                        </span>
                    </div>

                    <div class="form_box ">
                        <label class="fieldlabels">Surname <span class="medatory">*</span></label>
                        <div class="input_box">
                            <input type="text" name="currentLastName" hint name-directive [(ngModel)]="personalDetailsFormObject.currentLastName" #currentLastName="ngModel" class="effect" required="required" autocomplete="off" tabindex="4" minlength="2" maxlength="20" pattern="^[A-Za-z].*[A-Za-z]$">
                            <span class="tooltip"><span class='tooltipArrow'></span>Please fill Surname as stated in passport.</span>
                            <span class="focus-border "><i></i></span>
                        </div>
                        <span class="alert alert-danger" role="alert" *ngIf="personalDetailsForm.submitted && currentLastName.invalid">
                            <span *ngIf="currentLastName.errors.required">
                                Surname is required!
                            </span>
                            <span *ngIf="currentLastName.errors.minlength">
                                Surname should minimum 2 characters!
                            </span>
                            <span *ngIf="!currentLastName.errors.minlength && currentLastName.errors.pattern">
                                Surname should start and end with alphabet!
                            </span>
                        </span>
                    </div>

                    <div class="form_box ">
                        <label class="fieldlabels">First Name <span class="medatory">*</span></label>
                        <div class="input_box">
                            <input type="text" name="currentFirstName" hint name-with-number-directive [(ngModel)]="personalDetailsFormObject.currentFirstName" #currentFirstName="ngModel" class="effect" required="required" autocomplete="off" tabindex="5" minlength="2" maxlength="20" pattern="^[A-Za-z].*[A-Za-z0-9]$">
                            <span class="tooltip"><span class='tooltipArrow'></span>First names (given names) as stated in passport.</span>
                            <span class="focus-border"><i></i></span>
                        </div>
                        <span class="alert alert-danger" role="alert" *ngIf="personalDetailsForm.submitted && currentFirstName.invalid">
                            <span *ngIf="currentFirstName.errors.required">
                                First Name is required!
                            </span>
                            <span *ngIf="currentFirstName.errors.minlength">
                                First Name should minimum 2 characters!
                            </span>
                            <span *ngIf="!currentFirstName.errors.minlength && currentFirstName.errors.pattern">
                                First Name should start and end with alphabet!
                            </span>
                        </span>
                    </div>

                    <div class="form_box ">
                        <label class="fieldlabels">Middle Name (Optional)</label>
                        <div class="input_box">
                            <input type="text" name="currentMiddleName" hint name-directive [(ngModel)]="personalDetailsFormObject.currentMiddleName" #currentMiddleName="ngModel" class="effect" autocomplete="off" tabindex="6" minlength="2" maxlength="30" pattern="^[A-Za-z].*[A-Za-z]$">
                            <span class="tooltip"><span class='tooltipArrow'></span>In the middle name field, enter your middle name as the  appears on your passport. The  Middle name is optional .</span>
                            <span class="focus-border "><i></i></span>
                        </div>
                        <span class="alert alert-danger" role="alert" *ngIf="personalDetailsForm.submitted && currentMiddleName.invalid">
                            <span *ngIf="currentMiddleName.errors.minlength">
                                Middle Name should minimum 2 characters!
                            </span>
                            <span *ngIf="!currentMiddleName.errors.minlength && currentMiddleName.errors.pattern">
                                Middle Name should start and end with alphabet!
                            </span>
                        </span>
                    </div>

                    <div class="form_box">
                        <label class="fieldlabels">Gender <span class="medatory">*</span></label>
                        <div class="input_box">
                            <select name="currentGender" [(ngModel)]="personalDetailsFormObject.currentGender" #currentGender="ngModel" class="effect" required="required" tabindex="7">
                                <option value="">Choose Option</option>
                                <option [value]="constants.MALE">Male</option>
                                <option [value]="constants.FEMALE">Female</option>
                            </select>
                            <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <span class="tooltip"><span class='tooltipArrow'></span>Choose your gender, either male or female or other. The gender is required to complete the application.</span>
                            <span class="focus-border "><i></i></span>
                        </div>
                        <span class="alert alert-danger" role="alert" *ngIf="personalDetailsForm.submitted && currentGender.invalid">
                            <span *ngIf="currentGender.errors.required">
                                Gender is required!
                            </span>
                        </span>
                    </div>

                    <div class="form_box">
                        <label class="fieldlabels">Date of Birth (dd/mm/yyyy) <span class="medatory">*</span></label>
                        <div class="grid-date">
                            <div class="input_box">
                                <input type="text" name="currentDateOfBirthDay" hint numberOnly [(ngModel)]="personalDetailsFormObject.currentDateOfBirthDay" #currentDateOfBirthDay="ngModel" class="effect" required="required" autocomplete="off" tabindex="8" maxlength="2" placeholder="DD">
                                <span class="tooltip"><span class='tooltipArrow'></span>The date of birth is required to complete the application or to check the status of your application. </span>
                                <span class="focus-border "><i></i></span>
                            </div>

                            <div class="input_box">
                                <select name="currentDateOfBirthMonth" [(ngModel)]="personalDetailsFormObject.currentDateOfBirthMonth" #currentDateOfBirthMonth="ngModel" class="effect" required="required" autocomplete="off" tabindex="9">
                                    <option *ngFor="let object of monthsList;" [value]="object.value">{{object.text}}</option>
                                </select>
                                <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                                <span class="tooltip"><span class='tooltipArrow'></span></span>
                                <span class="focus-border "><i></i></span>
                            </div>

                            <div class="input_box">
                                <input type="text" name="currentDateOfBirthYear" hint numberOnly [(ngModel)]="personalDetailsFormObject.currentDateOfBirthYear" #currentDateOfBirthYear="ngModel" class="effect" required="required" autocomplete="off" tabindex="10" minlength="4" maxlength="4" placeholder="YYYY">
                                <span class="tooltip"><span class='tooltipArrow'></span>The date of birth is required to complete the application or to check the status of your application. </span>
                                <span class="focus-border "><i></i></span>
                            </div>
                        </div>
                        <span class="alert alert-danger" role="alert" *ngIf="personalDetailsForm.submitted && (currentDateOfBirthDay.invalid || currentDateOfBirthMonth.invalid || currentDateOfBirthYear.invalid)">
                            <span *ngIf="(currentDateOfBirthDay.errors?.required || currentDateOfBirthMonth.errors?.required || currentDateOfBirthYear.errors?.required)">
                                Date of Birth is required!
                            </span>
                            <span *ngIf="currentDateOfBirthYear.errors?.minlength">
                                Date of Birth's year should be 4 digits!
                            </span>
                            <span *ngIf="!currentDateOfBirthYear.errors?.minlength && currentDateOfBirthDay.errors?.hasError">
                                {{currentDateOfBirthDay.errors.errorMessage}}
                            </span>
                        </span>
                    </div>

                    <div class="form_box">
                        <label class="fieldlabels">Place of Birth <span class="medatory">*</span></label>
                        <div class="input_box">
                            <input type="text" name="currentPlaceOfBirth" hint class="effect" required="required" autocomplete="off" [(ngModel)]="personalDetailsFormObject.currentPlaceOfBirth" #currentPlaceOfBirth="ngModel" tabindex="11" minlength="2" maxlength="20" pattern="^[A-Za-z].*[A-Za-z]$">
                            <span class="tooltip"><span class='tooltipArrow'></span>Enter the city, town, village in which you were born is required to complete the application. If you do not know the city in which you were born, enter UNKNOWN.</span>
                            <span class="focus-border "><i></i></span>
                        </div>
                        <span class="alert alert-danger" role="alert" *ngIf="personalDetailsForm.submitted && currentPlaceOfBirth.invalid">
                            <span *ngIf="currentPlaceOfBirth.errors.required">
                                Place of Birth is required!
                            </span>
                            <span *ngIf="currentPlaceOfBirth.errors.minlength">
                                Place of Birth should minimum 2 characters!
                            </span>
                            <span *ngIf="!currentPlaceOfBirth.errors.minlength && currentPlaceOfBirth.errors.pattern">
                                Place of Birth should start and end with alphabet!
                            </span>
                        </span>
                    </div>

                </div>

                <div class="captcha mt15">
                  <!-- <span (load)="captcha.handleCaptchaLoad(personalDetailsFormObject)"></span> -->
                    <!-- <ngx-recaptcha2 name="captchaVerified" (ngModel)="personalDetailsFormObject.captchaVerified" (load)="captcha.handleCaptchaLoad(personalDetailsFormObject)" (success)="captcha.handleCaptchaResponse(personalDetailsFormObject, $event)"
                     (expire)="captcha.handleCaptchaExpire(personalDetailsFormObject)" size="normal" hl="en"
                     theme="light" type="image"></ngx-recaptcha2> -->
                    <!-- <span class="alert alert-danger" *ngIf="personalDetailsForm.submitted && personalDetailsFormObject.captchaVerified === false">
                        Captcha is required!
                    </span> -->
                </div>

                <input type="button" (click)="openCODHomePage()" value="Back" class="action-button-previous back_btn">
                <input type="submit" class="action-button custom_btn" value="Continue">

            </form>
        </section>

		<section *ngIf="applicationStage === 2" class="tab-content">

            <h3>Name Change Details</h3>

            <form #nameChangeDetailsForm="ngForm" (ngSubmit)="nameChangeDetailsForm.form.valid && openDateOfBirthChangeForm()" autocomplete="off" id="msform" appFocus>

                <div class="form_box pdl15">
                    <label class="fieldlabels">Do you want to change your name <span><b>({{personalDetailsFormObject.currentTitleLabel}} {{personalDetailsFormObject.currentFirstName}} {{personalDetailsFormObject.currentMiddleName}} {{personalDetailsFormObject.currentLastName}})</b></span>? <span class="medatory">*</span></label>
                    <div class="radio">
                        <input type="radio" name="isDataChange" [value]="constants.TRUE" [(ngModel)]="nameChangeDetailsFormObject.isDataChange" #isDataChange="ngModel" required="required" class="infoyn" id="first" ><label for="first" class="infoyn" tabindex="1">Yes</label>
                        <input type="radio" name="isDataChange" [value]="constants.FALSE" [(ngModel)]="nameChangeDetailsFormObject.isDataChange" #isDataChange="ngModel" required="required" class="infoyn" id="second"><label for="second" class="infoyn" tabindex="1">No</label>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="nameChangeDetailsForm.submitted && isDataChange.invalid">
                        <span *ngIf="isDataChange.errors.required">
                            This is required!
                        </span>
                    </span>
                </div>

                <div *ngIf="nameChangeDetailsFormObject.isDataChange === constants.TRUE" class="grid-two">

                    <div class="form_box">
                        <label class="fieldlabels">Reason For Change <span class="medatory">*</span></label>
                        <div class="input_box">
                            <select name="reason" [(ngModel)]="nameChangeDetailsFormObject.reason" #reason="ngModel" (ngModelChange)="setNameDefaultValues();" class="effect" required="required" tabindex="5">
                                <option [value]="constants.BLANK">Choose Option</option>
                                <!-- <option [value]="constants.MARRIAGE">Marriage</option>
                                <option [value]="constants.DIVORCE">Divorce</option>
                                <option [value]="constants.DECEASED_SPOUSE">Deceased Spouse</option> -->
                                <option [value]="constants.OTHERS">Change Of Data</option>
                            </select>
                            <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <span class="tooltip"><span class='tooltipArrow'></span>Please Choose Reason For Change.</span>
                            <span class="focus-border "><i></i></span>
                        </div>
                        <span class="alert alert-danger" role="alert" *ngIf="nameChangeDetailsForm.submitted && reason.invalid">
                            <span *ngIf="reason.errors.required">
                                Reason For Change is required!
                            </span>
                        </span>
                    </div>

                    <div class="form_box" *ngIf="nameChangeDetailsFormObject.reason === constants.OTHERS">
                        <label class="fieldlabels">Reason For Change Of Data <span class="medatory">*</span></label>
                        <div class="input_box">
                            <input type="text" name="otherReason" hint [(ngModel)]="nameChangeDetailsFormObject.otherReason" #otherReason="ngModel" class="effect" required="required" autocomplete="off" tabindex="6" minlength="2" maxlength="150" pattern="^[A-Za-z].*[A-Za-z]$">
                            <span class="tooltip"><span class='tooltipArrow'></span>Enter Other Reason For Change.</span>
                            <span class="focus-border"><i></i></span>
                        </div>
                        <span class="alert alert-danger" role="alert" *ngIf="nameChangeDetailsForm.submitted && otherReason.invalid">
                            <span *ngIf="otherReason.errors.required">
                                Reason For Change Of Data is required!
                            </span>
                            <span *ngIf="otherReason.errors.minlength">
                                Reason For Change Of Data should minimum 2 characters!
                            </span>
                            <span *ngIf="!otherReason.errors.minlength && otherReason.errors.pattern">
                                Reason For Change Of Data should start and end with alphabet!
                            </span>
                        </span>
                    </div>
                </div>

                <div *ngIf="nameChangeDetailsFormObject.isDataChange === constants.TRUE" class="grid-two mt15">
                    <!-- <div class="form_box">
                        <label class="fieldlabels">Desired Title <span class="medatory">*</span></label>
                        <div class="input_box">
                            <select name="desiredTitle" [(ngModel)]="nameChangeDetailsFormObject.desiredTitle" #desiredTitle="ngModel" class="effect" required="required" tabindex="1" [disabled]="disableNameFields">
                                <option value="">Choose Option</option>
                                <option [value]="constants.MR">Mr.</option>
                                <option [value]="constants.MRS">Mrs.</option>
                                <option [value]="constants.MISS">Miss</option>
                            </select>
                            <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <span class="tooltip"><span class='tooltipArrow'></span></span>
                            <span class="focus-border "><i></i></span>
                        </div>
                        <span class="alert alert-danger" role="alert" *ngIf="nameChangeDetailsForm.submitted && desiredTitle.invalid">
                            <span *ngIf="desiredTitle.errors.required">
                                Desired Title is required!
                            </span>
                        </span>
                    </div> -->

                    <div class="form_box ">
                        <label class="fieldlabels">Desired Surname <span class="medatory">*</span></label>
                        <div class="input_box">
                            <input type="text" name="desiredLastName" hint name-directive [(ngModel)]="nameChangeDetailsFormObject.desiredLastName" #desiredLastName="ngModel" class="effect" required="required" autocomplete="off" tabindex="2" minlength="2" maxlength="20" pattern="^[A-Za-z].*[A-Za-z]$">
                            <span class="tooltip"><span class='tooltipArrow'></span>Please fill Surname as stated in passport.</span>
                            <span class="focus-border "><i></i></span>
                        </div>
                        <span class="alert alert-danger" role="alert" *ngIf="nameChangeDetailsForm.submitted && desiredLastName.invalid">
                            <span *ngIf="desiredLastName.errors.required">
                                Desired Surname is required!
                            </span>
                            <span *ngIf="desiredLastName.errors.minlength">
                                Desired Surname should minimum 2 characters!
                            </span>
                            <span *ngIf="!desiredLastName.errors?.minlength && desiredLastName.errors.pattern">
                                Desired Surname should start and end with alphabet!
                            </span>
                        </span>
                    </div>

                    <div class="form_box ">
                        <label class="fieldlabels">Desired First Name <span class="medatory">*</span></label>
                        <div class="input_box">
                            <input type="text" name="desiredFirstName" hint name-with-number-directive [(ngModel)]="nameChangeDetailsFormObject.desiredFirstName" #desiredFirstName="ngModel" class="effect" required="required" autocomplete="off" tabindex="3" minlength="2" maxlength="20" pattern="^[A-Za-z].*[A-Za-z0-9]$" [disabled]="disableNameFields">
                            <span class="tooltip"><span class='tooltipArrow'></span>First names (given names) as stated in passport.</span>
                            <span class="focus-border"><i></i></span>
                        </div>
                        <span class="alert alert-danger" role="alert" *ngIf="nameChangeDetailsForm.submitted && desiredFirstName.invalid">
                            <span *ngIf="desiredFirstName.errors.required">
                                Desired First Name is required!
                            </span>
                            <span *ngIf="desiredFirstName.errors.minlength">
                                Desired First Name should minimum 2 characters!
                            </span>
                            <span *ngIf="!desiredFirstName.errors.minlength && desiredFirstName.errors.pattern">
                                Desired First Name should start and end with alphabet!
                            </span>
                        </span>
                    </div>

                    <div class="form_box">
                        <label class="fieldlabels">Desired Middle Name (Optional)</label>
                        <div class="input_box">
                            <input type="text" name="desiredMiddleName" hint name-directive [(ngModel)]="nameChangeDetailsFormObject.desiredMiddleName" #desiredMiddleName="ngModel" class="effect" autocomplete="off" tabindex="4" minlength="2" maxlength="30" pattern="^[A-Za-z].*[A-Za-z]$" [disabled]="disableNameFields">
                            <span class="tooltip"><span class='tooltipArrow'></span>In the middle name field, enter your middle name as the  appears on your passport. The  Middle name is optional .</span>
                            <span class="focus-border "><i></i></span>
                        </div>
                        <span class="alert alert-danger" role="alert" *ngIf="nameChangeDetailsForm.submitted && desiredMiddleName.invalid">
                            <span *ngIf="desiredMiddleName.errors.minlength">
                                Desired Middle Name should minimum 2 characters!
                            </span>
                            <span *ngIf="!desiredMiddleName.errors.minlength && desiredMiddleName.errors.pattern">
                                Desired Middle Name should start and end with alphabet!
                            </span>
                        </span>
                    </div>

                </div>

                <br>

                <input type="button" (click)="openPreviousSection()" value="Back" class="action-button-previous back_btn">
                <input type="submit" class="action-button custom_btn" value="Continue">
            </form>
        </section>

        <section *ngIf="applicationStage === 3" class="tab-content">

            <h3>Date of Birth Change Details</h3>

            <form #dateOfBirthChangeDetailsForm="ngForm" (ngSubmit)="dateOfBirthChangeDetailsForm.form.valid && openGenderChangeForm()" [validateDateOfBirth]="['desiredDateOfBirthDay', 'desiredDateOfBirthMonth', 'desiredDateOfBirthYear', '', constants.FALSE]" autocomplete="off" id="msform" appFocus>

                <div class="form_box pdl15">
                    <label class="fieldlabels">Do you want to change your Date of Birth <span><b>({{personalDetailsFormObject.currentDateOfBirth}})</b></span>? <span class="medatory">*</span></label>
                    <div class="radio">
                        <input type="radio" name="isDateOfBirthChange" [value]="constants.TRUE" [(ngModel)]="dateOfBirthChangeDetailsFormObject.isDateOfBirthChange" #isDateOfBirthChange="ngModel" required="required" class="infoyn" id="dateOfBirthFirst" ><label for="dateOfBirthFirst" class="infoyn" tabindex="1">Yes</label>
                        <input type="radio" name="isDateOfBirthChange" [value]="constants.FALSE" [(ngModel)]="dateOfBirthChangeDetailsFormObject.isDateOfBirthChange" #isDateOfBirthChange="ngModel" required="required" class="infoyn" id="dateOfBirthSecond"><label for="dateOfBirthSecond" class="infoyn" tabindex="1">No</label>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="dateOfBirthChangeDetailsForm.submitted && isDateOfBirthChange.invalid">
                        <span *ngIf="isDateOfBirthChange.errors.required">
                            This is required!
                        </span>
                    </span>
                </div>

                <div *ngIf="dateOfBirthChangeDetailsFormObject.isDateOfBirthChange === constants.TRUE" class="grid-two">

                    <div class="form_box">
                        <label class="fieldlabels">Date of Birth (dd/mm/yyyy) <span class="medatory">*</span></label>
                        <div class="grid-date">
                            <div class="input_box">
                                <input type="text" name="desiredDateOfBirthDay" hint numberOnly [(ngModel)]="dateOfBirthChangeDetailsFormObject.desiredDateOfBirthDay" #desiredDateOfBirthDay="ngModel" class="effect" required="required" autocomplete="off" tabindex="2" maxlength="2" placeholder="DD">
                                <span class="tooltip"><span class='tooltipArrow'></span>The date of birth is required to complete the application or to check the status of your application. </span>
                                <span class="focus-border "><i></i></span>
                            </div>

                            <div class="input_box">
                                <select name="desiredDateOfBirthMonth" [(ngModel)]="dateOfBirthChangeDetailsFormObject.desiredDateOfBirthMonth" #desiredDateOfBirthMonth="ngModel" class="effect" required="required" autocomplete="off" tabindex="3">
                                    <option *ngFor="let object of monthsList;" [value]="object.value">{{object.text}}</option>
                                </select>
                                <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                                <span class="tooltip"><span class='tooltipArrow'></span></span>
                                <span class="focus-border "><i></i></span>
                            </div>

                            <div class="input_box">
                                <input type="text" name="desiredDateOfBirthYear" hint numberOnly [(ngModel)]="dateOfBirthChangeDetailsFormObject.desiredDateOfBirthYear" #desiredDateOfBirthYear="ngModel" class="effect" required="required" autocomplete="off" tabindex="4" minlength="4" maxlength="4" placeholder="YYYY">
                                <span class="tooltip"><span class='tooltipArrow'></span>The date of birth is required to complete the application or to check the status of your application. </span>
                                <span class="focus-border "><i></i></span>
                            </div>
                        </div>
                        <span class="alert alert-danger" role="alert" *ngIf="dateOfBirthChangeDetailsForm.submitted && (desiredDateOfBirthDay.invalid || desiredDateOfBirthMonth.invalid || desiredDateOfBirthYear.invalid)">
                            <span *ngIf="(desiredDateOfBirthDay.errors?.required || desiredDateOfBirthMonth.errors?.required || desiredDateOfBirthYear.errors?.required)">
                                Date of Birth is required!
                            </span>
                            <span *ngIf="desiredDateOfBirthYear.errors?.minlength">
                                Date of Birth's year should be 4 digits!
                            </span>
                            <span *ngIf="!desiredDateOfBirthYear.errors?.minlength && desiredDateOfBirthDay.errors?.hasError">
                                {{desiredDateOfBirthDay.errors.errorMessage}}
                            </span>
                        </span>
                    </div>

                    <div class="form_box">
                        <label class="fieldlabels">Reason For Change <span class="medatory">*</span></label>
                        <div class="input_box">
                            <input type="text" name="dateOfBirthReason" hint [(ngModel)]="dateOfBirthChangeDetailsFormObject.dateOfBirthReason" #dateOfBirthReason="ngModel" class="effect" required="required" autocomplete="off" tabindex="5" minlength="2" maxlength="150" pattern="^[A-Za-z].*[A-Za-z]$">
                            <span class="tooltip"><span class='tooltipArrow'></span>Enter Reason For Change.</span>
                            <span class="focus-border"><i></i></span>
                        </div>
                        <span class="alert alert-danger" role="alert" *ngIf="dateOfBirthChangeDetailsForm.submitted && dateOfBirthReason.invalid">
                            <span *ngIf="dateOfBirthReason.errors.required">
                                Reason For Change is required!
                            </span>
                            <span *ngIf="dateOfBirthReason.errors.minlength">
                                Reason For Change should minimum 2 characters!
                            </span>
                            <span *ngIf="!dateOfBirthReason.errors.minlength && dateOfBirthReason.errors.pattern">
                                Reason For Change should start and end with alphabet!
                            </span>
                        </span>
                    </div>

                </div>

                <h4 class="seprate">Place of Birth</h4>

                <div class="form_box pdl15">
                    <label class="fieldlabels">Do you want to change your Place of Birth <span><b>({{personalDetailsFormObject.currentPlaceOfBirth}})</b></span>? <span class="medatory">*</span></label>
                    <div class="radio">
                        <input type="radio" name="isPlaceOfBirthChange" [value]="constants.TRUE" [(ngModel)]="dateOfBirthChangeDetailsFormObject.isPlaceOfBirthChange" #isPlaceOfBirthChange="ngModel" required="required" class="infoyn" id="placeOfBirthFirst" ><label for="placeOfBirthFirst" class="infoyn" tabindex="6">Yes</label>
                        <input type="radio" name="isPlaceOfBirthChange" [value]="constants.FALSE" [(ngModel)]="dateOfBirthChangeDetailsFormObject.isPlaceOfBirthChange" #isPlaceOfBirthChange="ngModel" required="required" class="infoyn" id="placeOfBirthSecond"><label for="placeOfBirthSecond" class="infoyn" tabindex="6">No</label>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="dateOfBirthChangeDetailsForm.submitted && isPlaceOfBirthChange.invalid">
                        <span *ngIf="isPlaceOfBirthChange.errors.required">
                            This is required!
                        </span>
                    </span>
                </div>

                <div *ngIf="dateOfBirthChangeDetailsFormObject.isPlaceOfBirthChange === constants.TRUE" class="grid-two">
                    <div class="form_box">
                        <label class="fieldlabels">Place of Birth <span class="medatory">*</span></label>
                        <div class="input_box">
                            <input type="text" name="desiredPlaceOfBirth" hint [(ngModel)]="dateOfBirthChangeDetailsFormObject.desiredPlaceOfBirth" #desiredPlaceOfBirth="ngModel" class="effect" required="required" autocomplete="off" tabindex="7" maxlength="50">
                            <span class="tooltip"><span class='tooltipArrow'></span>The place of birth is required to complete the application or to check the status of your application. </span>
                            <span class="focus-border "><i></i></span>
                        </div>
                        <span class="alert alert-danger" role="alert" *ngIf="dateOfBirthChangeDetailsForm.submitted && desiredPlaceOfBirth.invalid">
                            <span *ngIf="desiredPlaceOfBirth.errors.required">
                                Place of Birth is required!
                            </span>
                        </span>
                    </div>

                    <div class="form_box">
                        <label class="fieldlabels">Reason For Change <span class="medatory">*</span></label>
                        <div class="input_box">
                            <input type="text" name="placeOfBirthReason" hint [(ngModel)]="dateOfBirthChangeDetailsFormObject.placeOfBirthReason" #placeOfBirthReason="ngModel" class="effect" required="required" autocomplete="off" tabindex="8" minlength="2" maxlength="150" pattern="^[A-Za-z].*[A-Za-z]$">
                            <span class="tooltip"><span class='tooltipArrow'></span>Enter Reason For Change.</span>
                            <span class="focus-border"><i></i></span>
                        </div>
                        <span class="alert alert-danger" role="alert" *ngIf="dateOfBirthChangeDetailsForm.submitted && placeOfBirthReason.invalid">
                            <span *ngIf="placeOfBirthReason.errors.required">
                                Reason For Change is required!
                            </span>
                            <span *ngIf="placeOfBirthReason.errors.minlength">
                                Reason For Change should minimum 2 characters!
                            </span>
                            <span *ngIf="!placeOfBirthReason.errors.minlength && placeOfBirthReason.errors.pattern">
                                Reason For Change should start and end with alphabet!
                            </span>
                        </span>
                    </div>
                </div>
                <br>
                <input type="button" (click)="openPreviousSection()" value="Back" class="action-button-previous back_btn">
                <input type="submit" class="action-button custom_btn" value="Continue">
            </form>
        </section>

        <section *ngIf="applicationStage === 4" class="tab-content">

            <h3>Gender Change Details</h3>

            <form #genderChangeDetailsForm="ngForm" (ngSubmit)="genderChangeDetailsForm.form.valid && openConfirmationPage()" autocomplete="off" id="msform" appFocus>

                <div class="form_box pdl15">
                    <label class="fieldlabels">Do you want to change your Gender <span><b>({{personalDetailsFormObject.currentGender}})</b></span>? <span class="medatory">*</span></label>
                    <div class="radio">
                        <input type="radio" name="isGenderChange" [value]="constants.TRUE" [(ngModel)]="genderChangeDetailsFormObject.isGenderChange" #isGenderChange="ngModel" required="required" class="infoyn" id="first" ><label for="first" class="infoyn" tabindex="1">Yes</label>
                        <input type="radio" name="isGenderChange" [value]="constants.FALSE" [(ngModel)]="genderChangeDetailsFormObject.isGenderChange" #isGenderChange="ngModel" required="required" class="infoyn" id="second"><label for="second" class="infoyn" tabindex="1">No</label>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="genderChangeDetailsForm.submitted && isGenderChange.invalid">
                        <span *ngIf="isGenderChange.errors.required">
                            This is required!
                        </span>
                    </span>
                </div>

                <div *ngIf="genderChangeDetailsFormObject.isGenderChange === constants.TRUE" class="grid-two">

                    <div class="form_box">
                        <label class="fieldlabels">Gender <span class="medatory">*</span></label>
                        <div class="input_box">
                            <select name="desiredGender" [(ngModel)]="genderChangeDetailsFormObject.desiredGender" #desiredGender="ngModel" class="effect" required="required" tabindex="2">
                                <option value="">Choose Option</option>
                                <option [value]="constants.MALE">Male</option>
                                <option [value]="constants.FEMALE">Female</option>
                            </select>
                            <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                            <span class="tooltip"><span class='tooltipArrow'></span>Choose your gender, either male or female or other. The gender is required to complete the application.</span>
                            <span class="focus-border "><i></i></span>
                        </div>
                        <span class="alert alert-danger" role="alert" *ngIf="genderChangeDetailsForm.submitted && desiredGender.invalid">
                            <span *ngIf="desiredGender.errors.required">
                                Gender is required!
                            </span>
                        </span>
                    </div>

                    <div class="form_box">
                        <label class="fieldlabels">Reason For Change <span class="medatory">*</span></label>
                        <div class="input_box">
                            <input type="text" name="genderReason" hint [(ngModel)]="genderChangeDetailsFormObject.genderReason" #genderReason="ngModel" class="effect" required="required" autocomplete="off" tabindex="3" minlength="2" maxlength="150" pattern="^[A-Za-z].*[A-Za-z]$">
                            <span class="tooltip"><span class='tooltipArrow'></span>Enter Reason For Change.</span>
                            <span class="focus-border"><i></i></span>
                        </div>
                        <span class="alert alert-danger" role="alert" *ngIf="genderChangeDetailsForm.submitted && genderReason.invalid">
                            <span *ngIf="genderReason.errors.required">
                                Reason For Change is required!
                            </span>
                            <span *ngIf="genderReason.errors.minlength">
                                Reason For Change should minimum 2 characters!
                            </span>
                            <span *ngIf="!genderReason.errors.minlength && genderReason.errors.pattern">
                                Reason For Change should start and end with alphabet!
                            </span>
                        </span>
                    </div>

                </div>
                <br>
                <input type="button" (click)="openPreviousSection()" value="Back" class="action-button-previous back_btn">
                <input type="submit" class="action-button custom_btn" value="Continue">
            </form>
        </section>

	</div>
</div>

<app-footer></app-footer>
