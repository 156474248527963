<div class="notice_modal  ">
    <div class="modal-header">
        <h4>IMPORTANT NOTICE</h4>
    </div>

    <div class="modal-body">
        <p>You have already applied for Change of Data in your passport, dated {{applicationDate}} and your passport will be re-issued under that process. So you are being redirected to an another page, where you can click on the link to complete the application.</p>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn" (click)="confirm()">Continue</button>
    </div>
</div>