import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-codhome',
  templateUrl: './codhome.component.html',
  styleUrls: ['./codhome.component.css']
})
export class CODHomeComponent implements OnInit {

  constructor(private router: Router, private ngZone: NgZone, private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, private toast: Toast, public utils: Utils,
              public constants: Constants, private loaderService: LoaderService, private title: Title) {
    this.title.setTitle("Apply for correction in passport data | Nigerian Immigration Services");
  }

  ngOnInit(): void {
  }

  openCODRequestPage(){
    this.ngZone.run(()=>this.router.navigate(["request"]));
  }

  openCODApprovePage(){
    this.ngZone.run(()=>this.router.navigate(["approve"]));
  }

  openCODSearchPage(){
    this.ngZone.run(()=>this.router.navigate(["exploration"]));
  }

  openHomePage(){
    this.ngZone.run(()=>this.router.navigate([""]));
  }

}