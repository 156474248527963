<app-header class="hide"></app-header>

<div class="banner-img-name hide"></div>

<div class="main">

    <form #codConfirmationForm="ngForm" (ngSubmit)="codConfirmationForm.form.valid && showConfirmationForApplicationSubmission()" autocomplete="off" id="msform" style="padding:0;" appFocus>
        <h2>Change of Data Details</h2>

        <div *ngIf="isServerResponseCome === true && changedFieldsArraySize === constants.FALSE" class="imp_info">
            <p>You have not selected any data field to be changed, please go back and select relevant field.</p>
            <input type="button" (click)="openCODHomePage()" value="Back" class="action-button-previous back_btn">
        </div>

        <ng-container *ngIf="isServerResponseCome === true && changedFieldsArraySize > constants.FALSE">
            <div>
                <p>Please note down the information and print this page for futher process.</p>
                <p><b>Application Number</b> <span class="highlight_txt"> {{applicationId}}</span>, <b>Passport Number</b> <span class="highlight_txt"> {{passportNumber}}</span> and <b>Passcode</b> <span class="highlight_txt"> {{passcode}}</span></p>
            </div>

            <table class="mt30">
                <tr>
                    <th style="font-size: 13px;">Fields to be Changed</th>
                    <th style="font-size: 13px;">Current Data</th>
                    <th style="font-size: 13px;">New Data</th>
                    <th style="font-size: 13px;">Reason</th>
                </tr>
                <tr *ngFor="let changedFieldObject of changedFieldsArray;">
                    <td style="border-left:0;" data-label="Fields to be Changed">{{changedFieldObject.fieldLabel}}</td>
                    <td data-label="Current Data" >{{changedFieldObject.existingValue}}</td>
                    <td data-label="New Data">{{changedFieldObject.newValue}}</td>
                    <td data-label="Reason">{{changedFieldObject.reason === constants.OTHERS ? changedFieldObject.otherReason : changedFieldObject.reason}}</td>
                </tr>
            </table>

            <div class="imp_info">
                <div *ngIf="isApprovalRequired === true" class="element">
                    <h4>Important Instructions</h4>
                    <ul>
                        <li>1. The above requested change will require Administrative approval from NIS.</li>
                        <li *ngIf="feeDetailsObject.adminCharges > 0">2. It also requires you to make a payment of {{feeDetailsObject.currencySymbol}} {{feeDetailsObject.adminChargesFormatted}}.</li>
                    </ul>
                </div>

                <div class="hide">
                    <input type="checkbox" name="isConfirmationChecked" [(ngModel)]="codConfirmationFormObject.isConfirmationChecked" #isConfirmationChecked="ngModel" required="required" style="width: auto; height: auto; margin: 15px 8px 15px 0;">
                    <span  style="padding: 0 3px;">I confirm the above provided information has supporting documents and will be provided when requested.</span>
                </div>
                <span class="alert alert-danger" role="alert" *ngIf="codConfirmationForm.submitted && isConfirmationChecked.invalid">
                    <span *ngIf="isConfirmationChecked.errors.required">
                        This is required!
                    </span>
                </span>

                <input type="submit" [disabled]="changedFieldsArraySize === constants.FALSE || codConfirmationFormObject.isConfirmationChecked === false" class="action-button custom_btn hide" value="Confirm">
            </div>
        </ng-container>

    </form>

</div>

<app-footer class="hide"></app-footer>
