import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ProjectConfig } from './project-config';
import { Constants } from './constants';
import { Toast } from './toast';
import { Router } from '@angular/router';
import { Tags } from './tags';
import { LocalStorage } from './local-storage';

@Injectable({
    providedIn: 'root'
})
export class HttpUtils {

    constructor(private router: Router, private ngZone: NgZone, private httpClient: HttpClient, private projectConfig: ProjectConfig, private constants: Constants, private toast: Toast,
                private tags: Tags, private localStorage: LocalStorage){}

    private fetchRequestHeaders(){
        let headers = new HttpHeaders();
        headers = headers.append(this.tags.APPLICATION_TOKEN, this.localStorage.getStorageKey(this.tags.APPLICATION_TOKEN));
        return headers;
    }

    private async sendPostRequest(url: string, requestBody: {}){
        let serverResponseBody: any = {responseCode: this.constants.INTERNAL_SERVER_ERROR};

        url = this.projectConfig.SERVER_BASE_URL + url;

        const headers = this.fetchRequestHeaders();

        return this.httpClient.post(url, requestBody, {headers}).toPromise()
            .then(response => {
                if(response['responseCode'] === this.constants.STATUS_AUTH_TOKEN_NOT_VALID){
                    this.toast.showErrorToast(response['responseMsg']);
                    this.ngZone.run(()=>this.router.navigate([""]));
                    return;
                }
                return response;
            }).catch(error => {
                console.log("Server error come", error);
                serverResponseBody.responseMsg = this.constants.SERVER_COMMUNICATION_ERROR_MESSAGE;
                return serverResponseBody;
            });
    }

    async fetchCountryStateList() {
        const requestBody = {};

        return await this.sendPostRequest("v1/config/fetchCountryStates", requestBody);
    }

    async fetchOfficialPassportOfficeDetails() {
        const requestBody = {};

        return await this.sendPostRequest("v1/config/officialPassportOffice", requestBody);
    }

    async fetchStateProcessingOfficeList(processingState: string) {
        const requestBody = {};

        return await this.sendPostRequest("v1/config/fetchStatePassportOffices/"+processingState, requestBody);
    }

    async fetchBookletTypeList(processingOffice: string){
        const requestBody = {};

        return await this.sendPostRequest("v1/config/fetchOfficeDetails/"+processingOffice, requestBody);
    }

    async fetchValidityList(passportTypeFormObject: any = {}){
        const requestBody = passportTypeFormObject;

        return await this.sendPostRequest("v1/config/validityList", requestBody);
    }

    async fetchPassportFeeDetails(formData: any = {}) {
        const requestBody = formData;

        return await this.sendPostRequest("v1/config/fetchPassportFee", requestBody);
    }

    async fetchStateLGAList(stateId: string) {
        const requestBody = {};

        return await this.sendPostRequest("v1/config/fetchStateLGA/"+stateId, requestBody);
    }

    async updatePassportDetails(passportTypeFormObject: any = {}, feeDetailsObject: any = {}) {
        const requestBody = Object.assign(passportTypeFormObject, feeDetailsObject);

        return await this.sendPostRequest("v1/application/updatePassportDetails", requestBody);
    }

    async savePersonalDetailsData(personalDetailsFormObject: any = {}, passportTypeFormObject: any = {}, feeDetailsObject: any = {}) {
        const requestBody = Object.assign(personalDetailsFormObject, passportTypeFormObject, feeDetailsObject);

        return await this.sendPostRequest("v1/application/bioData", requestBody);
    }

    async saveContactInformationData(contactInformationFormObject: any = {}) {
        const requestBody = contactInformationFormObject;

        return await this.sendPostRequest("v1/application/contactInfo", requestBody);
    }

    async saveKinInformationData(kinInformationFormObject: any = {}) {
        const requestBody = kinInformationFormObject;

        return await this.sendPostRequest("v1/application/kinInfo", requestBody);
    }

    async fetchApplicationData(applicantId: string){
        const requestBody = {};

        return await this.sendPostRequest("v1/application/applicantStageData/"+applicantId, requestBody);
    }

    async fetchApplicationViewData(applicantId: string){
        const requestBody = {};

        return await this.sendPostRequest("v1/application/printApplication/"+applicantId, requestBody);
    }

    async confirmApplicationSubmission(applicantId: string, isSaveNow: number){
        const requestBody = {applicantId: applicantId, isSaveNow: isSaveNow};

        return await this.sendPostRequest("v1/application/confirmApplication", requestBody);
    }

    async searchApplication(formData: any = {}){
        const requestBody = formData;

        return await this.sendPostRequest("v1/application/searchApplication", requestBody);
    }

    async fetchApplicationPaymentDetails(applicantId: string, paymentType: string){
        const requestBody = {};

        return await this.sendPostRequest("v1/payments/allPaymentGateways/"+applicantId+"/"+paymentType, requestBody);
    }

    async fetchApplicationPaymentDetailsV2(applicantId: string, paymentType: string){
        const requestBody = {};

        return await this.sendPostRequest("v1/payments/allPaymentGatewaysV2/"+applicantId+"/"+paymentType, requestBody);
    }

    async makeApplicationPayment(formData: any = {}){
        const requestBody = formData;

        return await this.sendPostRequest("v1/payments/generateAcknowledgement", requestBody);
    }

    async checkPaymentStatus(txnRef: string){
        const requestBody = {};

        return await this.sendPostRequest("v1/payments/checkPaymentStatus/"+txnRef, requestBody);
    }

    async fetchApplicationPaymentReceiptDetails(txnReferenceNumber: string){
        const requestBody = {};

        return await this.sendPostRequest("v1/payments/printAcknowledgement/"+txnReferenceNumber, requestBody);
    }

    async fetchGuarantorFormData(applicantId: string){
        const requestBody = {};

        return await this.sendPostRequest("v1/application/generateGuarantorForm/"+applicantId, requestBody);
    }

    async saveCODPersonalDetailsData(personalDetailsFormObject: any = {}) {
        const requestBody = personalDetailsFormObject;

        return await this.sendPostRequest("v1/cod/applicantCurrentData", requestBody);
    }

    async saveChangeDetailsData(isFirstFormRequest: number, dataCorrectionId: string, batchId: string, dataCorrectionItemsArray:any = []){
        const requestBody = {isFirstFormRequest: isFirstFormRequest, dataCorrectionId: dataCorrectionId, batchId: batchId, dataCorrectionItems: dataCorrectionItemsArray};

        return await this.sendPostRequest("v1/cod/applicantCorrectionData", requestBody);
    }

    async fetchCODConfirmationData(correctionId: string){
        const requestBody = {};

        return await this.sendPostRequest("v1/cod/fetchCorrectionData/"+correctionId, requestBody);
    }

    async confirmCODData(formData: any = {}){
        const requestBody = formData;

        return await this.sendPostRequest("v1/cod/confirmCorrectionData", requestBody);
    }

    async validatePasscode(passcode: string, requestType: string){
        const requestBody = {};

        return await this.sendPostRequest("v1/cod/validatePasscode/"+passcode+"/"+requestType, requestBody);
    }

    async fetchCODApplicationData(passcode: string){
        const requestBody = {};

        return await this.sendPostRequest("v1/cod/fetchCODApprovedData/"+passcode, requestBody);
    }

    async fetchCODRequestViewData(passcode: string){
        const requestBody = {};

        return await this.sendPostRequest("v1/cod/fetchCODRequestData/"+passcode, requestBody);
    }

    async fetchCODCurrentData(passcode: string){
        const requestBody = {};

        return await this.sendPostRequest("v1/cod/fetchCODCurrentData/"+passcode, requestBody);
    }

    async fetchServerToken(){
        const requestBody = {};

        return await this.sendPostRequest("v1/auth/serverToken", requestBody);
    }

    async validatePassportNumber(paspassportNumber: string){
        const requestBody = {};

        return await this.sendPostRequest("v1/application/validatePassport/"+paspassportNumber, requestBody);
    }

    async fetchApplicationEnvironment(){
        const requestBody = {};

        return await this.sendPostRequest("v1/application/validatePassport/", requestBody);
    }

}
