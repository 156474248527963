<app-header></app-header>

<div class="center_body">  
   <div class="card">
        <div style="border-radius:200px; height:100px; width:100px; background: #F8FAF5; margin:0 auto; border:1px solid #f0f0f0">
            <i class="fa fa-times" style="color: #dd1d1d; margin-left: 0;"></i>
        </div>
        
        <h1 style="color: #dd1d1d">Payment Failed !</h1> 

        <p><b>{{failedReason}}</b></p>

        <p>NOTE: Payment for application number <b>{{applicationId}}</b> <span *ngIf="referenceNumber !== constant.NOT_FOUND"> with reference number <b>{{referenceNumber}}</b></span> failed. If your bank/card has been debited, please wait for 30 mins and re-check status of the payment.</p>

        <input type="button" (click)="retryPayment()" class="action-button-previous back_btn" value="Re-Try" />
        <input type="submit" (click)="openDashboard()" class="action-button custom_btn" value="Pay Later" />
    </div>
</div> 

<app-footer></app-footer>