import { Component, NgZone } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';

@Component({
  selector: 'app-codrequest-view',
  templateUrl: './codrequest-view.component.html',
  styleUrls: ['./codrequest-view.component.css']
})
export class CODRequestViewComponent {

  encryptedPasscode: string = "";

  personalDetailsObject:any = {};
  codFeeDetailsObject:any = {};
  changedFieldsArray:any = [];
  codPaymentDetailsObject: any = {};
  isPaymentDetailsExist:boolean=false;
  
  constructor(private router: Router, private ngZone: NgZone, private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, private toast: Toast,
              public constants: Constants, private loaderService: LoaderService, public utils: Utils) { 
    activatedRoute.params.subscribe((params: Params) => {
      this.encryptedPasscode = params['passcode'];
    });
    this.storeCODRequestViewData();
  }

  private storeCODRequestViewData(){
    this.loaderService.startLoader();
    this.httpUtils.fetchCODRequestViewData(this.utils.decryptString(this.encryptedPasscode)).then(serverResponse => {
      this.loaderService.stopLoader();
      const serverResponseCode = serverResponse.responseCode;
      if(serverResponseCode === this.constants.STATUS_SUCCESS){
        this.personalDetailsObject = serverResponse.codPreviewData;
        this.personalDetailsObject.paymentStatusImageURL = "assets/images/"+this.personalDetailsObject.paymentStatusImageName;
        this.personalDetailsObject.approvalStatusLabelClass = this.utils.getStatusClass(this.personalDetailsObject.approvalStatus);
        this.changedFieldsArray = serverResponse.itemsData;
        this.codFeeDetailsObject = serverResponse.codFeeDetails;
        this.codPaymentDetailsObject = serverResponse.codPaymentDetails;
        this.isPaymentDetailsExist = serverResponse.codPaymentDetails !== null;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  backToHomePage(){
    this.ngZone.run(()=>this.router.navigate(["main"]));
  }

  openPaymentPage(){
    this.ngZone.run(()=>this.router.navigate(["payment", this.utils.encryptString(this.personalDetailsObject.applicationId), this.utils.encryptString(this.constants.COD_PAYMENT)]));
  }

  printApplication(){
    window.print();
  }

}