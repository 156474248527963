import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { SelectService } from '../common/select-service';
import { LocalStorage } from '../common/local-storage';
import { Tags } from '../common/tags';
import { Utils } from '../common/utils';
import { LoaderService } from '../common/loader-service';
import { Toast } from '../common/toast';
import { NgForm } from '@angular/forms';
import { ProjectConfig } from '../common/project-config';
import { Captcha } from '../common/captcha';
import { SimpleModalService } from 'ngx-simple-modal';
import { ExceptionModalComponent } from '../exception-modal/exception-modal.component';
import { Title } from "@angular/platform-browser";
import { InformationModalComponent } from '../information-modal/information-modal.component';
import { GUI } from '../common/gui';

@Component({
  selector: 'app-application-add-edit',
  templateUrl: './application-add-edit.component.html',
  styleUrls: ['./application-add-edit.component.css']
})
export class ApplicationAddEditComponent {

  @ViewChild('passportTypeForm') passportTypeForm : NgForm;

  @ViewChild('personalDetailsForm') personalDetailsForm : NgForm;

  @ViewChild('contactInformationForm') contactInformationForm : NgForm;

  @ViewChild('kinInformationForm') kinInformationForm : NgForm;

  private masterEncryptedApplicantId : string = this.constants.BLANK;

  private masterEncryptedApplicationStage : string = this.constants.BLANK;

  private masterApplicationStage : string = this.constants.BLANK;

  private isMasterReferenceNumberExist : boolean = false;

  private masterEncryptedPasscode : string = this.constants.BLANK;

  private isMasterPasscodeExist : boolean = false;

  applicationStage : number = this.constants.PASSPORT_STEP;

  applicationHighestStage : number = this.constants.PASSPORT_STEP;

  private shallExitApplication : boolean;

  dateOfBirthMaxDate = new Date();

  dateOfBirthMinDate = new Date();

  passportTypeFormObject : any = {};

  personalDetailsFormObject : any = {};

  feeDetailsObject : any = {};

  contactInformationFormObject : any = {};

  kinInformationFormObject : any = {};

  officialPassportOfficeDetailsObject : any = {};

  passportSelectService = new SelectService();

  changeTypeSelectService = new SelectService();

  ageGroupSelectService = new SelectService();

  processingOfficeSelectService = new SelectService();

  validitySelectService = new SelectService();

  bookletSelectService = new SelectService();

  stateSelectService = new SelectService();

  contactInformationFormLGASelectService = new SelectService();

  currentAddressLGASelectService = new SelectService();

  kinInformationFormLGASelectService = new SelectService();

  serverValidityList : any = [];

  monthsList : any = [];

  ng2TelInputOptions = {initialCountry: 'ng', preferredCountries: [], separateDialCode: true, autoPlaceholder: "", allowDropdown: false, formatOnDisplay: false};

  ng2TelInputOptionsWithDropdown = {initialCountry: 'ng', preferredCountries: [], separateDialCode: true, autoPlaceholder: "", allowDropdown: true, formatOnDisplay: false};

  isEnhancedProcessingCenter : boolean = false;

  disableProcessingStateOffice : boolean = false;

  changeTypeLabel : string = "Change Type";

  isSkipCaptcha : boolean = false;

  isDisablePassportFormSubmitButton : boolean = false;

  isShowFeesSection : boolean = false;

  private defaultSelectOption = {value: this.constants.BLANK, text: this.constants.DEFAULT_SELECT_OPTION_LABEL};

  constructor(private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, private toast: Toast, public utils: Utils, public constants: Constants,
              private localStorage: LocalStorage, private tags: Tags, private loaderService: LoaderService, public projectConfig: ProjectConfig, public captcha: Captcha,
              private simpleModalService: SimpleModalService, private title: Title, public gui: GUI){
    activatedRoute.params.subscribe((params: Params) => {
      this.masterEncryptedApplicantId = params['applicantId'];
      this.masterEncryptedApplicationStage = params['applicationStage'];
      this.masterEncryptedPasscode = params['passcode'];
    });
    this.monthsList = this.utils.getMonthsArray();
    captcha.handleCaptchaLoad(this.personalDetailsFormObject);
    this.dateOfBirthMaxDate.setDate(this.dateOfBirthMaxDate.getDate() - 2);
    this.dateOfBirthMinDate.setFullYear(this.dateOfBirthMinDate.getFullYear() - 99);
    this.storeStateList();
    utils.storeAgeGroups(this.ageGroupSelectService);

    if(this.utils.isEmpty(this.masterEncryptedApplicantId) === false){
      this.setupAppMasterData();
      return;
    }

    if(this.utils.isEmpty(this.masterEncryptedPasscode) === false) {
      this.setupCODMasterData();
      return;
    }

    this.setupAppDefaultData();
  }

  private setupAppMasterData(){
    this.title.setTitle("Resume/Edit Passport Application | Nigerian Immigration Services");
    this.isMasterReferenceNumberExist = true;
    this.isSkipCaptcha = true;
    this.masterApplicationStage = this.utils.decryptString(this.masterEncryptedApplicationStage);
    this.storeApplicationData(this.utils.decryptString(this.masterEncryptedApplicantId), Number(this.masterApplicationStage));
  }

  private setupCODMasterData(){
    this.title.setTitle("Resume/Edit Passport Application | Nigerian Immigration Services");
    this.isMasterPasscodeExist = true;
    this.passportTypeFormObject.applyingFor = this.constants.REISSUE;
    this.passportTypeFormObject.applicationType = this.constants.COD_APPLICATION;
    this.changeTypeLabel = "Current Passport Status";
    this.storeCODApplicationData();
    this.setPassportTypeFormDefaultValues(true);
    this.setPersonalDetailsFormDefaultValues(true);
    this.setContactInformationFormDefaultValues();
    this.setKinInformationFormDefaultValues();
    this.isSkipCaptcha = true;
  }

  private setupAppDefaultData(){
    this.title.setTitle("Apply for Passport | Nigerian Immigration Services");
    this.passportTypeFormObject.applicationType = this.constants.PASSPORT_APPLICATION;
    this.setPassportTypeFormDefaultValues(false);
    this.setPersonalDetailsFormDefaultValues(false);
    this.setContactInformationFormDefaultValues();
    this.setKinInformationFormDefaultValues();
  }

  private fetchCurrentForm(){
    if(this.passportTypeForm !== undefined){
      return this.passportTypeForm;
    }

    if(this.personalDetailsForm !== undefined){
      return this.personalDetailsForm;
    }

    if(this.contactInformationForm !== undefined){
      return this.contactInformationForm;
    }

    return this.kinInformationForm;
  }

    openCentreAvailabilityForm(){
      window.open(this.projectConfig.CENTRE_AVAILABILITY_URL,'_blank');
    }

  openFieldSet(stage: number, event: any){
    const currentForm = this.fetchCurrentForm();
    currentForm.onSubmit(event);
    if(currentForm.valid === true){
      this.applicationStage = stage;
    }
  }

  openPreviousFieldSet(){
    this.applicationStage--;
  }

  setShallExitApplication(shallExitApplication: boolean){
    this.shallExitApplication = shallExitApplication;
  }

  openDashboard(){
    this.gui.openHomePage();
  }

  private setDateOfBirth(){
    const dateOfBirthArray = this.personalDetailsFormObject.dateOfBirth.split("/");
    this.personalDetailsFormObject.dateOfBirthDay = dateOfBirthArray[0];
    this.personalDetailsFormObject.dateOfBirthMonth = dateOfBirthArray[1];
    this.personalDetailsFormObject.dateOfBirthYear = dateOfBirthArray[2];
  }

  storePassportType(isChangedFromGUI: boolean){
    const applyingFor = this.passportTypeFormObject.applyingFor;

    if(this.utils.isEmpty(applyingFor) === true) {
      return;
    }

    if(applyingFor === this.constants.FRESH){
      const passportTypeList = [{value: this.constants.STANDARD_PASSPORT, text: this.utils.fetchPassportTypeLabel(this.constants.STANDARD_PASSPORT)}, {value: this.constants.OFFICIAL_PASSPORT, text: this.utils.fetchPassportTypeLabel(this.constants.OFFICIAL_PASSPORT)}];
      this.passportSelectService.setSelectOptions(passportTypeList);
      if(isChangedFromGUI === true){
        this.passportTypeFormObject.passportType = this.constants.STANDARD_PASSPORT;
        this.disableProcessingStateOffice = false;
        const fieldsArray = ["changeType", "ageGroup", "processingState", "processingOffice", "bookletType", "passportValidity"];
        const selectServiceArray = [this.processingOfficeSelectService, this.bookletSelectService, this.validitySelectService];
        this.setFieldsToBlank(this.passportTypeFormObject, fieldsArray, selectServiceArray, true);
      }
      return;
    }

    //REISSUE in case of COD
    const passportTypeKey = applyingFor === this.constants.RENEW ? this.constants.STANDARD_PASSPORT_RENEWAL_REISSUE : this.constants.STANDARD_PASSPORT_COD;
    const passportTypeList = [{value: passportTypeKey, text: this.utils.fetchPassportTypeLabel(passportTypeKey)}];
    this.passportSelectService.setSelectOptions(passportTypeList);

    this.utils.storeChangeTypes(this.changeTypeSelectService, applyingFor);
    if(isChangedFromGUI === true){
      this.passportTypeFormObject.passportType = passportTypeKey;
      const fieldsArray = ["changeType", "ageGroup", "processingState", "processingOffice", "bookletType", "passportValidity"];
      const selectServiceArray = [this.processingOfficeSelectService, this.bookletSelectService, this.validitySelectService];
      this.setFieldsToBlank(this.passportTypeFormObject, fieldsArray, selectServiceArray, true);
    }
  }

  setGender(){
    if(this.passportTypeFormObject.applicationType === this.constants.COD_APPLICATION){
      return;
    }

    if(this.personalDetailsFormObject.title === this.constants.BLANK){
      this.personalDetailsFormObject.gender = this.constants.BLANK;
      return;
    }

    if(this.personalDetailsFormObject.title === this.constants.MR){
      this.personalDetailsFormObject.gender = this.constants.MALE;
      return;
    }

    this.personalDetailsFormObject.gender = this.constants.FEMALE;
  }

  setFieldsToBlank(formObject: {}, fieldsArray : any[], selectServiceArray : SelectService[], isFeeObjectEffected: boolean){
    for(let i=0; i<fieldsArray.length; i++){
      formObject[fieldsArray[i]] = this.constants.BLANK;
    }

    if(selectServiceArray !== null){
      for(const selectService of selectServiceArray){
        selectService.setSelectOptions([this.defaultSelectOption]);
      }
    }

    if(isFeeObjectEffected === true){
      this.isShowFeesSection = false;
      this.feeDetailsObject = {};
    }
  }

  private setPassportTypeFormDefaultValues(isCODApplication: boolean){
    const fieldsArray = ["ageGroup", "processingState", "processingOffice", "bookletType", "passportValidity"];
    const selectServiceArray = [this.processingOfficeSelectService, this.bookletSelectService, this.validitySelectService];

    if(isCODApplication === false){
      fieldsArray.push("applyingFor", "passportType");
      selectServiceArray.push(this.passportSelectService);
    }

    this.setFieldsToBlank(this.passportTypeFormObject, fieldsArray, selectServiceArray, false);
    this.storePassportType(true);
  }

  private setPersonalDetailsFormDefaultValues(isCODApplication: boolean){
    const fieldsArray = ["maritalStatus"];
    if(isCODApplication === false){
      fieldsArray.push("title", "dateOfBirthMonth", "placeOfBirth", "gender");
    }
    const selectServiceArray = null;
    this.setFieldsToBlank(this.personalDetailsFormObject, fieldsArray, selectServiceArray, false);
  }

  private setContactInformationFormDefaultValues(){
    const fieldsArray = ["contactState", "addressState", "addressLGA", "currentAddressState", "currentAddressLGA"];
    const selectServiceArray = [this.contactInformationFormLGASelectService, this.currentAddressLGASelectService];
    this.setFieldsToBlank(this.contactInformationFormObject, fieldsArray, selectServiceArray, false);
    this.contactInformationFormObject.isCurrentAddressSame = false;
  }

  private setKinInformationFormDefaultValues(){
    const fieldsArray = ["kinRelation", "addressState", "addressLGA"];
    const selectServiceArray = [this.kinInformationFormLGASelectService];
    this.setFieldsToBlank(this.kinInformationFormObject, fieldsArray, selectServiceArray, false);
  }

  isValidDetails(formObject: any = {}, checkedFieldsArray: any = []){
    let fieldBlankCount = 0;

    for(let i=0; i<checkedFieldsArray.length; i++){
      if(this.utils.isEmpty(formObject[checkedFieldsArray[i]]) === true){
        fieldBlankCount++;
      }
    }

    return fieldBlankCount > 0 ? false : true;
  }

  private storeApplicationData(applicantId: string, masterApplicationStage: number){
    this.loaderService.startLoader();
    this.httpUtils.fetchApplicationData(applicantId).then(serverResponse => {
      this.loaderService.stopLoader();
      this.applicationStage = masterApplicationStage;
      this.applicationHighestStage = masterApplicationStage;
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.setupPassportTypeFormObject(serverResponse);

        this.setupFeeDetailsObject(serverResponse);

        this.setupPersonalDetailsFormObject(serverResponse);

        this.setupContactInformationFormObject(serverResponse);

        this.setupKinInformationFormObject(serverResponse);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private setupPassportTypeFormObject(serverResponse: any) {
    if(serverResponse.passportDetails !== null){
      this.passportTypeFormObject = serverResponse.passportDetails;
      this.storePassportType(false);
      this.storeOfficialPassportOfficeDetails(false);
      this.storeStateProcessingOfficeList(false);
      this.storeBookletTypeList(false);
      this.storeValidityList(false);
    }
  }

  private setupFeeDetailsObject(serverResponse: any) {
    if(serverResponse.feeDetails !== null){
      this.feeDetailsObject = serverResponse.feeDetails;
    }
  }

  private setupPersonalDetailsFormObject(serverResponse: any){
    if(serverResponse.personalInfo !== null){
      this.personalDetailsFormObject = serverResponse.personalInfo;
      this.setDateOfBirth();
      this.setDefaultProcessingStateOffice(false);
    } else {
      this.setPersonalDetailsFormDefaultValues(false);
    }
  }

  private setupContactInformationFormObject(serverResponse: any){
    if(serverResponse.contactInfo !== null){
      this.contactInformationFormObject = serverResponse.contactInfo;
      this.fetchStateLGA(this.contactInformationFormObject, 'addressState', this.contactInformationFormLGASelectService, 'addressLGA', false);
      this.fetchStateLGA(this.contactInformationFormObject, 'currentAddressState', this.currentAddressLGASelectService, 'currentAddressLGA', false);
      if(this.utils.isEmpty(this.contactInformationFormObject.phoneNumberCountryISOCode) === false){
        this.ng2TelInputOptionsWithDropdown.initialCountry = this.contactInformationFormObject.phoneNumberCountryISOCode;
      }
    } else {
      this.setContactInformationFormDefaultValues();
    }
  }

  private setupKinInformationFormObject(serverResponse: any){
    if(serverResponse.kinInformation !== null){
      this.kinInformationFormObject = serverResponse.kinInformation;
      this.fetchStateLGA(this.kinInformationFormObject, 'addressState', this.kinInformationFormLGASelectService, 'addressLGA', false);
    } else {
      this.setKinInformationFormDefaultValues();
    }
  }

  private storeCODApplicationData(){
    this.httpUtils.fetchCODApplicationData(this.utils.decryptString(this.masterEncryptedPasscode)).then(serverResponse => {
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        if(serverResponse.isApplicationInitiated === true){
          this.isMasterReferenceNumberExist = true;
          let applicationStage = serverResponse.applicationStage;
          applicationStage = applicationStage < 4 ? ++applicationStage : applicationStage;
          this.storeApplicationData(serverResponse.applicantId, applicationStage);
          return;
        }
        this.setupCODApplicationData(serverResponse);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private setupCODApplicationData(serverResponse: any) {
    this.passportTypeFormObject.passportNumber = serverResponse.passportNumber;
    this.passportTypeFormObject.ageGroup = serverResponse.ageGroup;
    this.personalDetailsFormObject.title = serverResponse.title;
    this.personalDetailsFormObject.lastName = serverResponse.lastName;
    this.personalDetailsFormObject.firstName = serverResponse.firstName;
    this.personalDetailsFormObject.middleName = serverResponse.middleName;
    this.personalDetailsFormObject.dateOfBirth = serverResponse.dateOfBirth;
    this.personalDetailsFormObject.placeOfBirth = serverResponse.placeOfBirth;
    this.personalDetailsFormObject.gender = serverResponse.gender;
    this.passportTypeFormObject.subType = serverResponse.subType;
    this.personalDetailsFormObject.applicationId = serverResponse.applicationId;
    this.personalDetailsFormObject.isNameChange = serverResponse.isNameChange;
    this.setDateOfBirth();
    this.setDefaultProcessingStateOffice(false);
  }

  private storeStateList(){
    this.loaderService.startLoader();
    this.httpUtils.fetchCountryStateList().then(serverResponse => {
      this.loaderService.stopLoader();
      const serverResponseCode = serverResponse.responseCode;
      if(serverResponseCode === this.constants.STATUS_SUCCESS){
        const serverCountryStateList = [this.defaultSelectOption];
        for(const valueJSONObject of serverResponse.states){
          serverCountryStateList.push({value: valueJSONObject.id, text: valueJSONObject.stateName});
        }
        this.stateSelectService.setSelectOptions(serverCountryStateList);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  storeOfficialPassportOfficeDetails(isChangedFromGUI: boolean){
    if(this.passportTypeFormObject.passportType === this.constants.OFFICIAL_PASSPORT){
      this.httpUtils.fetchOfficialPassportOfficeDetails().then(serverResponse => {
        const serverResponseCode = serverResponse.responseCode;
        if(serverResponseCode === this.constants.STATUS_SUCCESS){
          this.officialPassportOfficeDetailsObject = serverResponse;
          this.passportTypeFormObject.processingState = serverResponse.processingState;
          this.passportTypeFormObject.processingOffice = serverResponse.processingOffice;
          this.storeBookletTypeList(isChangedFromGUI);
        } else {
          this.toast.showErrorToast(serverResponse.responseMsg);
        }
      });
    } else if(isChangedFromGUI === true){
      this.passportTypeFormObject.processingState = this.constants.BLANK;
      this.passportTypeFormObject.processingOffice = this.constants.BLANK;
    }
  }

  storeStateProcessingOfficeList(isChangedFromGUI: boolean){
    this.loaderService.startLoader();

    if(this.isMasterReferenceNumberExist === false){
      this.passportTypeFormObject.processingOffice = this.constants.BLANK;
    }

    if(isChangedFromGUI === true){
      this.processingOfficeSelectService.setSelectOptions([this.defaultSelectOption]);
      this.passportTypeFormObject.processingOffice = this.constants.BLANK;
      this.passportTypeFormObject.bookletType = this.constants.BLANK;
      this.passportTypeFormObject.passportValidity = this.constants.BLANK;
    }

    if(this.isValidDetails(this.passportTypeFormObject, ["processingState"]) === false){
      this.processingOfficeSelectService.setSelectOptions([this.defaultSelectOption]);
      this.loaderService.stopLoader();
      return;
    }

    this.httpUtils.fetchStateProcessingOfficeList(this.passportTypeFormObject.processingState).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        const serverStateProcessingOfficeList = [this.defaultSelectOption];
        for(const valueJSONObject of serverResponse.passportOffices){
          serverStateProcessingOfficeList.push({value: valueJSONObject.id, text: valueJSONObject.name});
        }
        this.processingOfficeSelectService.setSelectOptions(serverStateProcessingOfficeList);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  storeBookletTypeList(isChangedFromGUI: boolean){
    if(this.isMasterReferenceNumberExist === false){
      this.passportTypeFormObject.passportValidity = this.constants.BLANK;
      this.passportTypeFormObject.bookletType = this.constants.BLANK;
    }
    if(this.isValidDetails(this.passportTypeFormObject, ["processingOffice"]) === false){
      this.validitySelectService.setSelectOptions([this.defaultSelectOption]);
      this.bookletSelectService.setSelectOptions([this.defaultSelectOption]);
      return;
    }
    this.loaderService.startLoader();
    this.httpUtils.fetchBookletTypeList(this.passportTypeFormObject.processingOffice).then(serverResponse => {
      this.loaderService.stopLoader();
      const serverResponseCode = serverResponse.responseCode;
      if(serverResponseCode === this.constants.STATUS_SUCCESS){
        let serverBookletTypeList = [this.defaultSelectOption];
        for(const valueJSONObject of serverResponse.bookletList){
          serverBookletTypeList.push({value: valueJSONObject.key, text: valueJSONObject.value});
        }
        this.bookletSelectService.setSelectOptions(serverBookletTypeList);
        if(isChangedFromGUI === true){
          this.passportTypeFormObject.bookletType = this.constants.BLANK;
          this.passportTypeFormObject.passportValidity = this.constants.BLANK;
        }
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  storeValidityList(isChangedFromGUI: boolean){
    if(this.isMasterReferenceNumberExist === false){
      this.passportTypeFormObject.passportValidity = this.constants.BLANK;
    }
    if(this.isValidDetails(this.passportTypeFormObject, ["processingOffice", "bookletType"]) === false){
      this.validitySelectService.setSelectOptions([this.defaultSelectOption]);
      return;
    }
    this.loaderService.startLoader();
    this.httpUtils.fetchValidityList(this.passportTypeFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      const serverResponseCode = serverResponse.responseCode;
      if(serverResponseCode === this.constants.STATUS_SUCCESS){
        const serverValidityList = [this.defaultSelectOption];
        for(const valueJSONObject of serverResponse.validityList){
          serverValidityList.push({value: valueJSONObject.key, text: valueJSONObject.value});
        }
        this.validitySelectService.setSelectOptions(serverValidityList);
        if(isChangedFromGUI === true){
          this.passportTypeFormObject.passportValidity = this.constants.BLANK;
        }
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  fetchPassportFeeDetails(){
    if(this.isValidDetails(this.passportTypeFormObject, ["passportType", "processingOffice", "ageGroup", "bookletType", "passportValidity"]) === false){
      return;
    }
    this.loaderService.startLoader();
    this.isDisablePassportFormSubmitButton = true;
    this.httpUtils.fetchPassportFeeDetails(this.passportTypeFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      const serverResponseCode = serverResponse.responseCode;
      if(serverResponseCode === this.constants.STATUS_SUCCESS){
        this.feeDetailsObject = serverResponse;
        this.isDisablePassportFormSubmitButton = this.feeDetailsObject.bookletFee <= this.constants.FALSE;
        this.isEnhancedProcessingCenter = this.feeDetailsObject.isEnhancedCenter === this.constants.TRUE;
      } else {
        this.feeDetailsObject = {};
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  fetchStateLGA(formObject: {}, fieldName: string, selectService: SelectService, childFieldName: string, isChangedFromGUI: boolean){
    const stateId = formObject[fieldName];
    if(this.utils.isEmpty(stateId) === true){
      selectService.setSelectOptions([this.defaultSelectOption]);
      formObject[childFieldName] = this.constants.BLANK;
      return;
    }
    this.loaderService.startLoader();
    this.httpUtils.fetchStateLGAList(stateId).then(serverResponse => {
      this.loaderService.stopLoader();
      const serverResponseCode = serverResponse.responseCode;
      if(serverResponseCode === this.constants.STATUS_SUCCESS){
        let serverStateLGAList = [this.defaultSelectOption];
        for(const valueJSONObject of serverResponse.lga){
          serverStateLGAList.push({value: valueJSONObject.id, text: valueJSONObject.description});
        }
        selectService.setSelectOptions(serverStateLGAList);
        if(isChangedFromGUI === true){
          formObject[childFieldName] = this.constants.BLANK;
        }
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  openPersonalDetailsForm(event: any){
    if(this.isSkipCaptcha === false && this.passportTypeFormObject.captchaVerified === false){
      return;
    }
    if(event.target.name === undefined || event.target.name === this.constants.BLANK){
      return;
    }
    window.scrollTo(0,0);
    if(this.utils.isEmpty(this.personalDetailsFormObject.referenceNumber) === true){
      this.applicationStage = this.constants.PERSONAL_INFO_STEP;
      this.applicationHighestStage = this.constants.PERSONAL_INFO_STEP;
      return;
    }
    this.loaderService.startLoader();
    if(this.passportTypeFormObject.applyingFor === this.constants.FRESH){
      this.passportTypeFormObject.changeType = this.constants.BLANK;
      this.passportTypeFormObject.passportNumber = this.constants.BLANK;
    }
    this.passportTypeFormObject.referenceNumber = this.personalDetailsFormObject.referenceNumber;
    this.httpUtils.updatePassportDetails(this.passportTypeFormObject, this.feeDetailsObject).then(serverResponse => {
      this.loaderService.stopLoader();
      const serverResponseCode = serverResponse.responseCode;
      if(serverResponseCode === this.constants.STATUS_SUCCESS){
        this.applicationStage = this.constants.PERSONAL_INFO_STEP;
        this.applicationHighestStage = this.constants.PERSONAL_INFO_STEP;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private openInformationModal() {
    const message = "REFERENCE NUMBER <b>"+this.personalDetailsFormObject.referenceNumber+"</b> (IMPORTANT - PLEASE WRITE DOWN THE REFERENCE NUMBER. YOU MAY REQUIRE IT LATER)";
    this.simpleModalService.addModal(InformationModalComponent, {message: message}, {autoFocus: true}).subscribe((isConfirmed: boolean)=> {
      if(isConfirmed === true){
        this.openDashboard();
      }
    });
  }

  openContactInformationForm(event: any){
    if(event.target.name === undefined || event.target.name === this.constants.BLANK){
      return;
    }
    window.scrollTo(0,0);
    if(this.isValidDetails(this.passportTypeFormObject, ["passportType", "ageGroup", "processingState", "processingOffice", "bookletType", "passportValidity"]) === false){
      this.applicationStage = this.constants.PASSPORT_STEP;
      return;
    }
    this.loaderService.startLoader();
    this.personalDetailsFormObject.dateOfBirth = this.personalDetailsFormObject.dateOfBirthDay + "/" + this.personalDetailsFormObject.dateOfBirthMonth + "/" + this.personalDetailsFormObject.dateOfBirthYear;
    this.httpUtils.savePersonalDetailsData(this.personalDetailsFormObject, this.passportTypeFormObject, this.feeDetailsObject).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.personalDetailsFormObject.applicantId = serverResponse.applicantId;
        this.personalDetailsFormObject.referenceNumber = serverResponse.referenceNumber;
        this.personalDetailsFormObject.fullName = serverResponse.fullName;
        this.isSkipCaptcha = true;
        if(this.shallExitApplication === true){
          this.openInformationModal();
          return;
        }
        this.applicationStage = this.constants.CONTACT_INFO_STEP;
        this.applicationHighestStage = this.constants.CONTACT_INFO_STEP;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  setCurrentAsPermanentAddress(isFinalSave: boolean){
    if(isFinalSave == false && this.contactInformationFormObject.isCurrentAddressSame === false) {
      this.contactInformationFormObject.currentAddressLine1 = this.constants.BLANK;
      this.contactInformationFormObject.currentAddressCity = this.constants.BLANK;
      this.contactInformationFormObject.currentAddressState = this.constants.BLANK;
      this.currentAddressLGASelectService.setSelectOptions([this.defaultSelectOption]);
      this.contactInformationFormObject.currentAddressLGA = this.constants.BLANK;
      this.contactInformationFormObject.currentAddressPostalCode = this.constants.BLANK;
      return;
    }

    if(this.contactInformationFormObject.isCurrentAddressSame === true){
      this.contactInformationFormObject.currentAddressLine1 = this.contactInformationFormObject.addressLine1;
      this.contactInformationFormObject.currentAddressCity = this.contactInformationFormObject.addressCity;
      this.contactInformationFormObject.currentAddressState = this.contactInformationFormObject.addressState;
      this.fetchStateLGA(this.contactInformationFormObject, 'currentAddressState', this.currentAddressLGASelectService, 'currentAddressLGA', false);
      this.contactInformationFormObject.currentAddressLGA = this.contactInformationFormObject.addressLGA;
      this.contactInformationFormObject.currentAddressPostalCode = this.contactInformationFormObject.addressPostalCode;
    }
  }

  openKinInformationForm(event: any){
    if(event.target.name === undefined || event.target.name === this.constants.BLANK){
      return;
    }
    window.scrollTo(0,0);
    this.loaderService.startLoader();
    this.contactInformationFormObject.applicantId = this.personalDetailsFormObject.applicantId;
    this.contactInformationFormObject.referenceNumber = this.personalDetailsFormObject.referenceNumber;
    this.contactInformationFormObject.fullName = this.personalDetailsFormObject.fullName;
    this.setCurrentAsPermanentAddress(true);
    this.httpUtils.saveContactInformationData(this.contactInformationFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      const serverResponseCode = serverResponse.responseCode;
      if(serverResponseCode === this.constants.STATUS_SUCCESS){
        this.contactInformationFormObject.contactDetailsId = serverResponse.contactDetailsId;
        this.contactInformationFormObject.applicantId = serverResponse.applicantId;
        this.contactInformationFormObject.referenceNumber = serverResponse.referenceNumber;
        this.contactInformationFormObject.fullName = serverResponse.fullName;
        if(this.shallExitApplication === true){
          this.openDashboard();
          return;
        }
        this.applicationStage = this.constants.NEXT_OF_KIN_INFO_STEP;
        this.applicationHighestStage = this.constants.NEXT_OF_KIN_INFO_STEP;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  openApplicationPreview(event: any){
    if(event.target.name === undefined || event.target.name === this.constants.BLANK){
      return;
    }
    this.loaderService.startLoader();
    this.kinInformationFormObject.applicantId = this.personalDetailsFormObject.applicantId;
    this.kinInformationFormObject.referenceNumber = this.personalDetailsFormObject.referenceNumber;
    this.kinInformationFormObject.fullName = this.personalDetailsFormObject.fullName;
    this.httpUtils.saveKinInformationData(this.kinInformationFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      const serverResponseCode = serverResponse.responseCode;
      if(serverResponseCode === this.constants.STATUS_SUCCESS){
        this.kinInformationFormObject.contactDetailsId = serverResponse.contactDetailsId;
        this.kinInformationFormObject.applicantId = serverResponse.applicantId;
        this.kinInformationFormObject.referenceNumber = serverResponse.referenceNumber;
        this.kinInformationFormObject.fullName = serverResponse.fullName;
        if(this.shallExitApplication === true){
          this.openDashboard();
          return;
        }
        this.gui.openApplicationView(serverResponse.applicantId, this.constants.NEXT_OF_KIN_INFO_STEP);
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  setDefaultProcessingStateOffice(isChangedFromGUI: boolean){
    if(isChangedFromGUI === true && this.passportTypeFormObject.applicationType === this.constants.COD_APPLICATION && this.personalDetailsFormObject.isNameChange === this.constants.FALSE){
      return;
    }

    //THIS IS THE CASE WHEN EDIT THE APPLICATION THEN DEFAULT DATA IS ALREADY SET
    if(isChangedFromGUI === false && (this.passportTypeFormObject.applicationType === this.constants.PASSPORT_APPLICATION || this.passportTypeFormObject.applicationType === this.constants.COD_APPLICATION) && this.passportTypeFormObject.changeType === this.constants.DAMAGED_PASSPORT){
      this.disableProcessingStateOffice = true;
      return;
    }

    let isSetDefaultProcessingStateOffice = false;

    if(isChangedFromGUI === false && this.passportTypeFormObject.applicationType === this.constants.COD_APPLICATION && this.personalDetailsFormObject.isNameChange === this.constants.FALSE){
      isSetDefaultProcessingStateOffice = true;
    } else if (isChangedFromGUI === true && this.passportTypeFormObject.applicationType === this.constants.COD_APPLICATION && this.passportTypeFormObject.changeType === this.constants.DAMAGED_PASSPORT) {
      isSetDefaultProcessingStateOffice = true;
    } else if(this.passportTypeFormObject.applicationType === this.constants.PASSPORT_APPLICATION && this.passportTypeFormObject.changeType === this.constants.DAMAGED_PASSPORT){
      isSetDefaultProcessingStateOffice = true;
    }

    if(isSetDefaultProcessingStateOffice === true){
      this.disableProcessingStateOffice = true;
      if(isChangedFromGUI === true || this.isMasterPasscodeExist === true){
        this.passportTypeFormObject.processingState = this.constants.FCT_STATE_ID;
        this.storeStateProcessingOfficeList(false);
        this.passportTypeFormObject.processingOffice = this.constants.FCT_HQ_OFFICE_ID;
        this.storeBookletTypeList(true);
        // this.storeValidityList(true);
      }
    } else {
      this.disableProcessingStateOffice = false;
      if(isChangedFromGUI === true){
        this.setFieldsToBlank(this.passportTypeFormObject, ["processingState", "processingOffice", "bookletType", "passportValidity"], null, true);
      }
    }
  }

  validatePassportNumber(){
    if(this.utils.isEmpty(this.passportTypeFormObject.passportNumber) === true){
      return;
    }
    this.loaderService.startLoader();
    this.httpUtils.validatePassportNumber(this.passportTypeFormObject.passportNumber).then(serverResponse => {
      this.loaderService.stopLoader();
      const serverResponseCode = serverResponse.responseCode;
      if(serverResponseCode === this.constants.DATA_CORRECTION_APPLICATION_EXISTS){
        this.simpleModalService.addModal(ExceptionModalComponent, {applicationDate: serverResponse.applicationDate}, {autoFocus: true}).subscribe((isConfirmed: boolean)=> {
          if(isConfirmed === true){
            this.gui.openCODApprovedPage();
          }
        });
      }
    });
  }

}
