import { Component, OnInit } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';

export interface RequestInterface {
  applicationDate: string;
}
@Component({
  selector: 'app-exception-modal',
  templateUrl: './exception-modal.component.html',
  styleUrls: ['./exception-modal.component.css']
})
export class ExceptionModalComponent extends SimpleModalComponent<RequestInterface, boolean> implements RequestInterface {
  applicationDate: string;
  
  constructor() { 
    super();
  }

  confirm() {
    this.result = true;
    this.close();
  }

}