<app-header></app-header>

<div class="banner-img-name"></div>

<div class="main">


    <div class="mid-section">
        <h4>Passport Application Services</h4>
        <p>All Citizens of Nigeria either by Birth, Descent, Adoption, Registration or Naturalization,
          are eligible for a Nigerian Passport, provided that they satisfy all conditions prescribed by law.
             <!-- Apply here if your processing country is Nigeria. <b>If your application processing country is outside Nigeria, then you should
            <span><a [href]="projectConfig.USD_PASSPORT_APPLICATION_URL" target="_blank" style="color:#F96922;font-weight:bold;">click here.</a></span></b> -->
        </p>
    </div>

    <div class="rows_box">
        <div class="grid_three">

            <!-- <div class="col_box">
              <div (click)="openCentreAvailabilityForm()" class="card_box_pass h-1">
                <div class="top_pass">
                  <img src="assets/images/icons-available.png">
                  <h3>Check Centre Availability<span>Click here to check the earliest available appointment date for each processing centre.</span></h3>
                </div>
              </div>
            </div> -->

            <div class="col_box">
                <div (click)="openApplicationForm()" class="card_box_pass h-1">
                    <div class="top_pass">
                        <img src="assets/images/passport.svg">
                        <h3>Start New Application<span>Click here to apply for a new passport or a re-issue of your passport.</span></h3>
                    </div>
                </div>
            </div>

            <div class="col_box">
                <div (click)="openSearchTypeForm(constants.RESUME_APPLICATION)" class="card_box_pass h-1">
                    <div class="top_pass">
                        <img src="assets/images/editt.png">
                        <h3>Resume/Edit your Application<span>Click here to resume your pending application.</span></h3>
                    </div>
                </div>
            </div>

            <div class="col_box">
                <div (click)="openCODHomePage()" class="card_box_pass h-1">
                    <div class="top_pass">
                        <img src="assets/images/cod.svg">
                        <h3>Correction of Data<span>Apply for the change of data on your existing passport.</span></h3>
                    </div>
                </div>
            </div>

            <!-- <div class="col_box">
                <div (click)="openSearchTypeForm(constants.GUARANTOR_FORM)" class="card_box_pass h-1 ">
                    <div class="top_pass">
                        <img src="assets/images/gurantor.svg">
                        <h3>Guarantor's Form<span>Guarantor's form Application.</span></h3>
                    </div>
                </div>
            </div> -->

            <!-- <div class="col_box">
                <div (click)="openSearchTypeForm(constants.PAYMENT_STATUS)" class="card_box_pass h-1">
                    <div class="top_pass">
                        <img src="assets/images/receipt.svg">
                        <h3>Payment Status/Print Receipt<span>Click here to check your application status or print your receipt.</span></h3>
                    </div>
                </div>
            </div> -->

            <!-- <div class="col_box">
                <div (click)="openBookAppointmentForm()" class="card_box_pass h-1">
                    <div class="top_pass">
                        <img src="assets/images/icons3.png">
                        <h3>Book Appointment<span>Click here to book appointment for processing your passport application.</span></h3>
                    </div>
                </div>
            </div> -->

        </div>
    </div>
</div>

<app-footer></app-footer>
