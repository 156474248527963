import { AfterViewInit, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[firstFocus]'
})
export class FirstFocusDirective implements AfterViewInit {

  constructor(private elementRef: ElementRef) { }
    
  ngAfterViewInit() {
    this.elementRef.nativeElement.focus();
  }
    
}