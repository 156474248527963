import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Constants } from '../common/constants';
import { GUI } from '../common/gui';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { LocalStorage } from '../common/local-storage';
import { ProjectConfig } from '../common/project-config';
import { Tags } from '../common/tags';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {

  private encryptedApplicantId : string = this.constants.BLANK;

  encryptedPaymentType : string = this.constants.BLANK;

  paymentFormObject : any = {};

  applicationPaymentDetailsObject : any = {};

  iopPaymentMethodsArray : any = [];

  sanaPayPaymentMethodsArray : any = [];

  paystackPaymentMethodsArray : any = [];

  teasyPaymentMethodsArray : any = [];

  unifiedPaymentMethodsArray : any = [];

  gatewayDetailsList : any = [];

  isPayButtonDisabled : boolean = false;

  isEnableIOPGateway : boolean = false;

  isEnableSanaPayGateway : boolean = false;

  isEnablePaystackGateway : boolean = false;

  isEnableTeasyGateway : boolean = false;

  isEnableUnifiedGateway : boolean = false;

  referenceLabel : string = "Reference Number";

  private isSubmitForm : number = this.constants.FALSE;

  private paymentPostForm : HTMLFormElement;

  isV2Enable : boolean = true;

  constructor(private router: Router, private ngZone: NgZone, private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, public constants: Constants,
              private loaderService: LoaderService, private toast: Toast, public utils: Utils, public projectConfig: ProjectConfig, private localStorage: LocalStorage,
              private tags: Tags, private gui: GUI) { 
    activatedRoute.params.subscribe((params: Params) => {
      this.encryptedApplicantId = params['applicantId'];
      this.encryptedPaymentType = params['paymentType'];
    });
    
    if(this.isV2Enable == true){
      this.storeApplicationPaymentDetailsV2();
    } else {
      this.storeApplicationPaymentDetails();
    }
  }

  ngOnInit(): void {
    this.paymentPostForm = document.forms['paymentForm'];
  }

  private openApplicationAddEditPage(ngZone: NgZone, router: Router, encryptedApplicantId: string, encryptedApplicationStage: string){
    ngZone.run(()=>router.navigate(["application", encryptedApplicantId, encryptedApplicationStage]));
  }

  private storeApplicationPaymentDetails(){
    this.loaderService.startLoader();
    this.httpUtils.fetchApplicationPaymentDetails(this.utils.decryptString(this.encryptedApplicantId), this.utils.decryptString(this.encryptedPaymentType)).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        const serverFeeChangedMsg = serverResponse.feeChangedMsg;
        if(this.utils.isEmpty(serverFeeChangedMsg) === false){
          this.isPayButtonDisabled = true;
          this.toast.showErrorToastWithCallbackFunction(serverFeeChangedMsg, this.openApplicationAddEditPage.bind(this, this.ngZone, this.router, this.encryptedApplicantId, this.utils.encryptString(this.constants.PASSPORT_STEP.toString())));
          return;
        }
        
        this.applicationPaymentDetailsObject = serverResponse;
        this.applicationPaymentDetailsObject.transactionFee = 0;
        this.applicationPaymentDetailsObject.totalFeeIncludeServiceFee = this.applicationPaymentDetailsObject.totalFee;
        if(serverResponse.iopPaymentMethods !== null && serverResponse.iopPaymentMethods.length > 0){
          this.iopPaymentMethodsArray = serverResponse.iopPaymentMethods;
          this.isEnableIOPGateway = true;
        }
        if(serverResponse.saanapayPaymentMethods !== null && serverResponse.saanapayPaymentMethods.length > 0){
          this.sanaPayPaymentMethodsArray = serverResponse.saanapayPaymentMethods;
          this.isEnableSanaPayGateway = true;
        }
        if(serverResponse.paystackPaymentMethods !== null && serverResponse.paystackPaymentMethods.length > 0){
          this.paystackPaymentMethodsArray = serverResponse.paystackPaymentMethods;
          this.isEnablePaystackGateway = true;
        }

        if(serverResponse.teasyPaymentMethods !== null && serverResponse.teasyPaymentMethods.length > 0) {
          this.teasyPaymentMethodsArray = serverResponse.teasyPaymentMethods;
          this.isEnableTeasyGateway = true;
        }

        if(serverResponse.unifiedPaymentMethods != null && serverResponse.unifiedPaymentMethods.length > 0) {
          this.unifiedPaymentMethodsArray = serverResponse.unifiedPaymentMethods;
          this.isEnableUnifiedGateway = true;
        }

        if(this.utils.decryptString(this.encryptedPaymentType) === this.constants.COD_PAYMENT){
          this.referenceLabel = "Passcode";
        }
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private storeApplicationPaymentDetailsV2() {
    this.loaderService.startLoader();
    const applicantId = this.utils.decryptString(this.encryptedApplicantId);
    const paymentType = this.utils.decryptString(this.encryptedPaymentType);

    this.httpUtils.fetchApplicationPaymentDetailsV2(applicantId, paymentType).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        const serverFeeChangedMsg = serverResponse.feeChangedMsg;
        if(this.utils.isEmpty(serverFeeChangedMsg) === false){
          this.isPayButtonDisabled = true;
          this.toast.showErrorToastWithCallbackFunction(serverFeeChangedMsg, this.openApplicationAddEditPage.bind(this, this.ngZone, this.router, this.encryptedApplicantId, this.utils.encryptString(this.constants.PASSPORT_STEP.toString())));
          return;
        }

        if(paymentType === this.constants.COD_PAYMENT){
          this.referenceLabel = "Passcode";
        }
        
        this.applicationPaymentDetailsObject = serverResponse;
        this.applicationPaymentDetailsObject.transactionFee = 0;
        this.applicationPaymentDetailsObject.totalFeeIncludeServiceFee = this.applicationPaymentDetailsObject.totalFee;
        this.gatewayDetailsList = serverResponse.gatewayDetails;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  fetchTransactionCharges(paymentMethodsArray: any[]){
    for(let paymentMethod of paymentMethodsArray){
      if(this.paymentFormObject.paymentMethod === paymentMethod.key){
        this.applicationPaymentDetailsObject.transactionFee = paymentMethod.transactionFee;
        this.applicationPaymentDetailsObject.serviceCharge = paymentMethod.serviceCharge;
        this.applicationPaymentDetailsObject.totalFeeIncludeServiceFee = this.applicationPaymentDetailsObject.totalFee + this.applicationPaymentDetailsObject.transactionFee + this.applicationPaymentDetailsObject.serviceCharge;
        this.applicationPaymentDetailsObject.paymentMethodLabel = paymentMethod.label;
        break;
      }
    }
  }

  setTransactionFee(){
    for(let gatewayObject of this.gatewayDetailsList){
      for(let gatewayItemObject of gatewayObject.items){
        if(gatewayItemObject.paymentMethod === this.paymentFormObject.paymentMethod){
          this.applicationPaymentDetailsObject.transactionFee = gatewayItemObject.transactionFee;
          this.applicationPaymentDetailsObject.serviceCharge = gatewayItemObject.serviceCharge;
          this.applicationPaymentDetailsObject.totalFeeIncludeServiceFee = this.applicationPaymentDetailsObject.totalFee + gatewayItemObject.transactionFee + gatewayItemObject.serviceCharge;
          this.applicationPaymentDetailsObject.paymentMethodLabel = gatewayItemObject.paymentMethodLabelWithGateway;
          break;
        }
      }
    }
  }

  backToApplicationView(){
    if(this.utils.decryptString(this.encryptedPaymentType) === this.constants.APPLICATION_PAYMENT){
      this.gui.openApplicationView(this.applicationPaymentDetailsObject.applicantId, this.constants.NEXT_OF_KIN_INFO_STEP);
    } else {
      this.gui.openCODView(this.applicationPaymentDetailsObject.referenceNumber);
    }
  }
  
  setIsSubmitForm(isSubmitForm: number){
    this.isSubmitForm = isSubmitForm;
  }

  makePayment(){
    this.loaderService.startLoader();
    this.isPayButtonDisabled = true;
    if(this.isSubmitForm === this.constants.TRUE){
      this.localStorage.setStorageKey(this.constants.PAYMENT_TYPE, this.encryptedPaymentType);
      this.paymentPostForm.action = this.projectConfig.SERVER_BASE_URL + "v1/payments/makePayment";
      this.paymentPostForm.authToken.value = this.localStorage.getStorageKey(this.tags.APPLICATION_TOKEN);
      this.paymentPostForm.submit();
    } else {
      this.paymentFormObject.applicationId = this.applicationPaymentDetailsObject.applicationId;
      this.paymentFormObject.currencyCode = this.applicationPaymentDetailsObject.currencyCode;
      this.paymentFormObject.totalAmount = this.applicationPaymentDetailsObject.totalFee;
      this.paymentFormObject.transactionFee = this.applicationPaymentDetailsObject.transactionFee;
      this.paymentFormObject.serviceCharge = this.applicationPaymentDetailsObject.serviceCharge;
      this.paymentFormObject.paymentMode = this.paymentFormObject.paymentMethod;
      this.paymentFormObject.paymentType = this.utils.decryptString(this.encryptedPaymentType);
      this.httpUtils.makeApplicationPayment(this.paymentFormObject).then(serverResponse => {
        this.loaderService.stopLoader();
        const serverResponseCode = serverResponse.responseCode;
        if(serverResponseCode === this.constants.STATUS_SUCCESS){
          if(this.utils.isEmpty(serverResponse.feeChangedMsg) === false){
            this.toast.showErrorToast(serverResponse.feeChangedMsg);
          }
          this.gui.openPaymentReceipt(serverResponse.txnRefNumber);
        } else {
          this.toast.showErrorToast(serverResponse.responseMsg);
          this.isPayButtonDisabled = false;
        }
      });
    }
  }

}