import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { Constants } from '../common/constants';
import { LocalStorage } from '../common/local-storage';
import { ProjectConfig } from '../common/project-config';
import { Tags } from '../common/tags';
import { Utils } from '../common/utils';
import { NoticeModalComponent } from '../notice-modal/notice-modal.component';
import { Title } from "@angular/platform-browser";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit{

  constructor(private router: Router, private ngZone: NgZone, private utils: Utils, public constants: Constants, public projectConfig: ProjectConfig,
              private simpleModalService: SimpleModalService, private tags: Tags, private localStorage: LocalStorage, private title: Title) {
    this.title.setTitle("Nigerian Immigration Services | Passport");
  }

  ngOnInit(): void {
    this.openNoticeModal();
  }

  openApplicationForm(){
    this.ngZone.run(()=>this.router.navigate(["application"]));
  }

  openSearchTypeForm(searchType: string){
    this.ngZone.run(()=>this.router.navigate(["search", this.utils.encryptString(searchType)]));
  }

  openCODHomePage(){
    this.ngZone.run(()=>this.router.navigate(["main"]));
  }

  openBookAppointmentForm(){
    window.location.href = this.projectConfig.APPOINTMENT_APPLICATION_URL;
  }

  openTrackApplicationForm(){
    window.location.href = this.projectConfig.TRACK_APPLICATION_URL;
  }

   openCentreAvailabilityForm(){
      window.location.href = this.projectConfig.APPOINTMENT_APPLICATION_URL;
    }

  openNoticeModal(){
    const isNoticeModalOpenPrevious = this.localStorage.getStorageKey(this.tags.IS_NOTICE_MODAL_OPEN);
    if(this.utils.isEmpty(isNoticeModalOpenPrevious) === true){
      this.localStorage.setStorageKey(this.tags.IS_NOTICE_MODAL_OPEN, this.constants.TRUE.toString());
    } else if(Number(isNoticeModalOpenPrevious) === this.constants.TRUE){
      return;
    }
    // const message = "This is the new Passport Application Portal for applicants applying from within Nigeria. If you are applying from within Nigeria, click on the <b>Continue</b> button. <br /> <br />To apply from outside Nigeria or to use other NIS services, click on <b>Apply from outside Nigeria</b>."
    // this.simpleModalService.addModal(NoticeModalComponent, {message: message}, {autoFocus: true}).subscribe((isConfirmed: boolean)=> {
    //   if(isConfirmed === false){
    //     window.location.href = this.projectConfig.USD_PASSPORT_APPLICATION_URL;
    //   }
    // });
  }

}
