import { Constants } from "./constants";
import { Injectable } from '@angular/core';
import { SelectService } from "./select-service";
import * as CryptoJS from 'crypto-js';

declare function showJSToolTip(tagNameAttr: string): any;
declare function hideJSToolTip(tagNameAttr: string): any;

@Injectable({ 
    providedIn: 'root'
})
export class Utils {

    constructor(private constants: Constants){
    }

    getYesNoLabel(status: number){
        if(status === this.constants.TRUE){
            return "Yes";
        }
        return "No";
    }

    isEmpty(value:string){
        return (value === undefined || value === null || value === this.constants.BLANK);
    }

    showToolTip(tagNameAttr: string){
        showJSToolTip(tagNameAttr);
    }
    
    hideToolTip(tagNameAttr: string){
        hideJSToolTip(tagNameAttr);
    }

    private getPassportTypeLabel(){
        const passportTypeLabelObject : any = {};
        passportTypeLabelObject[this.constants.STANDARD_PASSPORT] = "Standard Passport";
        passportTypeLabelObject[this.constants.STANDARD_PASSPORT_RENEWAL_REISSUE] = "Standard Passport";
        passportTypeLabelObject[this.constants.STANDARD_PASSPORT_COD] = "Standard Passport";
        passportTypeLabelObject[this.constants.OFFICIAL_PASSPORT] = "Official Passport";
        return passportTypeLabelObject;
    }

    fetchPassportTypeLabel(passportTypeKey: string){
        if(this.isEmpty(passportTypeKey) === true){
            return "Passport";
        }
        const passportTypeLabelObject = this.getPassportTypeLabel();
        return passportTypeLabelObject[passportTypeKey];
    }

    isRenewPassport(passportTypeKey: string){
        // return passportTypeKey === this.constants.STANDARD_PASSPORT_RENEWAL || passportTypeKey === this.constants.STANDARD_PASSPORT_REISSUE;
        return (passportTypeKey === this.constants.STANDARD_PASSPORT_RENEWAL_REISSUE || passportTypeKey === this.constants.STANDARD_PASSPORT_COD);
    }
    
    storeChangeTypes(changeTypeSelectService: SelectService, applyingFor: string){
        const changeTypeList = [{value: this.constants.BLANK, text: "Choose Option"}];
        if(applyingFor === this.constants.REISSUE){
            changeTypeList.push({value: this.constants.UNEXPIRED, text: "Active"})
        }
        changeTypeList.push({value: this.constants.EXPIRED, text: "Expired"})
        changeTypeList.push({value: this.constants.PAGE_EXHAUSTED, text: "Page Exhausted"})
        changeTypeList.push({value: this.constants.LOST_REPLACEMENT_REQUEST, text: "Lost/Replacement Request"})
        changeTypeList.push({value: this.constants.DAMAGED_PASSPORT, text: "Damaged Passport"})
        changeTypeSelectService.setSelectOptions(changeTypeList);
    }

    storeAgeGroups(ageGroupSelectService: SelectService){
        let ageGroupList = [{value: "", text: "Choose Option"}];
        ageGroupList.push({value: "MINOR", text: "0-17"})
        ageGroupList.push({value: "ADULT", text: "18-59"})
        ageGroupList.push({value: "SENIOR_CITIZEN", text: "60 and above"})
        ageGroupSelectService.setSelectOptions(ageGroupList);
    }

    getSearchByParamListObject(encryptedMasterSearchType: string){
        const decryptedMasterSearchType = this.decryptString(encryptedMasterSearchType);
        let searchByParamListObject:any = {};
        searchByParamListObject[this.constants.APPLICATION_ID] = "Application Number";
        searchByParamListObject[this.constants.DATE_OF_BIRTH] = "Date Of Birth";
        if(decryptedMasterSearchType !== this.constants.GUARANTOR_FORM){
            searchByParamListObject[this.constants.EMAIL_ID] = "Email Id";
        }
        return searchByParamListObject;
    }

    encryptString(plainString: string){
        const encryptedString = CryptoJS.AES.encrypt(plainString.trim(), this.constants.ENCRYPT_DECRYPT_KEY.trim()).toString();
        const encryptedBase64String = btoa(encryptedString);
        return encryptedBase64String;
    }

    decryptString(encryptedBase64String: string){
        const encryptedString = atob(encryptedBase64String);
        const plainString = CryptoJS.AES.decrypt(encryptedString.trim(), this.constants.ENCRYPT_DECRYPT_KEY.trim()).toString(CryptoJS.enc.Utf8);
        return plainString;
    }

    decryptServerEncryptedString(encryptedString: string){
        const key = CryptoJS.PBKDF2(this.constants.ENCRYPT_DECRYPT_MASTER_PASSWORD, this.constants.ENCRYPT_DECRYPT_MASTER_SALT, {keySize: 4, iterations: 4});
        const decryptedString = CryptoJS.AES.decrypt(encryptedString, key, {iv: CryptoJS.enc.Utf8.parse(this.constants.ENCRYPT_DECRYPT_MASTER_KEY), padding: CryptoJS.pad.Pkcs7, mode: CryptoJS.mode.CBC});
        const plainString = decryptedString.toString(CryptoJS.enc.Utf8);
        return plainString;
    }

    removeSpace(inputString: string){
        return inputString.replace(/\s/g, "");
    }

    getStatusClass(status: number){
        if(status === this.constants.STATUS_INITIATE){
          return "warning";
        }
    
        if(status === this.constants.STATUS_REJECTED){
          return "danger";
        } 
        
        return "success";
    }

    private getTitleLabel(){
        let titleLabelObject:any = {};
        titleLabelObject[this.constants.MR] = "Mr.";
        titleLabelObject[this.constants.MRS] = "Mrs.";
        titleLabelObject[this.constants.MISS] = "Miss";
        return titleLabelObject;
    }

    fetchTitleLabel(titleKey: string){
        const titleLabelObject = this.getTitleLabel();
        return titleLabelObject[titleKey];
    }

    trimValue(formObject: {}, fieldName: string){
        const fieldValue = formObject[fieldName];
        if(fieldValue === undefined || fieldValue === this.constants.BLANK){
            return;
        }
        formObject[fieldName] = fieldValue.trim();
    }

    getMonthsArray(){
        const monthsList = [{value: "", text: "Select Month"}];
        monthsList.push({value: "01", text: "Jan"});
        monthsList.push({value: "02", text: "Feb"});
        monthsList.push({value: "03", text: "Mar"});
        monthsList.push({value: "04", text: "Apr"});
        monthsList.push({value: "05", text: "May"});
        monthsList.push({value: "06", text: "Jun"});
        monthsList.push({value: "07", text: "Jul"});
        monthsList.push({value: "08", text: "Aug"});
        monthsList.push({value: "09", text: "Sep"});
        monthsList.push({value: "10", text: "Oct"});
        monthsList.push({value: "11", text: "Nov"});
        monthsList.push({value: "12", text: "Dec"});
        return monthsList;
    }

    setMobileNumberFields(countryInfoObject: {}, formObject: {}, fieldsArray: any[]){
        formObject[fieldsArray[0]] = countryInfoObject['iso2'];
        formObject[fieldsArray[1]] = "+" + countryInfoObject['dialCode'];
    }

}