import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { Captcha } from '../common/captcha';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { ProjectConfig } from '../common/project-config';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { Title } from "@angular/platform-browser";
import { GUI } from '../common/gui';

@Component({
  selector: 'app-search-application',
  templateUrl: './search-application.component.html',
  styleUrls: ['./search-application.component.css']
})
export class SearchApplicationComponent {

  private masterSearchType : string = this.constants.BLANK;

  pageTitle : string = this.constants.BLANK;

  oldApplicationRedirectURL : string = this.constants.BLANK;
  
  searchApplicationFormObject : any = {};
  
  searchByParamListObject : any = {};
  
  searchByParamListObjectKeys = Object.keys;
  
  dateOfBirthMaxDate = new Date();
  
  latestYear : number;

  monthsList : any = [];

  constructor(private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, private toast: Toast, public utils: Utils, public constants: Constants, 
              private loaderService: LoaderService, public projectConfig: ProjectConfig, public captcha: Captcha, private simpleModalService: SimpleModalService, 
              private title: Title, public gui: GUI) {
    activatedRoute.params.subscribe((params: Params) => {
      this.masterSearchType = params['searchType'];
    });
    this.setPageTitle();
    this.monthsList = this.utils.getMonthsArray();
    this.searchApplicationFormObject.searchBy = constants.BLANK;
    this.searchByParamListObject = this.utils.getSearchByParamListObject(this.masterSearchType);
    this.latestYear = new Date().getFullYear();
    this.searchApplicationFormObject.dateOfBirthMonth = this.constants.BLANK;
    this.oldApplicationRedirectURL = this.utils.decryptString(this.masterSearchType) === this.constants.GUARANTOR_FORM ? this.projectConfig.OLD_NGN_PASSPORT_APPLICATION_GUARANTOR_URL : this.projectConfig.OLD_NGN_PASSPORT_APPLICATION_SEARCH_URL;
  }

  private setPageTitle(){
    const decryptedMasterSearchType = this.utils.decryptString(this.masterSearchType);
    if(decryptedMasterSearchType === this.constants.RESUME_APPLICATION){
      this.title.setTitle("Resume/Edit Passport Application | Nigerian Immigration Services");
      this.pageTitle = "Resume/Edit Your Application";
    } else if(decryptedMasterSearchType === this.constants.PAYMENT_STATUS){
      this.title.setTitle("Check Payment Status | Nigerian Immigration Services");
      this.pageTitle = "Payment Status/Print Receipt";
    } else if(decryptedMasterSearchType === this.constants.GUARANTOR_FORM){
      this.title.setTitle("Passport Guarantor Form | Nigerian Immigration Services");
      this.pageTitle = "Guarantor's Form";
    }
  }

  searchApplication(){
    if(this.searchApplicationFormObject.captchaVerified === false){
      return;
    }
    this.loaderService.startLoader();
    this.searchApplicationFormObject.searchType = this.utils.decryptString(this.masterSearchType);
    if(this.searchApplicationFormObject.searchBy === this.constants.DATE_OF_BIRTH){
      this.searchApplicationFormObject.searchValue = this.searchApplicationFormObject.dateOfBirthDay + "/" + this.searchApplicationFormObject.dateOfBirthMonth + "/" + this.searchApplicationFormObject.dateOfBirthYear;
    }
    this.httpUtils.searchApplication(this.searchApplicationFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      const serverResponseCode = serverResponse.responseCode;

      if(serverResponseCode === this.constants.STATUS_SUCCESS){
        this.redirectControl(serverResponse);
        return;
      } 

      this.toast.showErrorToast(serverResponse.responseMsg);

      if(serverResponseCode === this.constants.PAYMENT_ALREADY_COMPLETE || serverResponseCode === this.constants.PAYMENT_ALREADY_INITIATED){
        this.gui.openApplicationView(serverResponse.applicantId, serverResponse.applicationStage);
      }
    });
  }

  private redirectControl(serverResponse: any){
    const decryptedMasterSearchType = this.utils.decryptString(this.masterSearchType);
    if(decryptedMasterSearchType === this.constants.RESUME_APPLICATION) {
      this.gui.openApplicationAddEdit(serverResponse.applicantId, serverResponse.applicationStage);
      return;
    } 
    
    if(decryptedMasterSearchType === this.constants.PAYMENT_STATUS) {
      this.gui.openApplicationView(serverResponse.applicantId, serverResponse.applicationStage);
      return;
    } 
    
    if(decryptedMasterSearchType === this.constants.GUARANTOR_FORM) {
      this.gui.openApplicationGuarantor(serverResponse.applicantId);
      return;
    }
  }

  openRedirectionMessageModal(){
    const enteredReferenceNumber = this.searchApplicationFormObject.referenceNumber;
    if(this.utils.isEmpty(enteredReferenceNumber) === true){
      return;
    }

    if(enteredReferenceNumber.startsWith(this.constants.NEW_PORTAL_REFERENCE_NUMBER_PREFIX) === false){
      const message = "It seems like the reference number <b>"+enteredReferenceNumber+"</b> was generated on the previous version. <br />Please click OK so that you can be redirected to <b>" + this.oldApplicationRedirectURL + "</b>";
      this.simpleModalService.addModal(ConfirmationModalComponent, {message: message}, {autoFocus: true}).subscribe((isConfirmed: boolean)=> {
        if(isConfirmed === true){
          this.loaderService.startLoader();
          window.location.href = this.oldApplicationRedirectURL;
        } 
      });
    }
  }

}