<app-header *ngIf="requestResource !== constants.REQUEST_RESOURCE_BACK_OFFICE" class="view-header"></app-header>

<div *ngIf="requestResource !== constants.REQUEST_RESOURCE_BACK_OFFICE" class="banner-img-name view-top"></div>

<section class="main" [ngStyle]="{'width': requestResource === constants.REQUEST_RESOURCE_BACK_OFFICE ? '100%' : '90%'}">
    <div class="profile-view">

        <form action="" method="post" name="applicationViewForm" #applicationViewForm="ngForm" autocomplete="off" id="msform1">
            <input type="hidden" name="applicationId" value="{{personalDetailsObject.applicationId}}">
            <input *ngIf="isPaymentDetailsExist === true && personalDetailsObject.applicationStatus === 200" type="hidden" name="txnReference" value="{{paymentDetailsObject.merchantRef}}">
            <input type="hidden" name="downloadType" value="">

            <table class="h_logo">
                <tr>
                    <td></td>
                    <td><img src="assets/images/logo-nis.png" style="width:160px;"></td>
                    <td></td>
                </tr>
            </table>

            <div class="s-head">
                <div class="result-header">
                    <div class="user-info-detail">
                        <div class="upper_bar" style="border-bottom:1px solid #979797;align-items: center;">
                            <div *ngIf="isPaymentDetailsExist === true" class="barcode">
                                <ngx-barcode [bc-value]="barCodeValue" [bc-display-value]="true" [bc-width]="1" [bc-height]="40" [bc-font-size]="14"></ngx-barcode>
                            </div>
                            <div *ngIf="isPaymentDetailsExist === false">
                            </div>
                            <div class="visa_type" style="text-align: right;">
                                <p>{{passportTypeDetailsObject.passportType}} Application</p>
                            </div>
                        </div>
                        <div class="common_view" >
                            <div>
                                <label>Reference Number</label>
                                <p>{{personalDetailsObject.referenceNumber}}</p>
                            </div>
                            <div>
                                <label>Application Number</label>
                                <p>{{personalDetailsObject.applicationId}}</p>
                            </div>
                            <div>
                                <label>Application Date</label>
                                <p>{{personalDetailsObject.applicationDate}}</p>
                            </div>
                            <div>
                                <label>Payment Status</label>
                                <p><img [src]="personalDetailsObject.paymentStatusImageURL" class="status_img" style="width:100%;max-width:51px;"></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section id="applicationdetail" class="">
                <div class="s-content">
                    <div class="border_line">
                        <p class="p-body  line pb10">Passport Details</p>
                        <div class="sr-p-info" >
                            <div>
                                <label>Applying For</label>
                                <!-- <p>{{passportTypeDetailsObject.passportType}} {{passportTypeDetailsObject.applyingFor}}</p> -->
                                <p>{{passportTypeDetailsObject.passportType}}</p>
                            </div>
                            <div style="border-bottom: 1px solid #aeaeae!important;">
                                <label>Booklet Type / Validity</label>
                                <p>{{passportTypeDetailsObject.bookletType}} / {{passportTypeDetailsObject.passportValidity}}</p>
                            </div>
                            <div *ngIf="utils.isEmpty(passportTypeDetailsObject.changeType) === false">
                                <label>Change Type</label>
                                <p>{{passportTypeDetailsObject.changeType}}</p>
                            </div>
                            <div *ngIf="utils.isEmpty(passportTypeDetailsObject.passportNumber) === false" style="border-bottom: 1px solid #aeaeae!important;">
                                <label>Passport Number</label>
                                <p>{{passportTypeDetailsObject.passportNumber}}</p>
                            </div>
                            <div>
                                <label>Processing State / Processing Office</label>
                                <p>{{passportTypeDetailsObject.processingState}} / {{passportTypeDetailsObject.processingOffice}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="border_line">
                        <p class="p-body line">Personal Details</p>
                        <div class="sr-p-info">
                            <div>
                                <label>Full Name</label>
                                <p class="p-body">{{personalDetailsObject.fullName}}</p>
                            </div>
                            <div>
                                <label>Date of Birth</label>
                                <p>{{personalDetailsObject.dateOfBirth}}</p>
                            </div>
                            <div>
                                <label>Place of Birth</label>
                                <p>{{personalDetailsObject.placeOfBirth}}</p>
                            </div>
                            <div>
                                <label>Gender</label>
                                <p>{{personalDetailsObject.gender}}</p>
                            </div>
                            <div>
                                <label>Marital Status</label>
                                <p>{{personalDetailsObject.maritalStatus}}</p>
                            </div>
                            <div>
                                <label>Occupation</label>
                                <p>{{personalDetailsObject.occupation}}</p>
                            </div>
                            <div>
                                <label>Maiden Name</label>
                                <p>{{personalDetailsObject.motherMaidenName}}</p>
                            </div>
                            <div style="border-bottom: 1px solid #aeaeae!important;">
                                <label>National Identification Number (NIN)</label>
                                <p>{{personalDetailsObject.ninNumber}}</p>
                            </div>
                            <div>
                                <label>Email</label>
                                <p>{{personalDetailsObject.emailId}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="border_line mtop20">
                        <p class="p-body  line pb10">Contact Information</p>
                        <div class="sr-p-info">
                            <div>
                                <label>Home Town / Village</label>
                                <p>{{contactDetailsObject.contactCity}}</p>
                            </div>
                            <div>
                                <label>State of Origin</label>
                                <p>{{contactDetailsObject.contactState}}</p>
                            </div>
                            <div>
                                <label>Mobile Number</label>
                                <p>{{contactDetailsObject.mobileNumberDialCode}} {{contactDetailsObject.mobileNumber}}</p>
                            </div>
                            <div>
                                <label>Phone Number</label>
                                <p>{{contactDetailsObject.phoneNumberDialCode}} {{contactDetailsObject.phoneNumber}}</p>
                            </div>
                            <div>
                                <label>Permanent Address</label>
                                <p>{{contactDetailsObject.addressLine1}}, {{contactDetailsObject.addressCity}}, {{contactDetailsObject.addressLGA}}, {{contactDetailsObject.addressState}}, {{contactDetailsObject.addressPostalCode}}</p>
                            </div>
                            <div>
                                <label>Current Address</label>
                                <p>{{contactDetailsObject.currentAddressLine1}}, {{contactDetailsObject.currentAddressCity}}, {{contactDetailsObject.currentAddressLGA}}, {{contactDetailsObject.currentAddressState}}, {{contactDetailsObject.currentAddressPostalCode}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="border_line">
                        <p class="p-body  line pb10">Kin Information</p>
                        <div class="sr-p-info" >
                            <div>
                                <label>Name of Kin</label>
                                <p>{{kinDetailsObject.kinName}}</p>
                            </div>
                            <div>
                                <label>Relationship</label>
                                <p>{{kinDetailsObject.kinRelation}}</p>
                            </div>
                            <div>
                                <label>Address</label>
                                <p>{{kinDetailsObject.addressLine1}}, {{kinDetailsObject.addressCity}}, {{kinDetailsObject.addressLGA}}, {{kinDetailsObject.addressState}}, {{kinDetailsObject.addressPostalCode}}</p>
                            </div>
                            <div>
                                <label>Contact Number</label>
                                <p>{{kinDetailsObject.mobileNumberDialCode}} {{kinDetailsObject.mobileNumber}}</p>
                            </div>
                        </div>
                    </div>

                    <div class="half_col mtop20">
                        <div class="half_col_box" style="margin-right:10px;">
                            <p class="p-body  line pb10">Fee Details</p>
                            <div class="tab50" >
                                <div>
                                    <label>Passport Booklet Fee</label>
                                    <span class="fr">{{feeDetailsObject.currencySymbol}} {{feeDetailsObject.bookletFee | number : '1.2' : 'en-US'}}</span>
                                </div>
                                <div *ngIf="feeDetailsObject.transactionFee > 0">
                                    <label>Transaction Fee</label>
                                    <span class="fr">{{feeDetailsObject.currencySymbol}} {{feeDetailsObject.transactionFee | number : '1.2' : 'en-US'}}</span>
                                </div>
                                <div *ngIf="feeDetailsObject.addressVerificationFee > 0">
                                    <label>Address Verification Fee</label>
                                    <span class="fr">{{feeDetailsObject.currencySymbol}} {{feeDetailsObject.addressVerificationFee | number : '1.2' : 'en-US'}}</span>
                                </div>
                                <div *ngIf="feeDetailsObject.addressVerificationServiceCharge > 0">
                                    <label>Address Verification Service Charges</label>
                                    <span class="fr">{{feeDetailsObject.currencySymbol}} {{feeDetailsObject.addressVerificationServiceCharge | number : '1.2' : 'en-US'}}</span>
                                </div>
                                <div class="total_box" style="border-bottom: 0;">
                                    <label>Total Fee</label>
                                    <span class="fr">{{feeDetailsObject.currencySymbol}} {{feeDetailsObject.totalFee | number : '1.2' : 'en-US'}}</span>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="isPaymentDetailsExist === true" class="half_col_box">
                            <p class="p-body  line pb10">Payment Details</p>
                            <div class="tab50">
                                <div>
                                    <label>Payment Date</label>
                                    <span class="fr">{{paymentDetailsObject.paymentDate}}</span>
                                </div>

                                <div>
                                    <label>Payment Gateway</label>
                                    <span class="fr">{{paymentDetailsObject.paymentGateway}}</span>
                                </div>

                                <div>
                                    <label>Payment Mode</label>
                                    <span class="fr">{{paymentDetailsObject.paymentMethod}}</span>
                                </div>

                                <div>
                                    <label>Gateway Reference</label>
                                    <span class="fr">{{paymentDetailsObject.gatewayReference}}</span>
                                </div>

                                <div *ngIf="utils.isEmpty(paymentDetailsObject.validationNo) === false">
                                    <label>Validation Number</label>
                                    <span class="fr">{{paymentDetailsObject.validationNo}}</span>
                                </div>

                                <div *ngIf="utils.isEmpty(paymentDetailsObject.pan) === false">
                                    <label>PAN</label>
                                    <span class="fr">{{paymentDetailsObject.pan}}</span>
                                </div>

                                <div *ngIf="utils.isEmpty(paymentDetailsObject.authCode) === false">
                                    <label>Auth Code</label>
                                    <span class="fr">{{paymentDetailsObject.authCode}}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="mt15 note">
                    <span><b style="color:red;">NOTE:</b>False information may lead to delay in processing this application or outright rejection.</span>
                </div>

                <div *ngIf="personalDetailsObject.applicationStatus === 100" class="t_and_c">
                    <input type="checkbox" name="termCondition" [(ngModel)]="isTermConditionSelect" #termCondition="ngModel" required="required"> <span style="padding:0 3px;">I hereby confirm the information provided is true, accurate and complete.</span>
                    <span class="alert alert-danger" role="alert" *ngIf="confirmationButtonClicked && termCondition.invalid">
                        <span *ngIf="termCondition.errors.required">
                            Confirmation is required
                        </span>
                    </span>
                </div>

            </section>

            <div *ngIf="requestResource !== constants.REQUEST_RESOURCE_BACK_OFFICE" class="view-buttons">
                <input type="button" (click)="backToPage()" value="Close" class="back_btn">
                <input *ngIf="isPaymentDetailsExist === false && personalDetailsObject.paymentStatus === 0" type="button" (click)="editApplication()" value="Edit" class="edit_btn">
                <input *ngIf="personalDetailsObject.applicationStatus === 100" type="button" (click)="showConfirmationForApplicationSubmission(constants.FALSE)" value="Confirm & Pay Later" class="save_btn">
                <input *ngIf="personalDetailsObject.applicationStatus === 100" type="button" (click)="showConfirmationForApplicationSubmission(constants.TRUE)" value="Confirm & Pay Now" class="custom_btn">
                <input *ngIf="isPaymentDetailsExist === false && personalDetailsObject.applicationStatus === 200 && personalDetailsObject.paymentStatus !== 200" type="button" (click)="openPaymentPage()" value="Pay Now" class="custom_btn">
                <input *ngIf="personalDetailsObject.applicationStatus === 200" type="button" (click)="printApplication()" value="Print Application" class="save_btn">
                <input *ngIf="isPaymentDetailsExist === true && personalDetailsObject.applicationStatus === 200" type="button" (click)="downloadPDF()" value="Download Receipt" class="receipt_btn">
                <input *ngIf="isPaymentDetailsExist === true && personalDetailsObject.applicationStatus === 200" type="button" (click)="bookAppointment()" value="Book Appointment" class="edit_btn">
            </div>

        </form>

    </div>
</section>

<app-footer *ngIf="requestResource !== constants.REQUEST_RESOURCE_BACK_OFFICE" class="view-footer"></app-footer>
