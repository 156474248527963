import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationAddEditComponent } from './application-add-edit/application-add-edit.component';
import { ApplicationViewComponent } from './application-view/application-view.component';
import { CODApproveComponent } from './codapprove/codapprove.component';
import { CODConfirmationSuccessComponent } from './codconfirmation-success/codconfirmation-success.component';
import { CODConfirmationComponent } from './codconfirmation/codconfirmation.component';
import { CODHomeComponent } from './codhome/codhome.component';
import { CODRequestSearchComponent } from './codrequest-search/codrequest-search.component';
import { CODRequestViewComponent } from './codrequest-view/codrequest-view.component';
import { CODRequestComponent } from './codrequest/codrequest.component';
import { GuarantorComponent } from './guarantor/guarantor.component';
import { HomeComponent } from './home/home.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PaymentFailureComponent } from './payment-failure/payment-failure.component';
import { PaymentReceiptComponent } from './payment-receipt/payment-receipt.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentComponent } from './payment/payment.component';
import { SearchApplicationComponent } from './search-application/search-application.component';

const routes: Routes = [
  {path: '', component: HomeComponent, pathMatch: 'full'},
  {path: 'application', component: ApplicationAddEditComponent, pathMatch: 'full'},
  {path: 'application/:applicantId/:applicationStage', component: ApplicationAddEditComponent, pathMatch: 'full'},

  {path: 'application/:passcode', component: ApplicationAddEditComponent, pathMatch: 'full'},
  {path: 'view/:applicantId', component: ApplicationViewComponent, pathMatch: 'full'},
  {path: 'view/:applicantId/:requestResource', component: ApplicationViewComponent, pathMatch: 'full'},
  {path: 'search/:searchType', component: SearchApplicationComponent, pathMatch: 'full'},
  {path: 'payment/:applicantId/:paymentType', component: PaymentComponent, pathMatch: 'full'},
  {path: 'receipt/:txnReferenceNumber', component: PaymentReceiptComponent, pathMatch: 'full'},
  {path: 'success/:txnRef', component: PaymentSuccessComponent, pathMatch: 'full'},
  {path: 'failure/:txnRef/:referenceNumber/:applicationId', component: PaymentFailureComponent, pathMatch: 'full'},
  {path: 'guarantor/:applicantId', component: GuarantorComponent, pathMatch: 'full'},
  {path: 'main', component: CODHomeComponent, pathMatch: 'full'},
  {path: 'request', component: CODRequestComponent, pathMatch: 'full'},
  {path: 'request/:passcode', component: CODRequestComponent, pathMatch: 'full'},
  {path: 'confirmation/:correctionId', component: CODConfirmationComponent, pathMatch: 'full'},
  {path: 'completed/:applicationNumber/:passcode', component: CODConfirmationSuccessComponent, pathMatch: 'full'},
  {path: 'approve', component: CODApproveComponent, pathMatch: 'full'},
  {path: 'perspective/:passcode', component: CODRequestViewComponent, pathMatch: 'full'},
  {path: 'exploration', component: CODRequestSearchComponent, pathMatch: 'full'},
  {path: '**', component: PageNotFoundComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})

export class AppRoutingModule { }
