<app-header class="view-header"></app-header>

<div class="banner-img-name"></div>

<section class="main">
    <div class="profile-view">

        <table class="h_logo">
            <tr>
                <td></td>
                <td><img src="assets/images/logo-nis.png" style="width:160px;"></td>
                <td></td>
            </tr>
        </table>

        <div class="s-head">
            <div class="result-header">
                <div class="user-info-detail">
                    <div class="upper_bar" style="border-bottom:1px solid #979797;">
                        <div></div>
                        <div class="visa_type" style="text-align: right;">
                            <p>Change Request Application</p>
                        </div>
                    </div>
                    <div class="common_view" >
                        <div>
                            <label>Passcode</label>
                            <p>{{personalDetailsObject.passcode}}</p>
                        </div>
                        <div>
                            <label>Application No.</label>
                            <p>{{personalDetailsObject.applicationId}}</p>
                        </div>
                        <div>
                            <label>Application Date</label>
                            <p>{{personalDetailsObject.applicationDate}}</p>
                        </div>
                        <div *ngIf="codFeeDetailsObject.isFeeToBeCharged > 0">
                            <label>Payment Status</label>
                            <p><img [src]="personalDetailsObject.paymentStatusImageURL" class="status_img" style="width:70px;"></p>
                        </div>
                        <div>
                            <label>Approval Status</label>
                            <p><label [innerHTML]="personalDetailsObject.approvalStatusLabel"></label></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <section id="applicationdetail" class="">
            <div class="s-content">

                <div class="border_line">
                    <p class="p-body  line pb10">Passport Details</p>
                    <div class="sr-p-info" >
                        <div>
                            <label>Passport Type</label>
                            <p>{{personalDetailsObject.passportType}}</p>
                        </div>
                        <div>
                            <label>Passport Number</label>
                            <p>{{personalDetailsObject.passportNumber}}</p>
                        </div>
                    </div>
                </div>

                <div class="border_line">
                    <p class="p-body  line">Personal Details</p>
                    <div class="sr-p-info">
                        <div>
                            <label>Full Name</label>
                            <p class="p-body">{{personalDetailsObject.fullName}}</p>
                        </div>
                        <div>
                            <label>Gender</label>
                            <p>{{personalDetailsObject.gender}}</p>
                        </div>
                        <div>
                            <label>Date of Birth</label>
                            <p>{{personalDetailsObject.dateOfBirth}}</p>
                        </div>
                        <div>
                            <label>Place of Birth</label>
                            <p>{{personalDetailsObject.placeOfBirth}}</p>
                        </div>
                    </div>
                </div>

                <div class="border_line">
                    <p class="p-body line">Change of Data Details</p>
                    <div class="detail_change_view">
                        <table class="cod-change-field">
                            <tr>
                                <th style="font-size: 13px;">Fields to be Changed</th>
                                <th style="font-size: 13px;">Current Data</th>
                                <th style="font-size: 13px;">New Data</th>
                                <th style="font-size: 13px;">Reason</th>
                            </tr>
                            <tr *ngFor="let changedFieldObject of changedFieldsArray;">
                                <td style="border-left:0;" data-label="Fields to be Changed">{{changedFieldObject.fieldLabel}}</td>
                                <td data-label="Current Data">{{changedFieldObject.existingValue}}</td>
                                <td data-label="New Data">{{changedFieldObject.newValue}}</td>
                                <td data-label="Reason">{{changedFieldObject.reason === constants.OTHERS ? changedFieldObject.otherReason : changedFieldObject.reason}}</td>
                            </tr>
                        </table>
                    </div>
                </div>

                <div class="half_col mtop20">
                    <div class="half_col_box" style="margin-right:10px;">
                        <p class="p-body  line pb10">Fee Details</p>
                        <div class="tab50" >
                            <div>
                                <label>Administrative Fees</label>
                                <span class="fr">{{codFeeDetailsObject.currencySymbol}} {{codFeeDetailsObject.paymentAmount}}</span>
                            </div>

                            <div *ngIf="codFeeDetailsObject.isFeeToBeCharged > 0 && isPaymentDetailsExist === true">
                                <label>Transaction Fees</label>
                                <span class="fr">{{codFeeDetailsObject.currencySymbol}} {{codFeeDetailsObject.transactionFee}}</span>
                            </div>

                            <div *ngIf="codFeeDetailsObject.isFeeToBeCharged > 0 && isPaymentDetailsExist === true">
                                <label>Service Charge</label>
                                <span class="fr">{{codFeeDetailsObject.currencySymbol}} {{codFeeDetailsObject.serviceCharge}}</span>
                            </div>

                            <div class="total_box" >
                                <label>Total Fee</label>
                                <span class="fr">{{codFeeDetailsObject.currencySymbol}} {{codFeeDetailsObject.totalAmount}}</span>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="codFeeDetailsObject.isFeeToBeCharged > 0 && isPaymentDetailsExist === true" class="half_col_box">
                        <p class="p-body line pb10">Payment Details</p>
                        <div class="tab50">
                            <div>
                                <label>Payment Date</label>
                                <span class="fr">{{codPaymentDetailsObject.paymentDate}}</span>
                            </div>
                            <div>
                                <label>Payment Gateway</label>
                                <span class="fr">{{codPaymentDetailsObject.paymentGateway}}</span>
                            </div>
                            <div>
                                <label>Payment Mode</label>
                                <span class="fr">{{codPaymentDetailsObject.paymentMode}}</span>
                            </div>
                            <div>
                                <label>Validation Number</label>
                                <span class="fr">{{codPaymentDetailsObject.validationNo}}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

        <div class="view-buttons">
            <input type="button" (click)="backToHomePage()" value="Close" class="back_btn">
            <!-- <input *ngIf="isPaymentDetailsExist === false && personalDetailsObject.paymentStatus === 0" type="button" (click)="editApplication()" value="Edit" class="edit_btn"> -->
            <input *ngIf="personalDetailsObject.paymentStatus !== 200" type="button" (click)="openPaymentPage()" value="Pay Now" class="custom_btn">
            <input type="button" (click)="printApplication()" value="Print" class="save_btn">
        </div>

    </div>
</section>

<br><br>

<app-footer class="view-footer"></app-footer>
