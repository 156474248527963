import { Component, NgZone, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Captcha } from '../common/captcha';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { ProjectConfig } from '../common/project-config';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';

@Component({
  selector: 'app-codrequest',
  templateUrl: './codrequest.component.html',
  styleUrls: ['./codrequest.component.css']
})
export class CODRequestComponent implements OnInit {

  private masterEncryptedPasscode:string="";

  applicationStage:number=1;
  applicationHighestStage:number=1;

  personalDetailsFormObject:any = {};
  nameChangeDetailsFormObject:any = {};
  dateOfBirthChangeDetailsFormObject:any = {};
  genderChangeDetailsFormObject:any = {};

  currentDateOfBirthMaxDate = new Date();
  currentDateOfBirthMinDate = new Date();


  monthsList:any = [];

  disableNameFields:boolean = false;

  constructor(private router: Router, private ngZone: NgZone, private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, private toast: Toast, public utils: Utils,
              public constants: Constants, private loaderService: LoaderService, public projectConfig: ProjectConfig, public captcha: Captcha) {
    activatedRoute.params.subscribe((params: Params) => {
      this.masterEncryptedPasscode = params['passcode'];
    });
    if(this.masterEncryptedPasscode !== undefined && this.masterEncryptedPasscode !== "") {
      this.storeCODApplicationData();
    }
    this.monthsList = this.utils.getMonthsArray();
    this.setPersonalDetailsFormDefaultValues();
    this.setNameChangeDetailsFormDefaultValues();
    this.setDateOfBirthChangeDetailsFormDefaultValues();
    this.setGenderChangeDetailsFormDefaultValues();
    captcha.handleCaptchaLoad(this.personalDetailsFormObject);
  }

  ngOnInit(): void {
    window.scrollTo(0,0);
  }

  openSection(applicationCurrentStage: number){
    this.applicationStage = applicationCurrentStage;
  }

  openPreviousSection(){
    --this.applicationStage;
  }

  openCODHomePage(){
    this.ngZone.run(()=>this.router.navigate(["main"]));
  }

  private setPersonalDetailsFormDefaultValues(){
    this.personalDetailsFormObject.currentPassportType = this.constants.STANDARD_PASSPORT;
    this.personalDetailsFormObject.currentTitle = this.constants.BLANK;
    this.personalDetailsFormObject.currentGender = this.constants.BLANK;
    this.personalDetailsFormObject.currentDateOfBirthMonth = this.constants.BLANK;
    this.currentDateOfBirthMaxDate.setDate(this.currentDateOfBirthMaxDate.getDate() - 2);
    this.currentDateOfBirthMinDate.setFullYear(this.currentDateOfBirthMinDate.getFullYear() - 99);
    this.personalDetailsFormObject.currentBookletType = this.constants.BLANK;
  }

  private setNameChangeDetailsFormDefaultValues(){
    this.nameChangeDetailsFormObject.desiredTitle = this.constants.BLANK;
    this.nameChangeDetailsFormObject.reason = this.constants.BLANK;
  }

  private setDateOfBirthChangeDetailsFormDefaultValues(){
    this.dateOfBirthChangeDetailsFormObject.desiredDateOfBirthMonth = this.constants.BLANK;
    this.dateOfBirthChangeDetailsFormObject.dateOfBirthReason = this.constants.BLANK;
    this.dateOfBirthChangeDetailsFormObject.placeOfBirthReason = this.constants.BLANK;
  }

  private setGenderChangeDetailsFormDefaultValues(){
    this.genderChangeDetailsFormObject.desiredGender = this.constants.BLANK;
    this.genderChangeDetailsFormObject.genderReason = this.constants.BLANK;
  }

  setGender(){
    this.personalDetailsFormObject.currentTitleLabel = this.utils.fetchTitleLabel(this.personalDetailsFormObject.currentTitle);

    if(this.personalDetailsFormObject.currentTitle === this.constants.BLANK){
      this.personalDetailsFormObject.currentGender = this.constants.BLANK;
      return;
    }

    if(this.personalDetailsFormObject.currentTitle === this.constants.MR){
      this.personalDetailsFormObject.currentGender = this.constants.MALE;
      return;
    }

    this.personalDetailsFormObject.currentGender = this.constants.FEMALE;
  }

  private storeCODApplicationData(){
    const passcode = this.utils.decryptString(this.masterEncryptedPasscode);
    this.httpUtils.fetchCODCurrentData(passcode).then(serverResponse => {
      const serverResponseCode = serverResponse.responseCode;
      if(serverResponseCode === this.constants.STATUS_SUCCESS){
        this.personalDetailsFormObject = serverResponse;
        this.nameChangeDetailsFormObject.isDataChange = serverResponse.isDataChange;
        this.dateOfBirthChangeDetailsFormObject.isDateOfBirthChange = serverResponse.isDateOfBirthChange;
        this.dateOfBirthChangeDetailsFormObject.isPlaceOfBirthChange = serverResponse.isPlaceOfBirthChange;
        this.genderChangeDetailsFormObject.isGenderChange = serverResponse.isGenderChange;
        this.setDateOfBirth();
        this.applicationStage = 2;
        this.applicationHighestStage = 2;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  private setDateOfBirth(){
    const dateOfBirthArray = this.personalDetailsFormObject.currentDateOfBirth.split("/");
    this.personalDetailsFormObject.currentDateOfBirthDay = dateOfBirthArray[0];
    this.personalDetailsFormObject.currentDateOfBirthMonth = dateOfBirthArray[1];
    this.personalDetailsFormObject.currentDateOfBirthYear = dateOfBirthArray[2];
  }

  openNameChangeForm(){
    if(this.personalDetailsFormObject.captchaVerified === false){
      return;
    }
    window.scrollTo(0,0);
    this.loaderService.startLoader();
    this.personalDetailsFormObject.currentDateOfBirth = this.personalDetailsFormObject.currentDateOfBirthDay + "/" + this.personalDetailsFormObject.currentDateOfBirthMonth + "/" + this.personalDetailsFormObject.currentDateOfBirthYear;
    this.httpUtils.saveCODPersonalDetailsData(this.personalDetailsFormObject).then(serverResponse => {
      this.loaderService.stopLoader();
      const serverResponseCode = serverResponse.responseCode;
      if(serverResponseCode === this.constants.STATUS_SUCCESS){
        this.personalDetailsFormObject.dataCorrectionId = serverResponse.dataCorrectionId;
        this.applicationStage = 2;
        this.applicationHighestStage = 2;
        this.nameChangeDetailsFormObject.desiredTitle = this.personalDetailsFormObject.currentTitle;
        this.nameChangeDetailsFormObject.desiredLastName = this.personalDetailsFormObject.currentLastName;
        this.nameChangeDetailsFormObject.desiredFirstName = this.personalDetailsFormObject.currentFirstName;
        this.nameChangeDetailsFormObject.desiredMiddleName = this.personalDetailsFormObject.currentMiddleName;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  setNameDefaultValues(){
    if(this.nameChangeDetailsFormObject.reason === this.constants.OTHERS){
      this.disableNameFields = false;
    } else {
      this.nameChangeDetailsFormObject.desiredTitle = this.personalDetailsFormObject.currentTitle;
      this.nameChangeDetailsFormObject.desiredLastName = this.personalDetailsFormObject.currentLastName;
      this.nameChangeDetailsFormObject.desiredFirstName = this.personalDetailsFormObject.currentFirstName;
      this.nameChangeDetailsFormObject.desiredMiddleName = this.personalDetailsFormObject.currentMiddleName;
      this.disableNameFields = true;
    }
  }

  openDateOfBirthChangeForm(){
    window.scrollTo(0,0);
    this.loaderService.startLoader();
    const dataCorrectionItemsArray = [];
    dataCorrectionItemsArray.push({fieldKey: "TITLE", fieldLabel: "Title", isDataChanged: this.nameChangeDetailsFormObject.isDataChange, existingValue: this.personalDetailsFormObject.currentTitle, newValue: this.nameChangeDetailsFormObject.desiredTitle, reason: this.nameChangeDetailsFormObject.reason, otherReason: this.nameChangeDetailsFormObject.otherReason, sequence: 1});
    dataCorrectionItemsArray.push({fieldKey: "LAST_NAME", fieldLabel: "Surname", isDataChanged: this.nameChangeDetailsFormObject.isDataChange, existingValue: this.personalDetailsFormObject.currentLastName, newValue: this.nameChangeDetailsFormObject.desiredLastName, reason: this.nameChangeDetailsFormObject.reason, otherReason: this.nameChangeDetailsFormObject.otherReason, sequence: 2});
    dataCorrectionItemsArray.push({fieldKey: "FIRST_NAME", fieldLabel: "First Name", isDataChanged: this.nameChangeDetailsFormObject.isDataChange, existingValue: this.personalDetailsFormObject.currentFirstName, newValue: this.nameChangeDetailsFormObject.desiredFirstName, reason: this.nameChangeDetailsFormObject.reason, otherReason: this.nameChangeDetailsFormObject.otherReason, sequence: 3});
    dataCorrectionItemsArray.push({fieldKey: "MIDDLE_NAME", fieldLabel: "Middle Name", isDataChanged: this.nameChangeDetailsFormObject.isDataChange, existingValue: this.personalDetailsFormObject.currentMiddleName, newValue: this.nameChangeDetailsFormObject.desiredMiddleName, reason: this.nameChangeDetailsFormObject.reason, otherReason: this.nameChangeDetailsFormObject.otherReason, sequence: 4});
    this.httpUtils.saveChangeDetailsData(this.constants.TRUE, this.personalDetailsFormObject.dataCorrectionId, this.constants.BLANK, dataCorrectionItemsArray).then(serverResponse => {
      this.loaderService.stopLoader();
      const serverResponseCode = serverResponse.responseCode;
      if(serverResponseCode === this.constants.STATUS_SUCCESS) {
        this.nameChangeDetailsFormObject.batchId = serverResponse.batchId;
        if(this.nameChangeDetailsFormObject.isDataChange === this.constants.TRUE && this.nameChangeDetailsFormObject.reason !== this.constants.OTHERS){
          this.ngZone.run(()=>this.router.navigate(["confirmation", this.utils.encryptString(this.personalDetailsFormObject.dataCorrectionId)]));
        } else {
          this.applicationStage = 3;
          this.applicationHighestStage = 3;
        }
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }

    });
  }

  openGenderChangeForm(){
    window.scrollTo(0,0);
    this.loaderService.startLoader();
    const dataCorrectionItemsArray = [];
    this.dateOfBirthChangeDetailsFormObject.desiredDateOfBirth = this.dateOfBirthChangeDetailsFormObject.desiredDateOfBirthDay + "/" + this.dateOfBirthChangeDetailsFormObject.desiredDateOfBirthMonth + "/" + this.dateOfBirthChangeDetailsFormObject.desiredDateOfBirthYear;
    dataCorrectionItemsArray.push({fieldKey: "DATE_OF_BIRTH", fieldLabel: "Date Of Birth", isDataChanged: this.dateOfBirthChangeDetailsFormObject.isDateOfBirthChange, existingValue: this.personalDetailsFormObject.currentDateOfBirth, newValue: this.dateOfBirthChangeDetailsFormObject.desiredDateOfBirth, reason: this.dateOfBirthChangeDetailsFormObject.dateOfBirthReason, otherReason: this.constants.BLANK, sequence: 4});
    dataCorrectionItemsArray.push({fieldKey: "PLACE_OF_BIRTH", fieldLabel: "Place Of Birth", isDataChanged: this.dateOfBirthChangeDetailsFormObject.isPlaceOfBirthChange, existingValue: this.personalDetailsFormObject.currentPlaceOfBirth, newValue: this.dateOfBirthChangeDetailsFormObject.desiredPlaceOfBirth, reason: this.dateOfBirthChangeDetailsFormObject.placeOfBirthReason, otherReason: this.constants.BLANK, sequence: 5});
    this.httpUtils.saveChangeDetailsData(this.constants.FALSE, this.personalDetailsFormObject.dataCorrectionId, this.nameChangeDetailsFormObject.batchId, dataCorrectionItemsArray).then(serverResponse => {
      this.loaderService.stopLoader();
      const serverResponseCode = serverResponse.responseCode;
      if(serverResponseCode === this.constants.STATUS_SUCCESS){
        this.dateOfBirthChangeDetailsFormObject.batchId = serverResponse.batchId;
        this.applicationStage = 4;
        this.applicationHighestStage = 4;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  openConfirmationPage(){
    this.loaderService.startLoader();
    const dataCorrectionItemsArray = [];
    dataCorrectionItemsArray.push({fieldKey: "GENDER", fieldLabel: "Gender", isDataChanged: this.genderChangeDetailsFormObject.isGenderChange, existingValue: this.personalDetailsFormObject.currentGender, newValue: this.genderChangeDetailsFormObject.desiredGender, reason: this.genderChangeDetailsFormObject.genderReason, otherReason: this.constants.BLANK, sequence: 6});
    this.httpUtils.saveChangeDetailsData(this.constants.FALSE, this.personalDetailsFormObject.dataCorrectionId, this.nameChangeDetailsFormObject.batchId, dataCorrectionItemsArray).then(serverResponse => {
      this.loaderService.stopLoader();
      const serverResponseCode = serverResponse.responseCode;
      if(serverResponseCode === this.constants.STATUS_SUCCESS){
        this.ngZone.run(()=>this.router.navigate(["confirmation", this.utils.encryptString(this.personalDetailsFormObject.dataCorrectionId)]));
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

}
