<app-header></app-header>

<div class="banner-img-name"></div>

<div class="w100">
    <div class="main">

        <h3 class="mb10">Process Approved Changed Request</h3>

        <h4 style="font-weight:500;padding-bottom:7px;">To proceed with the approved changed request, please enter the passcode</h4>

        <form #codApproveForm="ngForm" (ngSubmit)="codApproveForm.form.valid && validatePasscode()" autocomplete="off" id="" appFocus>
            <div class="half_w">
                <div class="form_box">
                    <label class="fieldlabels">Passcode <span class="medatory">*</span></label>
                    <div class="input_box">
                        <input type="text" name="passcode" hint [(ngModel)]="codApproveFormObject.passcode" #passcode="ngModel" [disabled]="showValidateButton === false" class="effect" required="required" autocomplete="off" tabindex="1" maxlength="15" firstFocus>
                        <span class="tooltip"><span class='tooltipArrow'></span>Enter your passcode.</span>
                        <span class="focus-border"><i></i></span>
                    </div>
                    <span class="alert alert-danger" role="alert" *ngIf="codApproveForm.submitted && passcode.invalid">
                        <span *ngIf="passcode.errors.required">
                            Passcode is required!
                        </span>
                    </span>
                </div>
            </div>

            <!-- <div class="mt15 captcha">
                <ngx-recaptcha2 name="captchaVerified" (ngModel)="codApproveFormObject.captchaVerified" [siteKey]="projectConfig.CAPTCHA_SITE_KEY" (load)="captcha.handleCaptchaLoad(codApproveFormObject)" (success)="captcha.handleCaptchaResponse(codApproveFormObject, $event)" (expire)="captcha.handleCaptchaExpire(codApproveFormObject)" size="normal" hl="en" theme="light" type="image"></ngx-recaptcha2>
                <span class="alert alert-danger" *ngIf="codApproveForm.submitted && codApproveFormObject.captchaVerified === false">
                    Captcha is required!
                </span>
            </div> -->

            <div class="w100">
                <input type="button" (click)="openHomePage()" class="back_btn" value="Back" style="margin-right: 5px;"/>
                <input *ngIf="showValidateButton === true" type="submit" class="action-button custom_btn" value="Validate">
                <input *ngIf="serverResponseCode === constants.STATUS_SUCCESS" type="button" (click)="openApplicationPage()" class="action-button save_btn" value="Continue">
                <input *ngIf="serverResponseCode === constants.COD_PAYMENT_NOT_COMPLETE_ERROR_CODE" type="button" (click)="openCODApplicationViewPage()" class="action-button save_btn" value="Pay Now">
                <input *ngIf="serverResponseCode === constants.COD_APPLICATION_NOT_CONFIRMED_ERROR_CODE" type="button" (click)="openCODConfirmationPage()" class="action-button save_btn" value="Confirm">
            </div>
        </form>

    </div>
</div>

<footer>
    <div class="main-footer">
        <div class="footer_flex">
            <p>Copyright © {{latestYear}} | All rights reserved.</p>
            <img src="assets/images/newworks-logo.png" >
        </div>
   </div>
</footer>
