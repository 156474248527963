import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class Tags {

    public LOGGED_USER_ID = "PASSPORT_LOGGED_USER_ID";
    public LOGGED_USER_NAME = "PASSPORT_LOGGED_USER_NAME";
    public AUTH_TOKEN = "AUTH_TOKEN";
    public KEYCLOAK_TOKEN = "KEYCLOAK_TOKEN";
    public KEYCLOAK_REFRESH_TOKEN = "KEYCLOAK_REFRESH_TOKEN";

    public IS_NOTICE_MODAL_OPEN = "IS_NOTICE_MODAL_OPEN";

    public EMAIL_ID = "emailId";
    public APPLICATION_TOKEN = "APPLICATION_CREST";

    public CAPTCHA_SITE_KEY = "CAPTCHA_SITE_KEY";
    public SERVER_BASE_URL = "SERVER_BASE_URL";
    public APPOINTMENT_APPLICATION_URL = "APPOINTMENT_APPLICATION_URL";
    public TRACK_APPLICATION_URL = "TRACK_APPLICATION_URL";
    public USD_PASSPORT_APPLICATION_URL = "USD_PASSPORT_APPLICATION_URL";
    public OLD_NGN_PASSPORT_APPLICATION_SEARCH_URL = "OLD_NGN_PASSPORT_APPLICATION_SEARCH_URL";
    public OLD_NGN_PASSPORT_APPLICATION_GUARANTOR_URL = "OLD_NGN_PASSPORT_APPLICATION_GUARANTOR_URL";
    public CENTRE_AVAILABILITY_URL = "CENTRE_AVAILABILITY_URL";
}
