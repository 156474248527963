import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { Constants } from '../common/constants';
import { Utils } from '../common/utils';

@Directive({
    selector: '[hint]'
})
export class HintDirective {

    @Output() ngModelChange = new EventEmitter();

    constructor(private utils: Utils, private constants: Constants) { }

    @HostListener('focus', ['$event'])
    public onFocusHandler(event: any): void {
        const fieldName = event.target.name;
        this.utils.showToolTip(fieldName);
    }

    @HostListener('blur', ['$event'])
    public onBlurHandler(event: any): void {
        const fieldName = event.target.name;
        this.utils.hideToolTip(fieldName);

        let fieldValue = event.target.value;

        if((fieldName === "mobileNumber" || fieldName === "phoneNumber") && fieldValue.startsWith("0")){
            fieldValue = fieldValue.substring(1);
            this.ngModelChange.emit(fieldValue);
        }

        if(fieldValue !== undefined && fieldValue !== this.constants.BLANK){
            fieldValue = fieldValue.trim();
            this.ngModelChange.emit(fieldValue);
        }
    }

}