import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxUiLoaderConfig, NgxUiLoaderModule, NgxUiLoaderRouterModule, PB_DIRECTION, SPINNER } from "ngx-ui-loader";
import { TooltipModule, TooltipOptions } from 'ng2-tooltip-directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FlatpickrModule } from 'angularx-flatpickr';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { SimpleModalModule } from 'ngx-simple-modal';
import { NgxBarcodeModule } from 'ngx-barcode';
import { LoaderService } from './common/loader-service';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent } from './footer/footer.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ApplicationAddEditComponent } from './application-add-edit/application-add-edit.component';
import { ApplicationViewComponent } from './application-view/application-view.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { NumberOnlyDirective } from './validators/number-only-directive';
import { SearchApplicationComponent } from './search-application/search-application.component';
import { PaymentComponent } from './payment/payment.component';
import { PaymentSuccessComponent } from './payment-success/payment-success.component';
import { PaymentFailureComponent } from './payment-failure/payment-failure.component';
import { PaymentReceiptComponent } from './payment-receipt/payment-receipt.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { FocusDirective } from './validators/focus-directive';
import { DateOfBirthDirective } from './validators/date-of-birth.directive';
import { FirstFocusDirective } from './validators/first-focus-directive';
import { GuarantorComponent } from './guarantor/guarantor.component';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { CODHomeComponent } from './codhome/codhome.component';
import { CODRequestComponent } from './codrequest/codrequest.component';
import { CODConfirmationComponent } from './codconfirmation/codconfirmation.component';
import { CODApproveComponent } from './codapprove/codapprove.component';
import { CODConfirmationSuccessComponent } from './codconfirmation-success/codconfirmation-success.component';
import { CODRequestViewComponent } from './codrequest-view/codrequest-view.component';
import { CODRequestSearchComponent } from './codrequest-search/codrequest-search.component';
import { AlphaNumericOnlyDirective } from './validators/alpha-numeric-only-directive';
import { DateOfBirthInputDirective } from './validators/date-of-birth-input-directive';
import { HintDirective } from './validators/hint-directive';
import { NameDirective } from './validators/name-directive';
import { AddressDirective } from './validators/address-directive';
import { NameWithNumberDirective } from './validators/name-with-number-directive';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NoticeModalComponent } from './notice-modal/notice-modal.component';
import { NgxDialogsModule } from  'ngx-dialogs';
import { ExceptionModalComponent } from './exception-modal/exception-modal.component';
import { ValidatePhoneDirective } from './validators/validate-phone-directive';
import { InformationModalComponent } from './information-modal/information-modal.component';

const ngxUiLoaderDefaultConfig: NgxUiLoaderConfig = {
  fgsType: SPINNER.ballSpinClockwise, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
  pbColor: "green",
  blur: 10,
  fgsColor: "green",
  masterLoaderId: "master",
};

const defaultTooltipOptions: TooltipOptions = {
  'show-delay': 500
}

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomeComponent,
    FooterComponent,
    PageNotFoundComponent,
    ApplicationAddEditComponent,
    ApplicationViewComponent,
    ConfirmationModalComponent,
    NumberOnlyDirective,
    SearchApplicationComponent,
    PaymentComponent,
    PaymentSuccessComponent,
    PaymentFailureComponent,
    PaymentReceiptComponent,
    FocusDirective,
    DateOfBirthDirective,
    FirstFocusDirective,
    GuarantorComponent,
    CODHomeComponent,
    CODRequestComponent,
    CODConfirmationComponent,
    CODApproveComponent,
    CODConfirmationSuccessComponent,
    CODRequestViewComponent,
    CODRequestSearchComponent,
    AlphaNumericOnlyDirective,
    NameDirective,
    DateOfBirthInputDirective,
    HintDirective,
    AddressDirective,
    NameWithNumberDirective,
    NoticeModalComponent,
    ExceptionModalComponent,
    ValidatePhoneDirective,
    InformationModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderDefaultConfig),
    NgxUiLoaderRouterModule,
    TooltipModule.forRoot(defaultTooltipOptions as TooltipOptions),
    ReactiveFormsModule,
		MatSelectModule,
    MatFormFieldModule,
    NgxMatSelectSearchModule,
    FlatpickrModule.forRoot({dateFormat: "d/m/Y", allowInput: true, clickOpens: false}),
    HttpClientModule,
    SimpleModalModule,
    NgxBarcodeModule,
    NgxCaptchaModule,
    PDFExportModule,
    Ng2TelInputModule,
    NgxDialogsModule
  ],
  providers: [
    LoaderService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
