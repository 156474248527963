<app-header></app-header>

<div class="banner-img-name"></div>

<section class="main">
  <!-- <h2 class="">Application Form</h2> -->
    <div class="info">
        <h3 class="">Please fill the forms to apply for {{utils.fetchPassportTypeLabel(passportTypeFormObject.passportType)}}</h3>
        <p *ngIf="utils.isEmpty(personalDetailsFormObject.referenceNumber) === false" style="color: red; line-height: 20px;" class="blinkingText">Please note down the Reference No. {{personalDetailsFormObject.referenceNumber}}</p>
    </div>

    <div class="form_main">

        <ul id="progressbar">
            <li [id]="applicationHighestStage >= 1 && applicationStage !== 1 ? 'passport-type-tick' : 'passport-type'" [ngClass]="applicationHighestStage >= 1 ? 'active' : ''" [ngStyle]="{'cursor': applicationHighestStage >= 1 ? 'pointer' : 'not-allowed'}"  (click)="applicationHighestStage >= 1 ? openFieldSet(1, $event) : ''"><p>Passport Type</p></li>
            <li [id]="applicationHighestStage >= 2 && applicationStage !== 2 ? 'personal-tick' : 'personal'" [ngClass]="applicationHighestStage >= 2 ? 'active' : ''" [ngStyle]="{'cursor': applicationHighestStage >= 2 ? 'pointer' : 'not-allowed'}" (click)="applicationHighestStage >= 2 ? openFieldSet(2, $event) : ''"><p>Personal Details</p></li>
            <li [id]="applicationHighestStage >= 3 && applicationStage !== 3 ? 'contact-tick' : 'contact'" [ngClass]="applicationHighestStage >= 3 ? 'active' : ''" [ngStyle]="{'cursor': applicationHighestStage >= 4 ? 'pointer' : 'not-allowed'}" (click)="applicationHighestStage >= 3 ? openFieldSet(3, $event) : ''"><p>Contact Information</p></li>
            <li [id]="applicationHighestStage >= 4 && applicationStage !== 4 ? 'kin-tick' : 'kin'" [ngClass]="applicationHighestStage >= 4 ? 'active' : ''" [ngStyle]="{'cursor': applicationHighestStage >= 4 ? 'pointer' : 'not-allowed'}" (click)="applicationHighestStage >= 4 ? openFieldSet(4, $event) : ''"><p>Next of Kin Information</p></li>
        </ul>

        <fieldset *ngIf="applicationStage === 1" class="passport_type">
            <div class="step_info">
                <span>Step 1: Passport Type</span>
            </div>
            <form #passportTypeForm="ngForm" (ngSubmit)="passportTypeForm.form.valid && openPersonalDetailsForm($event)" autocomplete="off" name="passportTypeForm" id="msform" appFocus>

                <div class="grid-one-third">

                    <div class="grid-onethird-box">
                        <!-- This is required for the mobile view -->
                        <details>
                            <summary>Instructions for Applicant </summary>
                            <div class="content">
                                <ul *ngIf="utils.isRenewPassport(passportTypeFormObject.passportType) === false">
                                    <li><span>Check Centre Appointment Availability</span> - <span (click)="openCentreAvailabilityForm()" style="color: #F96922; font-weight: bold; cursor: pointer">Click Here</span></li>
                                    <li><span>Select correct passport type.</span></li>
                                    <li><span>Select age group.</span></li>
                                    <li><span>Select processing state and processing center.</span></li>
                                    <li><span>Select booklet type and validity.</span></li>
                                </ul>

                                <ul *ngIf="utils.isRenewPassport(passportTypeFormObject.passportType) === true">
                                    <li><span>Check Centre Appointment Availability</span> - <span (click)="openCentreAvailabilityForm()" style="color: #F96922; font-weight: bold; cursor: pointer">Click Here</span></li>
                                    <li><span>Enter a valid Passport Number.</span></li>
                                    <li><span>Select correct passport type.</span></li>
                                    <li><span>Select processing state and processing center.</span></li>
                                </ul>
                            </div>
                        </details>

                        <div *ngIf="passportTypeFormObject.applicationType !== constants.COD_APPLICATION" class="passport_box">
                            <div class="radio">
                                <input type="radio" name="applyingFor" [(ngModel)]="passportTypeFormObject.applyingFor" (ngModelChange)="storePassportType(true);" #applyingFor="ngModel" required="required" [value]="constants.FRESH" id="fresh">
                                <label for="fresh">Applying for Fresh passport</label>

                                <input type="radio" name="applyingFor" [(ngModel)]="passportTypeFormObject.applyingFor" (ngModelChange)="storePassportType(true);" #applyingFor="ngModel" required="required" [value]="constants.RENEW" id="renew">
                                <label for="renew">Renew/Re-issue Passport</label>
                            </div>
                            <span class="alert alert-danger" role="alert" *ngIf="passportTypeForm.submitted && applyingFor.invalid" style="margin-top: 5px;">
                                <span *ngIf="applyingFor.errors.required">
                                    This field is required!
                                </span>
                            </span>
                        </div>

                        <div class="grid-two">
                            <div class="form_box">
                                <label class="fieldlabels">Passport Type <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <mat-select name="passportType" [(ngModel)]="passportTypeFormObject.passportType" #passportType="ngModel" (ngModelChange)="storeOfficialPassportOfficeDetails(true)" required="required" class="effect" tabindex="1">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="passportSelectService.selectFilterControl" noEntriesFoundLabel="No data found" placeholderLabel="Search" autocomplete="off"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let passportType of passportSelectService.filteredOptions | async" [value]="passportType.value">{{passportType.text}}</mat-option>
                                    </mat-select>
                                    <span class="tooltip"><span class='tooltipArrow'></span>Please choose passport type field.</span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="(passportTypeFormObject.ispassportTypeBlank || passportTypeForm.submitted) && passportType.invalid">
                                    <span *ngIf="passportType.errors.required">
                                        Passport Type is required!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box" *ngIf="utils.isRenewPassport(passportTypeFormObject.passportType) === true">
                                <label class="fieldlabels">{{changeTypeLabel}} <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <mat-select name="changeType" [(ngModel)]="passportTypeFormObject.changeType" #changeType="ngModel" (ngModelChange)="setDefaultProcessingStateOffice(true); fetchPassportFeeDetails();" required="required" class="effect" tabindex="2">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="changeTypeSelectService.selectFilterControl" noEntriesFoundLabel="No data found" placeholderLabel="Search" autocomplete="off"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let changeType of changeTypeSelectService.filteredOptions | async" [value]="changeType.value">{{changeType.text}}</mat-option>
                                    </mat-select>
                                    <span class="tooltip"><span class='tooltipArrow'></span>Please choose passport type field.</span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="passportTypeForm.submitted && changeType.invalid">
                                    <span *ngIf="changeType.errors.required">
                                        {{changeTypeLabel}} is required!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box" *ngIf="utils.isRenewPassport(passportTypeFormObject.passportType) === true">
                                <label class="fieldlabels">Passport Number <span *ngIf="passportTypeFormObject.changeType !== constants.LOST_REPLACEMENT_REQUEST" class="medatory">*</span></label>
                                <div class="input_box">
                                    <input type="text" name="passportNumber" hint alphaNumericOnly class="effect" [required]="passportTypeFormObject.changeType !== constants.LOST_REPLACEMENT_REQUEST" [disabled]="passportTypeFormObject.applicationType === constants.COD_APPLICATION" autocomplete="off" pattern="^[A-Za-z].*$" (focusout)="validatePassportNumber();" (input)="passportTypeFormObject.passportNumber = passportTypeFormObject.passportNumber.toUpperCase()" [(ngModel)]="passportTypeFormObject.passportNumber" #passportNumber="ngModel" tabindex="3" minlength="9" maxlength="9">
                                    <span class="tooltip"><span class='tooltipArrow'></span>Enter your passport number as it appears on your passport. The passport number may contain numbers and/or characters. Please closely distinguish between the number zero and the letter O. The passport number is required to complete the application or to check the status of your application.</span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="passportTypeForm.submitted && passportNumber.invalid">
                                    <span *ngIf="passportNumber.errors?.required">
                                        Passport Number is required!
                                    </span>
                                    <span *ngIf="passportNumber.errors?.minlength">
                                        Passport Number should be 9 digits!
                                    </span>
                                    <span *ngIf="!passportNumber.errors?.minlength && passportNumber.errors?.pattern">
                                        Passport Number should start with alphabet!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box">
                                <label class="fieldlabels">Age Group <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <mat-select name="ageGroup" [(ngModel)]="passportTypeFormObject.ageGroup" #ageGroup="ngModel" (ngModelChange)="storeBookletTypeList(true); fetchPassportFeeDetails();" required="required" [disabled]="passportTypeFormObject.applicationType === constants.COD_APPLICATION" class="effect" tabindex="4">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="ageGroupSelectService.selectFilterControl" noEntriesFoundLabel="No data found" placeholderLabel="Search" autocomplete="off"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let ageGroup of ageGroupSelectService.filteredOptions | async" [value]="ageGroup.value">{{ageGroup.text}}</mat-option>
                                    </mat-select>
                                    <span class="tooltip"><span class='tooltipArrow'></span>Choose the country of citizenship, as it appears on your passport. The country of citizenship is required to complete the application.</span>
                                    <span *ngIf="passportTypeFormObject.applicationType === constants.PASSPORT_APPLICATION" class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="passportTypeForm.submitted && ageGroup.invalid">
                                    <span *ngIf="ageGroup.errors.required">
                                        Age Group is required!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box" *ngIf="passportTypeFormObject.passportType !== this.constants.OFFICIAL_PASSPORT">
                                <label class="fieldlabels">Processing State <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <mat-select name="processingState" [(ngModel)]="passportTypeFormObject.processingState" #processingState="ngModel" (ngModelChange)="storeStateProcessingOfficeList(true); storeBookletTypeList(true); fetchPassportFeeDetails();" required="required" class="effect" tabindex="5" [disabled]="disableProcessingStateOffice">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="stateSelectService.selectFilterControl" noEntriesFoundLabel="No data found" placeholderLabel="Search" autocomplete="off"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let state of stateSelectService.filteredOptions | async" [value]="state.value">{{state.text}}</mat-option>
                                    </mat-select>
                                    <span class="tooltip"><span class='tooltipArrow'></span>Choose the country of citizenship, as it appears on your passport. The country of citizenship is required to complete the application.</span>
                                    <span *ngIf="disableProcessingStateOffice === false" class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="passportTypeForm.submitted && processingState.invalid">
                                    <span *ngIf="processingState.errors.required">
                                        Processing State is required!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box" *ngIf="passportTypeFormObject.passportType !== this.constants.OFFICIAL_PASSPORT">
                                <label class="fieldlabels">Processing Office <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <mat-select name="processingOffice" [(ngModel)]="passportTypeFormObject.processingOffice" (ngModelChange)="storeBookletTypeList(true); fetchPassportFeeDetails();" #processingOffice="ngModel" required="required" class="effect" tabindex="6" [disabled]="disableProcessingStateOffice">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="processingOfficeSelectService.selectFilterControl" noEntriesFoundLabel="No data found" placeholderLabel="Search" autocomplete="off"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let processingOffice of processingOfficeSelectService.filteredOptions | async" [value]="processingOffice.value">{{processingOffice.text}}</mat-option>
                                    </mat-select>
                                    <span class="tooltip"><span class='tooltipArrow'></span>Choose the country of citizenship, as it appears on your passport. The country of citizenship is required to complete the application.</span>
                                    <span *ngIf="disableProcessingStateOffice === false" class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="passportTypeForm.submitted && processingOffice.invalid">
                                    <span *ngIf="processingOffice.errors.required">
                                        Processing Office is required!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box">
                                <label class="fieldlabels">Booklet Type <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <mat-select name="bookletType" [(ngModel)]="passportTypeFormObject.bookletType" (ngModelChange)="storeValidityList(true); fetchPassportFeeDetails();" #bookletType="ngModel" required="required" class="effect" tabindex="7">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="bookletSelectService.selectFilterControl" noEntriesFoundLabel="No data found" placeholderLabel="Search" autocomplete="off"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let booklet of bookletSelectService.filteredOptions | async" [value]="booklet.value">{{booklet.text}}</mat-option>
                                    </mat-select>
                                    <span class="tooltip"><span class='tooltipArrow'></span>Please choose booklet type</span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="passportTypeForm.submitted && bookletType.invalid">
                                    <span *ngIf="bookletType.errors.required">
                                        Booklet Type is required!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box">
                                <label class="fieldlabels">Validity <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <mat-select name="passportValidity" [(ngModel)]="passportTypeFormObject.passportValidity" (ngModelChange)="fetchPassportFeeDetails();" #passportValidity="ngModel" required="required" class="effect" tabindex="8">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="validitySelectService.selectFilterControl" noEntriesFoundLabel="No data found" placeholderLabel="Search" autocomplete="off"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let validity of validitySelectService.filteredOptions | async" [value]="validity.value">{{validity.text}}</mat-option>
                                    </mat-select>
                                    <span class="tooltip"><span class='tooltipArrow'></span>Please choose validity field.</span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="passportTypeForm.submitted && passportValidity.invalid">
                                    <span *ngIf="passportValidity.errors.required">
                                        Validity is required!
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="grid-onethird-box">
                        <div *ngIf="isValidDetails(passportTypeFormObject , ['passportType', 'ageGroup', 'processingOffice', 'bookletType', 'passportValidity']) === false" class="instruction_box">
                            <h4>Instructions</h4>
                            <ul *ngIf="utils.isRenewPassport(passportTypeFormObject.passportType) === false">
                                <li><span>Check Centre Appointment Availability</span> - <span (click)="openCentreAvailabilityForm()" style="color: #F96922; font-weight: bold; cursor: pointer">Click Here</span></li>
                                <li><span>Select correct passport type.</span></li>
                                <li><span>Select age group.</span></li>
                                <li><span>Select processing state and processing center.</span></li>
                                <li><span>Select booklet type and validity.</span></li>
                            </ul>

                            <ul *ngIf="utils.isRenewPassport(passportTypeFormObject.passportType) === true">
                                <li><span>Check Centre Appointment Availability</span> - <span (click)="openCentreAvailabilityForm()" style="color: #F96922; font-weight: bold; cursor: pointer">Click Here</span></li>
                                <li><span>Enter a valid Passport Number.</span></li>
                                <li><span>Select correct passport type.</span></li>
                                <li><span>Select processing state and processing center.</span></li>
                            </ul>
                        </div>

                        <div *ngIf="isValidDetails(passportTypeFormObject , ['passportType', 'ageGroup', 'processingOffice', 'bookletType', 'passportValidity']) === true" class="grid-box">
                            <div class="fee">
                                <div>Passport Booklet Fee : <span>{{feeDetailsObject.currencySymbol}} {{feeDetailsObject.bookletFee | number : '1.2' : 'en-US'}}</span></div>
                                <div *ngIf="feeDetailsObject.addressVerificationFee > 0">Address Verification Fee : <span>{{feeDetailsObject.currencySymbol}} {{feeDetailsObject.addressVerificationFee | number : '1.2' : 'en-US'}}</span></div>
                                <!-- <div *ngIf="feeDetailsObject.addressVerificationServiceCharge > 0">Address Verification Service Charges : <span>{{feeDetailsObject.currencySymbol}} {{feeDetailsObject.addressVerificationServiceCharge}}</span></div> -->
                                <div><b>Total Fee :</b> <span><b>{{feeDetailsObject.currencySymbol}} {{feeDetailsObject.totalFee | number : '1.2' : 'en-US'}}</b></span></div>
                            </div>
                        </div>
                    </div>

                </div>

                <br/>

                <div *ngIf="isSkipCaptcha === false" class="captcha">
                    <!-- <ngx-recaptcha2 name="captchaVerified" (ngModel)="passportTypeFormObject.captchaVerified" [siteKey]="projectConfig.CAPTCHA_SITE_KEY" (load)="captcha.handleCaptchaLoad(passportTypeFormObject)" (success)="captcha.handleCaptchaResponse(passportTypeFormObject, $event)" (expire)="captcha.handleCaptchaExpire(passportTypeFormObject)" size="normal" hl="en" theme="light" type="image"></ngx-recaptcha2>
                    <span class="alert alert-danger" *ngIf="passportTypeForm.submitted && passportTypeFormObject.captchaVerified === false">
                        Captcha is required!
                    </span> -->
                </div>

                <input type="button" (click)="gui.openHomePage()" class="action-button-previous back_btn" value="Back" />
                <input type="submit" [value]="utils.isEmpty(personalDetailsFormObject.referenceNumber) ? 'Continue' : 'Save & Continue'" class="action-button custom_btn" [disabled]="isDisablePassportFormSubmitButton"/>
            </form>
        </fieldset>

        <fieldset *ngIf="applicationStage === 2">
            <div class="step_info">
                <span>Step 2: Personal Details</span>
            </div>
            <form #personalDetailsForm="ngForm" (ngSubmit)="personalDetailsForm.form.valid && openContactInformationForm($event)" autocomplete="off" name="personalDetailsForm" id="msform" [validateDateOfBirth]="['dateOfBirthDay', 'dateOfBirthMonth', 'dateOfBirthYear', passportTypeFormObject.ageGroup, constants.TRUE]" appFocus>

                <div class="grid-one-third">

                    <div class="grid-onethird-box">
                        <details>
                            <summary>Instructions for Applicant </summary>
                            <div class="content">
                                <ul>
                                    <li><span>Please note that the information provided in your application must match with your NIN.</span></li>
                                    <li><span>You are required to verify your application details before making payment.</span></li>
                                    <li><span>You have an option to save your application if you intend to make payment at a later date.</span></li>
                                    <li><span>Date of Birth should be in the format dd/mm/yyyy.</span></li>
                                    <li><span>NIN is expected to be exactly 11 digits.</span></li>
                                    <li><span>Enter a valid email address in the format.</span></li>
                                </ul>
                            </div>
                        </details>
                        <div class="grid-two">

                            <div class="form_box">
                                <label class="fieldlabels">Title <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <select name="title" class="effect" required="required" [disabled]="passportTypeFormObject.applicationType === constants.COD_APPLICATION" [(ngModel)]="personalDetailsFormObject.title" #title="ngModel" tabindex="1" (ngModelChange)="setGender();">
                                        <option value="">Choose Option</option>
                                        <option [value]="constants.MR">Mr.</option>
                                        <option [value]="constants.MRS">Mrs.</option>
                                        <option [value]="constants.MISS">Miss</option>
                                    </select>
                                    <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                                    <span class="tooltip"><span class='tooltipArrow'></span></span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="personalDetailsForm.submitted && title.invalid">
                                    <span *ngIf="title.errors.required">
                                        Title is required!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box ">
                                <label class="fieldlabels">Surname <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <input type="text" name="lastName" hint name-directive class="effect" required="required" [disabled]="passportTypeFormObject.applicationType === constants.COD_APPLICATION" autocomplete="off" [(ngModel)]="personalDetailsFormObject.lastName" #lastName="ngModel" tabindex="2" minlength="2" maxlength="30" pattern="^[A-Za-z].*[A-Za-z]$">
                                    <span class="tooltip"><span class='tooltipArrow'></span>Please fill Surname as stated in passport.</span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="personalDetailsForm.submitted && lastName.invalid">
                                    <span *ngIf="lastName.errors.required">
                                        Surname is required!
                                    </span>
                                    <span *ngIf="lastName.errors?.minlength">
                                        Surname should minimum 2 characters!
                                    </span>
                                    <span *ngIf="!lastName.errors?.minlength && lastName.errors.pattern">
                                        Surname should start and end with alphabet!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box ">
                                <label class="fieldlabels">First Name <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <input type="text" name="firstName" hint name-with-number-directive class="effect" required="required" [disabled]="passportTypeFormObject.applicationType === constants.COD_APPLICATION" autocomplete="off" [(ngModel)]="personalDetailsFormObject.firstName" #firstName="ngModel" tabindex="3" minlength="2" maxlength="30" pattern="^[A-Za-z].*[A-Za-z0-9]$">
                                    <span class="tooltip"><span class='tooltipArrow'></span>First names (given names) as stated in passport.</span>
                                    <span class="focus-border"><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="personalDetailsForm.submitted && firstName.invalid">
                                    <span *ngIf="firstName.errors.required">
                                        First Name is required!
                                    </span>
                                    <span *ngIf="firstName.errors?.minlength">
                                        First Name should minimum 2 characters!
                                    </span>
                                    <span *ngIf="!firstName.errors?.minlength && firstName.errors.pattern">
                                        First Name should start with alphabet and end with alphabet or number!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box ">
                                <label class="fieldlabels">Middle Name (Optional)</label>
                                <div class="input_box">
                                    <input type="text" name="middleName" hint name-directive class="effect" autocomplete="off" [disabled]="passportTypeFormObject.applicationType === constants.COD_APPLICATION" [(ngModel)]="personalDetailsFormObject.middleName" #middleName="ngModel" tabindex="4" minlength="2" maxlength="30" pattern="^[A-Za-z].*[A-Za-z]$">
                                    <span class="tooltip"><span class='tooltipArrow'></span>In the middle name field, enter your middle name as the  appears on your passport. The  Middle name is optional .</span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="personalDetailsForm.submitted && middleName.invalid">
                                    <span *ngIf="middleName.errors?.minlength">
                                        Middle Name should minimum 2 characters!
                                    </span>
                                    <span *ngIf="!middleName.errors?.minlength && middleName.errors.pattern">
                                        Middle Name should start and end with alphabet!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box ">
                                <label class="fieldlabels">Date of Birth (dd/mm/yyyy) <span class="medatory">*</span></label>
                                <div class="grid-date">
                                    <div class="input_box">
                                        <input type="text" name="dateOfBirthDay" hint numberOnly [(ngModel)]="personalDetailsFormObject.dateOfBirthDay" #dateOfBirthDay="ngModel" class="effect" required="required" autocomplete="off" tabindex="5" maxlength="2" [disabled]="passportTypeFormObject.applicationType === constants.COD_APPLICATION" placeholder="DD">
                                        <span class="tooltip"><span class='tooltipArrow'></span>The date of birth is required to complete the application or to check the status of your application. </span>
                                        <span class="focus-border "><i></i></span>
                                    </div>

                                    <div class="input_box">
                                        <select name="dateOfBirthMonth" [(ngModel)]="personalDetailsFormObject.dateOfBirthMonth" #dateOfBirthMonth="ngModel" class="effect" required="required" autocomplete="off" tabindex="6" [disabled]="passportTypeFormObject.applicationType === constants.COD_APPLICATION">
                                            <option *ngFor="let object of monthsList;" [value]="object.value">{{object.text}}</option>
                                        </select>
                                        <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                                        <span class="tooltip"><span class='tooltipArrow'></span></span>
                                        <span class="focus-border "><i></i></span>
                                    </div>

                                    <div class="input_box">
                                        <input type="text" name="dateOfBirthYear" hint numberOnly [(ngModel)]="personalDetailsFormObject.dateOfBirthYear" #dateOfBirthYear="ngModel" class="effect" required="required" autocomplete="off" tabindex="7" minlength="4" maxlength="4" [disabled]="passportTypeFormObject.applicationType === constants.COD_APPLICATION" placeholder="YYYY">
                                        <span class="tooltip"><span class='tooltipArrow'></span>The date of birth is required to complete the application or to check the status of your application. </span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="personalDetailsForm.submitted && (dateOfBirthDay.invalid || dateOfBirthMonth.invalid || dateOfBirthYear.invalid)">
                                    <span *ngIf="(dateOfBirthDay.errors?.required || dateOfBirthMonth.errors?.required || dateOfBirthYear.errors?.required)">
                                        Date of Birth is required!
                                    </span>
                                    <span *ngIf="dateOfBirthYear.errors?.minlength">
                                        Date of Birth's year should be 4 digits!
                                    </span>
                                    <span *ngIf="!dateOfBirthYear.errors?.minlength && dateOfBirthDay.errors?.hasError">
                                        {{dateOfBirthDay.errors.errorMessage}}
                                    </span>
                                </span>
                            </div>

                            <div class="form_box">
                                <label class="fieldlabels">Place of Birth <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <input type="text" name="placeOfBirth" hint class="effect" required="required" autocomplete="off" [disabled]="passportTypeFormObject.applicationType === constants.COD_APPLICATION" [(ngModel)]="personalDetailsFormObject.placeOfBirth" #placeOfBirth="ngModel" tabindex="8" minlength="2" maxlength="20" pattern="^[A-Za-z].*[A-Za-z]$">
                                    <span class="tooltip"><span class='tooltipArrow'></span>Enter the city, town, village in which you were born is required to complete the application. If you do not know the city in which you were born, enter UNKNOWN.</span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="personalDetailsForm.submitted && placeOfBirth.invalid">
                                    <span *ngIf="placeOfBirth.errors.required">
                                        Place of Birth is required!
                                    </span>
                                    <span *ngIf="placeOfBirth.errors?.minlength">
                                        Place of Birth should minimum 2 characters!
                                    </span>
                                    <span *ngIf="!placeOfBirth.errors?.minlength && placeOfBirth.errors.pattern">
                                        Place of Birth should start and end with alphabet!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box">
                                <label class="fieldlabels">Gender <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <select name="gender" class="effect" required="required" [disabled]="passportTypeFormObject.applicationType === constants.COD_APPLICATION" [(ngModel)]="personalDetailsFormObject.gender" #gender="ngModel" tabindex="9">
                                        <option value="">Choose Option</option>
                                        <option [value]="constants.MALE">Male</option>
                                        <option [value]="constants.FEMALE">Female</option>
                                    </select>
                                    <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                                    <span class="tooltip"><span class='tooltipArrow'></span>Choose your gender, either male or female or other. The gender is required to complete the application.</span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="personalDetailsForm.submitted && gender.invalid">
                                    <span *ngIf="gender.errors.required">
                                        Gender is required!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box">
                                <label class="fieldlabels">Marital status <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <select name="maritalStatus" class="effect" required="required" [(ngModel)]="personalDetailsFormObject.maritalStatus" #maritalStatus="ngModel" tabindex="10">
                                        <option value="">Choose Option</option>
                                        <option [value]="constants.SINGLE">Single</option>
                                        <option [value]="constants.MARRIED">Married</option>
                                        <option [value]="constants.WIDOWED">Widowed</option>
                                        <option [value]="constants.DIVORCED">Divorced</option>
                                    </select>
                                    <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                                    <span class="tooltip"><span class='tooltipArrow'></span></span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="personalDetailsForm.submitted && maritalStatus.invalid">
                                    <span *ngIf="maritalStatus.errors.required">
                                        Marital status is required!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box">
                                <label class="fieldlabels">Occupation</label>
                                <div class="input_box">
                                    <input type="text" name="occupation" hint name-directive class="effect" autocomplete="off" [(ngModel)]="personalDetailsFormObject.occupation" #occupation="ngModel" tabindex="11" maxlength="50">
                                    <span class="tooltip"><span class='tooltipArrow'></span>Enter your occupation.</span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="personalDetailsForm.submitted && occupation.invalid">
                                    <span *ngIf="occupation.errors.required">
                                        Occupation is required!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box">
                                <label class="fieldlabels">Maiden Name</label>
                                <div class="input_box">
                                    <input type="text" name="motherMaidenName" hint name-directive class="effect" autocomplete="off" [(ngModel)]="personalDetailsFormObject.motherMaidenName" #motherMaidenName="ngModel" tabindex="12" minlength="2" maxlength="50" pattern="^[A-Za-z].*[A-Za-z]$">
                                    <span class="tooltip"><span class='tooltipArrow'></span>Enter your maiden Name.</span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="personalDetailsForm.submitted && motherMaidenName.invalid">
                                    <span *ngIf="motherMaidenName.errors.required">
                                        Maiden Name is required!
                                    </span>
                                    <span *ngIf="motherMaidenName.errors?.minlength">
                                        Maiden Name should minimum 2 characters!
                                    </span>
                                    <span *ngIf="!motherMaidenName.errors?.minlength && motherMaidenName.errors.pattern">
                                        Maiden Name should start and end with alphabet!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box">
                                <label class="fieldlabels">National Identification Number (NIN) <span *ngIf="isEnhancedProcessingCenter === true" class="medatory">*</span></label>
                                <div class="input_box">
                                    <input type="text" name="ninNumber" hint numberOnly class="effect" [required]="isEnhancedProcessingCenter" autocomplete="off" [(ngModel)]="personalDetailsFormObject.ninNumber" #ninNumber="ngModel" tabindex="13" minlength="11" maxlength="11">
                                    <span class="tooltip"><span class='tooltipArrow'></span>Enter your National Identification Number.</span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="personalDetailsForm.submitted && ninNumber.invalid">
                                    <span *ngIf="ninNumber.errors.required">
                                        National Identification Number (NIN) is required!
                                    </span>
                                    <span *ngIf="ninNumber.errors?.minlength">
                                        National Identification Number (NIN) should be 11 digits!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box">
                                <label class="fieldlabels">Email <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <input type="text" name="emailId" hint class="effect" required="required" autocomplete="off" pattern="^[a-zA-Z0-9.-_]{1,}@[a-zA-Z0-9.-]{2,}[.]{1}[a-zA-Z0-9]{2,}$" [(ngModel)]="personalDetailsFormObject.emailId" #emailId="ngModel" tabindex="14" maxlength="100">
                                    <span class="tooltip"><span class='tooltipArrow'></span>Enter your email.</span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="personalDetailsForm.submitted && emailId.invalid">
                                    <span *ngIf="emailId.errors.required">
                                        Email is required!
                                    </span>
                                    <span *ngIf="emailId.errors.pattern">
                                        Email should be valid email!
                                    </span>
                                </span>
                            </div>
                        </div>

                    </div>

                    <div class="grid-onethird-box">
                        <div class="instruction_box">
                            <h4>Instructions</h4>
                            <ul>
                                <li><span>Please note that the information provided in your application must match with your NIN.</span></li>
                                <li><span>You are required to verify your application details before making payment.</span></li>
                                <li><span>You have an option to save your application if you intend to make payment at a later date.</span></li>
                                <li><span>Date of Birth should be in the format dd/mm/yyyy.</span></li>
                                <li><span>NIN is expected to be exactly 11 digits.</span></li>
                                <li><span>Enter a valid email address in the format.</span></li>
                            </ul>
                        </div>

                    </div>

                </div>

                <br />

                <input type="button" (click)="openPreviousFieldSet()" class="action-button-previous back_btn" value="Previous Page" />
                <input type="submit" (click)="setShallExitApplication(true)" class="save_btn" value="Save & Exit" />
                <input type="submit" (click)="setShallExitApplication(false)" class="action-button custom_btn" value="Continue" />

            </form>
        </fieldset>

        <fieldset *ngIf="applicationStage === 3">
            <div class="step_info">
                <span>Step 3: Contact Information</span>
            </div>

            <form #contactInformationForm="ngForm" (ngSubmit)="contactInformationForm.form.valid && openKinInformationForm($event)" autocomplete="off" name="contactInformationForm" id="msform" appFocus>

                <div class="grid-one-third">

                    <div class="grid-onethird-box">
                        <details>
                            <summary>Instructions for Applicant </summary>
                            <div class="content">
                                <ul>
                                    <li><span>Enter a valid phone number in the format +234-01234567891.</span></li>
                                    <li><span>You are required to verify your application details before making payment.</span></li>
                                </ul>

                            </div>
                        </details>
                        <div class="grid-two">

                            <div class="form_box">
                                <label class="fieldlabels">Home Town / Village <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <input type="text" name="contactCity" hint name-directive class="effect" required="required" autocomplete="off" [(ngModel)]="contactInformationFormObject.contactCity" #contactCity="ngModel" tabindex="1" minlength="2" maxlength="40" pattern="^[A-Za-z].*[A-Za-z]$">
                                    <span class="tooltip"><span class='tooltipArrow'></span>Enter your home town.</span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="contactInformationForm.submitted && contactCity.invalid">
                                    <span *ngIf="contactCity.errors.required">
                                        Home Town is required!
                                    </span>
                                    <span *ngIf="contactCity.errors?.minlength">
                                        Home Town should minimum 2 characters!
                                    </span>
                                    <span *ngIf="!contactCity.errors?.minlength && contactCity.errors.pattern">
                                        Home Town should start and end with alphabet!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box">
                                <label class="fieldlabels">State of Origin <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <mat-select name="contactState" hint [(ngModel)]="contactInformationFormObject.contactState" #contactState="ngModel" class="effect" required="required" tabindex="2">
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="stateSelectService.selectFilterControl" noEntriesFoundLabel="No data found" placeholderLabel="Search" autocomplete="off"></ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let state of stateSelectService.filteredOptions | async" [value]="state.value">{{state.text}}</mat-option>
                                    </mat-select>
                                    <span class="tooltip"><span class='tooltipArrow'></span>Choose state of origin</span>
                                    <span class="focus-border"><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="contactInformationForm.submitted && contactState.invalid">
                                    <span *ngIf="contactState.errors.required">
                                        State of Origin is required!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box">
                                <label class="fieldlabels">Contact Number <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <input type="text" name="mobileNumber" hint numberOnly [validate-phone]="['mobileNumber', 'Contact Number']" [(ngModel)]="contactInformationFormObject.mobileNumber" #mobileNumber="ngModel" class="focus_border" required="required" autocomplete="off" tabindex="3" minlength="8" maxlength="11" ng2TelInput [ng2TelInputOptions]="ng2TelInputOptions" (intlTelInputObject)="utils.setMobileNumberFields($event.s, contactInformationFormObject, ['mobileNumberCountryISOCode', 'mobileNumberDialCode'])">
                                    <span class="tooltip"><span class='tooltipArrow'></span>Please write the contact number.</span>
                                    <span class="focus-border"><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="contactInformationForm.submitted && mobileNumber.invalid">
                                    <span *ngIf="mobileNumber.errors.required">
                                        Contact Number is required!
                                    </span>
                                    <span *ngIf="mobileNumber.errors?.minlength">
                                        Contact Number should minimum 8 digits!
                                    </span>
                                    <span *ngIf="!mobileNumber.errors?.minlength && mobileNumber.errors?.hasError">
                                        {{mobileNumber.errors.errorMessage}}
                                    </span>
                                </span>
                            </div>

                            <div class="form_box">
                                <label class="fieldlabels">Additional Number</label>
                                <div class="input_box">
                                    <input type="text" name="phoneNumber" hint numberOnly [validate-phone]="['phoneNumber', 'Additional Number']" [(ngModel)]="contactInformationFormObject.phoneNumber" #phoneNumber="ngModel" class="focus_border" autocomplete="off" tabindex="4" minlength="8" maxlength="11" ng2TelInput [ng2TelInputOptions]="ng2TelInputOptionsWithDropdown" (countryChange)="utils.setMobileNumberFields($event, contactInformationFormObject, ['phoneNumberCountryISOCode', 'phoneNumberDialCode'])" (intlTelInputObject)="utils.setMobileNumberFields($event.s, contactInformationFormObject, ['phoneNumberCountryISOCode', 'phoneNumberDialCode'])">
                                    <span class="tooltip"><span class='tooltipArrow'></span>Please write the additional number.</span>
                                    <span class="focus-border"><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="contactInformationForm.submitted && phoneNumber.invalid">
                                    <span *ngIf="phoneNumber.errors.required">
                                        Additional Number is required!
                                    </span>
                                    <span *ngIf="phoneNumber.errors?.minlength">
                                        Additional Number should minimum 8 digits!
                                    </span>
                                    <span *ngIf="!phoneNumber.errors?.minlength && phoneNumber.errors?.hasError">
                                        {{phoneNumber.errors.errorMessage}}
                                    </span>
                                </span>
                            </div>

                        </div>

                        <h4 class="seprate">Permanent Address in Nigeria</h4>

                        <div class="grid-two">

                            <div class="grid-two-box">
                                <div class="form_box h_txt">
                                    <label class="fieldlabels">Address <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <textarea name="addressLine1" hint address-directive class="effect" required="required" autocomplete="off" [(ngModel)]="contactInformationFormObject.addressLine1" #addressLine1="ngModel" tabindex="5" minlength="10" maxlength="450"></textarea>
                                        <span class="tooltip"><span class='tooltipArrow'></span>Enter your permanent addess in Nigeria</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger" role="alert" *ngIf="contactInformationForm.submitted && addressLine1.invalid">
                                        <span *ngIf="addressLine1.errors.required">
                                            Address is required!
                                        </span>
                                        <span *ngIf="addressLine1.errors?.minlength">
                                            Address should minimum 10 characters!
                                        </span>
                                        <!-- <span *ngIf="!addressLine1.errors?.minlength && addressLine1.errors.pattern">
                                            Address should start and end with alphabet!
                                        </span> -->
                                    </span>
                                </div>
                            </div>

                            <div class="grid-two-box">
                                <div class="form_box">
                                    <label class="fieldlabels">State <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <mat-select name="addressState" hint [(ngModel)]="contactInformationFormObject.addressState" #addressState="ngModel" (ngModelChange)="fetchStateLGA(contactInformationFormObject, 'addressState', contactInformationFormLGASelectService, 'addressLGA', true);" class="effect" required="required" tabindex="6">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="stateSelectService.selectFilterControl" noEntriesFoundLabel="No data found" placeholderLabel="Search" autocomplete="off"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let state of stateSelectService.filteredOptions | async" [value]="state.value">{{state.text}}</mat-option>
                                        </mat-select>
                                        <span class="tooltip"><span class='tooltipArrow'></span>Choose state</span>
                                        <span class="focus-border"><i></i></span>
                                    </div>
                                    <span class="alert alert-danger" role="alert" *ngIf="contactInformationForm.submitted && addressState.invalid">
                                        <span *ngIf="addressState.errors.required">
                                            State is required!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box mt15">
                                    <label class="fieldlabels">LGA <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <mat-select name="addressLGA" hint class="effect" required="required" [(ngModel)]="contactInformationFormObject.addressLGA" #addressLGA="ngModel" tabindex="7">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="contactInformationFormLGASelectService.selectFilterControl" noEntriesFoundLabel="No data found" placeholderLabel="Search" autocomplete="off"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let lga of contactInformationFormLGASelectService.filteredOptions | async" [value]="lga.value">{{lga.text}}</mat-option>
                                        </mat-select>
                                        <span class="tooltip"><span class='tooltipArrow'></span>Choose LGA</span>
                                        <span class="focus-border"><i></i></span>
                                    </div>
                                    <span class="alert alert-danger" role="alert" *ngIf="contactInformationForm.submitted && addressLGA.invalid">
                                        <span *ngIf="addressLGA.errors.required">
                                            LGA is required!
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="grid-two mt15">
                            <div class="form_box">
                                <label class="fieldlabels">City / Town <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <input type="text" name="addressCity" hint class="effect" required="required" autocomplete="off" [(ngModel)]="contactInformationFormObject.addressCity" #addressCity="ngModel" tabindex="8" minlength="2" maxlength="60" pattern="^[A-Za-z].*[A-Za-z]$">
                                    <span class="tooltip"><span class='tooltipArrow'></span>Enter your city.</span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="contactInformationForm.submitted && addressCity.invalid">
                                    <span *ngIf="addressCity.errors.required">
                                        City / Town is required!
                                    </span>
                                    <span *ngIf="addressCity.errors?.minlength">
                                        City / Town should minimum 2 characters!
                                    </span>
                                    <span *ngIf="!addressCity.errors?.minlength && addressCity.errors.pattern">
                                        City / Town should start and end with alphabet!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box">
                                <label class="fieldlabels">Postal Code</label>
                                <div class="input_box">
                                    <input type="text" name="addressPostalCode" hint numberOnly class="effect" autocomplete="off" [(ngModel)]="contactInformationFormObject.addressPostalCode" #addressPostalCode="ngModel" tabindex="10" minlength="6" maxlength="6">
                                    <span class="tooltip"><span class='tooltipArrow'></span>Enter your postal code</span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="contactInformationForm.submitted && addressPostalCode.invalid">
                                    <span *ngIf="addressPostalCode.errors.required">
                                        Postal Code is required!
                                    </span>
                                    <span *ngIf="addressPostalCode.errors?.minlength">
                                        Postal Code should minimum 6 digits!
                                    </span>
                                </span>
                            </div>

                        </div>

                        <h4 class="seprate">Current Address in Nigeria</h4>

                        <div>
                            <input style="width: auto; height: auto; margin: 15px 8px 15px 0;" type="checkbox" name="isCurrentAddressSame" [(ngModel)]="contactInformationFormObject.isCurrentAddressSame" #isCurrentAddressSame="ngModel" (ngModelChange)="setCurrentAsPermanentAddress(false);" tabindex="11">
                            <span style="padding:0 3px;">Same as permanent address</span>
                        </div>

                        <div *ngIf="contactInformationFormObject.isCurrentAddressSame === false">

                            <div class="grid-two">

                                <div class="grid-two-box">
                                    <div class="form_box h_txt">
                                        <label class="fieldlabels">Address <span class="medatory">*</span></label>
                                        <div class="input_box">
                                            <textarea name="currentAddressLine1" hint address-directive class="effect" required="required" autocomplete="off" [(ngModel)]="contactInformationFormObject.currentAddressLine1" #currentAddressLine1="ngModel" tabindex="12" minlength="10" maxlength="450"></textarea>
                                            <span class="tooltip"><span class='tooltipArrow'></span>Enter your current addess in Nigeria</span>
                                            <span class="focus-border "><i></i></span>
                                        </div>
                                        <span class="alert alert-danger" role="alert" *ngIf="contactInformationForm.submitted && currentAddressLine1.invalid">
                                            <span *ngIf="currentAddressLine1.errors.required">
                                                Address is required!
                                            </span>
                                            <span *ngIf="currentAddressLine1.errors?.minlength">
                                                Address should minimum 10 characters!
                                            </span>
                                            <!-- <span *ngIf="!currentAddressLine1.errors?.minlength && currentAddressLine1.errors.pattern">
                                                Address should start and end with alphabet!
                                            </span> -->
                                        </span>
                                    </div>
                                </div>

                                <div class="grid-two-box">
                                    <div class="form_box">
                                        <label class="fieldlabels">State <span class="medatory">*</span></label>
                                        <div class="input_box">
                                            <mat-select name="currentAddressState" hint [(ngModel)]="contactInformationFormObject.currentAddressState" #currentAddressState="ngModel" (ngModelChange)="fetchStateLGA(contactInformationFormObject, 'currentAddressState', currentAddressLGASelectService, 'currentAddressLGA', true);" class="effect" required="required" tabindex="13">
                                                <mat-option>
                                                    <ngx-mat-select-search [formControl]="stateSelectService.selectFilterControl" noEntriesFoundLabel="No data found" placeholderLabel="Search" autocomplete="off"></ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let state of stateSelectService.filteredOptions | async" [value]="state.value">{{state.text}}</mat-option>
                                            </mat-select>
                                            <span class="tooltip"><span class='tooltipArrow'></span>Choose state</span>
                                            <span class="focus-border"><i></i></span>
                                        </div>
                                        <span class="alert alert-danger" role="alert" *ngIf="contactInformationForm.submitted && currentAddressState.invalid">
                                            <span *ngIf="currentAddressState.errors.required">
                                                State is required!
                                            </span>
                                        </span>
                                    </div>

                                    <div class="form_box mt15">
                                        <label class="fieldlabels">LGA <span class="medatory">*</span></label>
                                        <div class="input_box">
                                            <mat-select name="currentAddressLGA" hint class="effect" required="required" [(ngModel)]="contactInformationFormObject.currentAddressLGA" #currentAddressLGA="ngModel" tabindex="14">
                                                <mat-option>
                                                    <ngx-mat-select-search [formControl]="currentAddressLGASelectService.selectFilterControl" noEntriesFoundLabel="No data found" placeholderLabel="Search" autocomplete="off"></ngx-mat-select-search>
                                                </mat-option>
                                                <mat-option *ngFor="let lga of currentAddressLGASelectService.filteredOptions | async" [value]="lga.value">{{lga.text}}</mat-option>
                                            </mat-select>
                                            <span class="tooltip"><span class='tooltipArrow'></span>Choose LGA</span>
                                            <span class="focus-border"><i></i></span>
                                        </div>
                                        <span class="alert alert-danger" role="alert" *ngIf="contactInformationForm.submitted && currentAddressLGA.invalid">
                                            <span *ngIf="currentAddressLGA.errors.required">
                                                LGA is required!
                                            </span>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div class="grid-two mt15">
                                <div class="form_box">
                                    <label class="fieldlabels">City / Town <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <input type="text" name="currentAddressCity" hint class="effect" required="required" autocomplete="off" [(ngModel)]="contactInformationFormObject.currentAddressCity" #currentAddressCity="ngModel" tabindex="15" minlength="2" maxlength="60" pattern="^[A-Za-z].*[A-Za-z]$">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Enter your city.</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger" role="alert" *ngIf="contactInformationForm.submitted && currentAddressCity.invalid">
                                        <span *ngIf="currentAddressCity.errors.required">
                                            City / Town is required!
                                        </span>
                                        <span *ngIf="currentAddressCity.errors?.minlength">
                                            City / Town should minimum 2 characters!
                                        </span>
                                        <span *ngIf="!currentAddressCity.errors?.minlength && currentAddressCity.errors?.pattern">
                                            City / Town should start and end with alphabet!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box">
                                    <label class="fieldlabels">Postal Code </label>
                                    <div class="input_box">
                                        <input type="text" name="currentAddressPostalCode" hint numberOnly class="effect" autocomplete="off" [(ngModel)]="contactInformationFormObject.currentAddressPostalCode" #currentAddressPostalCode="ngModel" tabindex="16" minlength="6" maxlength="6">
                                        <span class="tooltip"><span class='tooltipArrow'></span>Enter your postal code</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger" role="alert" *ngIf="contactInformationForm.submitted && currentAddressPostalCode.invalid">
                                        <span *ngIf="currentAddressPostalCode.errors.required">
                                            Postal Code is required!
                                        </span>
                                        <span *ngIf="currentAddressPostalCode.errors?.minlength">
                                            Postal Code should minimum 6 digits!
                                        </span>
                                    </span>
                                </div>

                            </div>

                        </div>

                    </div>

                    <div class="grid-onethird-box">
                        <div class="instruction_box">
                            <h4>Instructions</h4>
                            <ul>
                                <li><span>Enter a valid phone number in the format +234-01234567891.</span></li>
                                <li><span>You are required to verify your application details before making payment.</span></li>
                            </ul>
                        </div>

                    </div>

                </div>

                <br />

                <input type="button" (click)="openPreviousFieldSet()" class="action-button-previous back_btn" value="Previous Page" />
                <input type="submit" (click)="setShallExitApplication(true)" class="save_btn" value="Save & Exit" />
                <input type="submit" (click)="setShallExitApplication(false)" class="action-button custom_btn" value="Continue" />

            </form>
        </fieldset>

        <fieldset *ngIf="applicationStage === 4">
            <div class="step_info">
                <span>Step 4: Next of Kin Information</span>
            </div>

            <form #kinInformationForm="ngForm" (ngSubmit)="kinInformationForm.form.valid && openApplicationPreview($event)" autocomplete="off" name="kinInformationForm" id="msform" appFocus>

                <div class="grid-one-third">

                    <div class="grid-onethird-box">
                        <details>
                            <summary>Instructions for Applicant </summary>
                            <div class="content">
                                <ul>
                                    <li><span>You are required to enter a valid next of kin contact phone number.</span></li>
                                    <li><span>Enter a valid Contact phone number in the format +234-01234567891.</span></li>
                                </ul>

                            </div>
                        </details>
                        <div class="grid-two">

                            <div class="form_box ">
                                <label class="fieldlabels">Name of Next of Kin <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <input type="text" name="kinName" hint name-with-number-directive class="effect" required="required" autocomplete="off" [(ngModel)]="kinInformationFormObject.kinName" #kinName="ngModel" tabindex="1" minlength="2" maxlength="30" pattern="^[A-Za-z].*[A-Za-z0-9]$">
                                    <span class="tooltip"><span class='tooltipArrow'></span>Please fill name of Kin.</span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="kinInformationForm.submitted && kinName.invalid">
                                    <span *ngIf="kinName.errors.required">
                                        Name of Next of Kin is required!
                                    </span>
                                    <span *ngIf="kinName.errors?.minlength">
                                        Name of Next of Kin should minimum 2 characters!
                                    </span>
                                    <span *ngIf="!kinName.errors?.minlength && kinName.errors.pattern">
                                        Name of Next of Kin should start and end with alphabet!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box">
                                <label class="fieldlabels">Relationship <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <select name="kinRelation" hint class="effect" required="required" [(ngModel)]="kinInformationFormObject.kinRelation" #kinRelation="ngModel" tabindex="2">
                                        <option value="">Choose Option</option>
                                        <option [value]="constants.FATHER">Father</option>
                                        <option [value]="constants.MOTHER">Mother</option>
                                        <option [value]="constants.SON">Son</option>
                                        <option [value]="constants.DAUGHTER">Daughter</option>
                                        <option [value]="constants.HUSBAND">Husband</option>
                                        <option [value]="constants.WIFE">Wife</option>
                                        <option [value]="constants.BROTHER">Brother</option>
                                        <option [value]="constants.SISTER">Sister</option>
                                        <option [value]="constants.FRIEND">Friend</option>
                                        <option [value]="constants.RELATIVE">Relative</option>
                                    </select>
                                    <span class="icon_caret"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                                    <span class="tooltip"><span class='tooltipArrow'></span>Please fill relationship with Kin.</span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="kinInformationForm.submitted && kinRelation.invalid">
                                    <span *ngIf="kinRelation.errors.required">
                                        Relationship is required!
                                    </span>
                                </span>
                            </div>
                        </div>

                        <div class="grid-two">

                            <div class="grid-two-box">
                                <div class="form_box h_txt">
                                    <label class="fieldlabels">Address <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <textarea name="addressLine1" hint address-directive class="effect" required="required" autocomplete="off" [(ngModel)]="kinInformationFormObject.addressLine1" #addressLine1="ngModel" tabindex="3" minlength="10" maxlength="450"></textarea>
                                        <span class="tooltip"><span class='tooltipArrow'></span>Enter your permanent addess in Nigeria</span>
                                        <span class="focus-border "><i></i></span>
                                    </div>
                                    <span class="alert alert-danger" role="alert" *ngIf="kinInformationForm.submitted && addressLine1.invalid">
                                        <span *ngIf="addressLine1.errors.required">
                                            Address is required!
                                        </span>
                                        <span *ngIf="addressLine1.errors?.minlength">
                                            Address should minimum 10 characters!
                                        </span>
                                    </span>
                                </div>
                            </div>

                            <div class="grid-two-box">
                                <div class="form_box">
                                    <label class="fieldlabels">State <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <mat-select name="addressState" hint [(ngModel)]="kinInformationFormObject.addressState" #addressState="ngModel" (ngModelChange)="fetchStateLGA(kinInformationFormObject, 'addressState', kinInformationFormLGASelectService, 'addressLGA', true);" class="effect" required="required" tabindex="4">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="stateSelectService.selectFilterControl" noEntriesFoundLabel="No data found" placeholderLabel="Search" autocomplete="off"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let state of stateSelectService.filteredOptions | async" [value]="state.value">{{state.text}}</mat-option>
                                        </mat-select>
                                        <span class="tooltip"><span class='tooltipArrow'></span>Choose state</span>
                                        <span class="focus-border"><i></i></span>
                                    </div>
                                    <span class="alert alert-danger" role="alert" *ngIf="kinInformationForm.submitted && addressState.invalid">
                                        <span *ngIf="addressState.errors.required">
                                            State is required!
                                        </span>
                                    </span>
                                </div>

                                <div class="form_box mt15">
                                    <label class="fieldlabels">LGA <span class="medatory">*</span></label>
                                    <div class="input_box">
                                        <mat-select name="addressLGA" hint class="effect" required="required" [(ngModel)]="kinInformationFormObject.addressLGA" #addressLGA="ngModel" tabindex="5">
                                            <mat-option>
                                                <ngx-mat-select-search [formControl]="kinInformationFormLGASelectService.selectFilterControl" noEntriesFoundLabel="No data found" placeholderLabel="Search" autocomplete="off"></ngx-mat-select-search>
                                            </mat-option>
                                            <mat-option *ngFor="let lga of kinInformationFormLGASelectService.filteredOptions | async" [value]="lga.value">{{lga.text}}</mat-option>
                                        </mat-select>
                                        <span class="tooltip"><span class='tooltipArrow'></span>Choose LGA</span>
                                        <span class="focus-border"><i></i></span>
                                    </div>
                                    <span class="alert alert-danger" role="alert" *ngIf="kinInformationForm.submitted && addressLGA.invalid">
                                        <span *ngIf="addressLGA.errors.required">
                                            LGA is required!
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div class="grid-two mt15">
                            <div class="form_box">
                                <label class="fieldlabels">City / Town <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <input type="text" name="addressCity" hint class="effect" required="required" autocomplete="off" [(ngModel)]="kinInformationFormObject.addressCity" #addressCity="ngModel" tabindex="6" minlength="2" maxlength="60" pattern="^[A-Za-z].*[A-Za-z]$">
                                    <span class="tooltip"><span class='tooltipArrow'></span>Enter your city.</span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="kinInformationForm.submitted && addressCity.invalid">
                                    <span *ngIf="addressCity.errors.required">
                                        City / Town is required!
                                    </span>
                                    <span *ngIf="addressCity.errors?.minlength">
                                        City / Town should minimum 2 characters!
                                    </span>
                                    <span *ngIf="!addressCity.errors?.minlength && addressCity.errors?.pattern">
                                        City / Town should start and end with alphabet!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box">
                                <label class="fieldlabels">Postal Code</label>
                                <div class="input_box">
                                    <input type="text" name="addressPostalCode" hint numberOnly class="effect" autocomplete="off" [(ngModel)]="kinInformationFormObject.addressPostalCode" #addressPostalCode="ngModel" tabindex="7" minlength="6" maxlength="6">
                                    <span class="tooltip"><span class='tooltipArrow'></span>Enter your postal code</span>
                                    <span class="focus-border "><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="kinInformationForm.submitted && addressPostalCode.invalid">
                                    <span *ngIf="addressPostalCode.errors.required">
                                        Postal Code is required!
                                    </span>
                                    <span *ngIf="addressPostalCode.errors?.minlength">
                                        Postal Code should minimum 6 digits!
                                    </span>
                                </span>
                            </div>

                            <div class="form_box">
                                <label class="fieldlabels">Contact Number <span class="medatory">*</span></label>
                                <div class="input_box">
                                    <input type="text" name="mobileNumber" hint numberOnly [(ngModel)]="kinInformationFormObject.mobileNumber" #mobileNumber="ngModel" class="focus_border" required="required" autocomplete="off" tabindex="8" minlength="8" maxlength="11" ng2TelInput [ng2TelInputOptions]="ng2TelInputOptions" (intlTelInputObject)="utils.setMobileNumberFields($event.s, kinInformationFormObject, ['mobileNumberCountryISOCode', 'mobileNumberDialCode'])">
                                    <span class="tooltip"><span class='tooltipArrow'></span>Please write the contact number.</span>
                                    <span class="focus-border"><i></i></span>
                                </div>
                                <span class="alert alert-danger" role="alert" *ngIf="kinInformationForm.submitted && mobileNumber.invalid">
                                    <span *ngIf="mobileNumber.errors.required">
                                        Contact Number is required!
                                    </span>
                                    <span *ngIf="mobileNumber.errors?.minlength">
                                        Contact Number should minimum 8 digits!
                                    </span>
                                </span>
                            </div>

                        </div>

                    </div>

                    <div class="grid-onethird-box">
                        <div class="instruction_box">
                            <h4>Instructions</h4>
                            <ul>
                                <li><span>You are required to enter a valid next of kin contact phone number.</span></li>
                                <li><span>Enter a valid Contact phone number in the format +234-01234567891.</span></li>
                            </ul>
                        </div>

                    </div>

                </div>

                <br />

                <input type="button" (click)="openPreviousFieldSet()" class="action-button-previous back_btn" value="Previous Page" />
                <input type="submit" (click)="setShallExitApplication(true)" class="save_btn" value="Save & Exit" />
                <input type="submit" (click)="setShallExitApplication(false)" class="action-button custom_btn" value="Continue" />

            </form>
        </fieldset>

    </div>

</section>

<app-footer></app-footer>
