import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { SimpleModalService } from 'ngx-simple-modal';
import { Constants } from '../common/constants';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { LocalStorage } from '../common/local-storage';
import { Tags } from '../common/tags';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';
import { ConfirmationModalComponent } from '../confirmation-modal/confirmation-modal.component';
import { ProjectConfig } from '../common/project-config';
import { GUI } from '../common/gui';

@Component({
  selector: 'app-application-view',
  templateUrl: './application-view.component.html',
  styleUrls: ['./application-view.component.css']
})
export class ApplicationViewComponent implements OnInit {

  private encryptedApplicantId : string = this.constants.BLANK;
  
  private encryptedRequestResource : string = this.constants.BLANK;

  requestResource : string = this.constants.BLANK;

  barCodeValue : string = this.constants.BLANK;

  isTermConditionSelect : boolean = false;
  
  isCaptchaVerified : boolean = false;
  
  confirmationButtonClicked : boolean = false;

  isPaymentDetailsExist : boolean = false;

  passportTypeDetailsObject : any = {};

  personalDetailsObject : any = {};

  contactDetailsObject : any = {};

  kinDetailsObject : any = {};

  feeDetailsObject : any = {};

  paymentDetailsObject : any = {};

  private applicationViewForm:HTMLFormElement;

  constructor(private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, private toast: Toast, public constants: Constants, private loaderService: LoaderService, 
              private simpleModalService: SimpleModalService, private localStorage: LocalStorage, private tags: Tags, public utils: Utils, public projectConfig: ProjectConfig,
              public gui: GUI) { 
    activatedRoute.params.subscribe((params: Params) => {
      this.encryptedApplicantId = params['applicantId'];
      this.encryptedRequestResource = params['requestResource'];
    });
    if(this.utils.isEmpty(this.encryptedRequestResource) === false){
      this.requestResource = this.utils.decryptString(this.encryptedRequestResource);
    }
    this.storeApplicationViewData();
  }
 
  ngOnInit(): void {
    this.applicationViewForm = document.forms['applicationViewForm'];
  }

  private storeApplicationViewData(){
    this.loaderService.startLoader();
    this.httpUtils.fetchApplicationViewData(this.utils.decryptString(this.encryptedApplicantId)).then(serverResponse => {
      this.loaderService.stopLoader();
      const serverResponseCode = serverResponse.responseCode;
      if(serverResponseCode === this.constants.STATUS_SUCCESS){
        this.passportTypeDetailsObject = serverResponse.passportDetails;
        this.personalDetailsObject = serverResponse.personalInfo;
        this.personalDetailsObject.paymentStatusImageURL = "assets/images/"+this.personalDetailsObject.paymentStatusImageName;
        this.contactDetailsObject = serverResponse.contactInfo;
        this.kinDetailsObject = serverResponse.kinInformation;
        this.feeDetailsObject = serverResponse.feeDetails;
        this.barCodeValue = this.personalDetailsObject.applicationId;
        this.paymentDetailsObject = serverResponse.paymentDetails;
        this.isPaymentDetailsExist = serverResponse.paymentDetails !== null;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  backToPage(){
    this.gui.openHomePage();
  }

  editApplication(){
    this.gui.openApplicationAddEdit(this.utils.decryptString(this.encryptedApplicantId), this.constants.NEXT_OF_KIN_INFO_STEP);
  }

  showConfirmationForApplicationSubmission(isSaveNow: number){
    this.confirmationButtonClicked = true;
    if(this.isTermConditionSelect === false){
      return;
    }
    let message = "APPLICATION NUMBER <b>"+this.personalDetailsObject.applicationId+"</b> & REFERENCE NUMBER <b>"+this.personalDetailsObject.referenceNumber+"</b> (IMPORTANT - PLEASE WRITE DOWN THE REFERENCE NUMBER AND APPLICATION NUMBER. YOU MAY REQUIRE IT LATER)";
    if(isSaveNow === this.constants.TRUE){
      message = "<span>Note that by pressing OK your application will be submitted and there will be no correction of data after payment. Are you sure you want to continue?</span>"
    }
    this.simpleModalService.addModal(ConfirmationModalComponent, {message: message}, {autoFocus: true}).subscribe((isConfirmed: boolean)=> {
      if(isConfirmed === true){
        this.confirmApplicationSubmission(isSaveNow);
      }
    });
  }

  private confirmApplicationSubmission(isSaveNow: number){
    this.loaderService.startLoader();
    this.httpUtils.confirmApplicationSubmission(this.utils.decryptString(this.encryptedApplicantId), isSaveNow).then(serverResponse => {
      this.loaderService.stopLoader();
      const serverResponseCode = serverResponse.responseCode;
      if(serverResponseCode === this.constants.STATUS_SUCCESS){
        if(isSaveNow === this.constants.FALSE){
          this.gui.openHomePage();
        } else {
          this.gui.openPaymentPage(this.encryptedApplicantId, this.constants.APPLICATION_PAYMENT);
        }
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  printApplication(){
    window.print();
  }

  openPaymentPage(){
    this.gui.openPaymentPage(this.encryptedApplicantId, this.constants.APPLICATION_PAYMENT);
  }

  bookAppointment(){
    const base64String = btoa(this.personalDetailsObject.applicationId + "|" + this.personalDetailsObject.referenceNumber); 
    window.location.href = this.projectConfig.APPOINTMENT_APPLICATION_URL + "?a=" + base64String;
  }
  
  downloadPDF(){
    this.applicationViewForm.action = this.projectConfig.SERVER_BASE_URL + "v1/application/downloadApplicationPDF";
    this.applicationViewForm.downloadType.value = this.constants.RECEIPT_DOWNLOAD;
    this.applicationViewForm.submit();
  }

}