<app-header></app-header>

<div class="banner-img-name"></div>

<div class="main">
  <div class="cod-Data">
    <div class="title_btn">
      <h2>Correction of data in Passport</h2>
      <input type="button" (click)="openHomePage()" class="back_btn" value="Back"/>
    </div>
        <p>To change any data in the <b>Passport</b>, you have to apply for a "<b>Correction of data</b>" and get the specified change done in the personal particulars. To check the complete <a href="javascript:void(0);" style="color:#0d7836;"><b>List of Documents</b></a> to be submitted along with the application form.</p>
        <br>
        <div class="grid-container bk">
              <div (click)="openCODRequestPage()" class="card_box_pass">
                  <h4><a href="javascript:void(0);"><img src="assets/images/icons1.png">Apply For Change Request <i class="fa fa-angle-right link" aria-hidden="true"></i></a></h4>
              </div>

              <div (click)="openCODApprovePage()" class="card_box_pass">
                  <h4><a href="javascript:void(0);"><img src="assets/images/icons2.png">Process Approved Change Request <i class="fa fa-angle-right link" aria-hidden="true"></i></a></h4>
              </div>

              <div (click)="openCODSearchPage()" class="card_box_pass">
                  <h4><a href="javascript:void(0);"><img src="assets/images/icons3.png">Check Change Request Status <i class="fa fa-angle-right link" aria-hidden="true"></i></a></h4>
              </div>
          </div>
          <br>
          <h3 class="heading-green mb30">Correction Due to Marriage:</h3>
          <div class="tabordion">
            <section id="section1">
              <input type="radio" name="sections" id="option1" checked>
              <label for="option1">Married</label>
              <article>
                <ol>
                  <li> Application Letter.  </li>
                  <li> Copy of Marriage Certificate or Declaration of Marriage.</li>
                  <li> Sworn Affidavit from a Magistrate or High Court of Change of Name deposed to by Applicant.</li>
                  <li>Copy of Newspaper Publication of Change of Name (21 days after publication).</li>
                  <li> Statutory evidence of change of name (if resident outside Nigeria in lieu of iii and iv).</li>
                  <li> Passport Booklet and Copy of Data Page of Passport.</li>
                  <li> Acknowledgement Slip & Evidence of Payment.</li>
                </ol>
              </article>
            </section>
            <section id="section2">
              <input type="radio" name="sections" id="option2">
              <label for="option2">Divorced</label>
              <article>
                <ol>
                  <li> Application Letter.</li>
                  <li> Decree Nisi and Decree Absolute or Declaration of Divorce.</li>
                  <li> Evidence of Maiden Name.</li>
                  <li> In case of Re-marriage, Certificate of Marriage with Current Spouse.</li>
                  <li> Sworn Affidavit from a Magistrate or High Court of Change of Name deposed to by Applicant.</li>
                  <li> Copy of Newspaper Publication of Change of Name (21 days after publication).</li>
                  <li> Statutory evidence of change of name (if resident outside Nigeria in lieu of v and vi).</li>
                  <li> Passport Booklet and Copy of Data Page of passport.</li>
                  <li> Acknowledgement Slip & Evidence of Payment.</li>
                </ol>
              </article>
            </section>
            <section id="section3">
              <input type="radio" name="sections" id="option3">
              <label for="option3">Widowed</label>
              <article>
                <ol>
                  <li> Application Letter.</li>
                  <li>Certificate of Death of spouse from National Population Commission (NPC).</li>
                  <li>Evidence of Maiden Name.</li>
                  <li>In case of Re-marriage, Certificate of Marriage with Current Spouse.</li>
                  <li> Sworn Affidavit from a Magistrate or High Court of Change of Name deposed to by Applicant.</li>
                  <li> Copy of Newspaper Publication of Change of Name (21 days after publication).</li>
                  <li> Statutory evidence of change of name (if resident outside Nigeria in lieu of v and vi).</li>
                  <li> Passport Booklet and Copy of Data Page of passport.</li>
                  <li> Acknowledgement Slip & Evidence of Payment.</li>
                </ol>
              </article>
            </section>

          </div>

          <br>
          <hr style="border-style: dashed;border-color: #aeaeae;border-width: 0.5px;border-bottom: none;">
          <br>
          <h3 class="heading-green">Correction due to Error:</h3><br>
          <h4>Due to Correction of Error(s)</h4>
          <ol style="padding-left:20px;">
            <li>Application Letter stating reason(s) for change.</li>
            <li>If more than one [1] alphabet, attach evidence of payment of non-refundable Administrative Charge of NGN 30,000.00 only.</li>
            <li>Previous documentary evidences to support request.</li>
            <li>Passport Booklet and Copy of Data Page.</li>
            <li>Sworn Affidavit from a Magistrate or High Court of correction of error, deposed to by Applicant.</li>
            <li>Acknowledgement Slip & Evidence of Payment of passport fees if application is approved.</li>
          </ol>
          <br>
          <h4 style="display:block;width:auto;float:left;">Others:</h4>
          <ul style="font-family:'sfui-semibold';display:inline-block;padding: 3px 10px;">
            <li>- Replacement of names</li>
              <li>- Addition of names</li>
              <li>- Rearrangement of names</li>
              <li>- Deletion of names</li>
          </ul>
          <ol style="padding-left:20px;">
            <li> Application Letter stating reasons for the request and attached with evidence of payment of non-refundable Administrative Charge of NGN 30,000.00 only.</li>
              <li>Previous documentary evidences to support request.</li>
              <li>Sworn Affidavit from a Magistrate or High Court of Change of Name deposed to by Applicant with passport sized photograph attached.</li>
              <li>Copy of Newspaper Publication of Change of name 21 days after the publication.</li>
              <li>Passport Booklet and Copy of Data Page of passport.</li>
              <li>Acknowledgement Slip & Evidence of Payment of passport fees if application is approved.</li>
          </ol>
          <div style="margin-bottom:60px;"></div>
  </div>
</div>

<app-footer></app-footer>
