import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: '[dateOfBirthInput]'
})
export class DateOfBirthInputDirective {
  
    constructor() { }

    @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent) {
        e.preventDefault();
    }

    @HostListener('copy', ['$event']) blockCopy(e: KeyboardEvent) {
        e.preventDefault();
    }

    @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent) {
        e.preventDefault();
    }

    @HostListener('keydown', ['$event'])
    public onKeydownHandler(keyboardEvent: any): boolean {
        const keyValue = keyboardEvent.charCode? keyboardEvent.charCode : keyboardEvent.keyCode;
        let isValidKey = false;
        if (keyValue == 8 || keyValue == 37 || keyValue == 39 || keyValue == 9 || keyValue == 118){
			isValidKey = true;
		}
        if (keyValue >= 48 && keyValue <= 57) {
            isValidKey = true;
        }
        if (keyValue >= 90 && keyValue <= 105) {
            isValidKey = true;
        }
        return isValidKey;
    }

    @HostListener('keyup', ['$event'])
    public onKeyupHandler(keyboardEvent: any): void {
        const keyValue = keyboardEvent.charCode? keyboardEvent.charCode : keyboardEvent.keyCode;
        if((keyValue >= 48 && keyValue <= 57) || (keyValue >= 90 && keyValue <= 105)){
            let currentValue = keyboardEvent.target.value;
            if(currentValue.length === 2 || currentValue.length === 5){
                keyboardEvent.target.value = currentValue + "/";
            }
        }
    }

}