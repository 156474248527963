import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Constants } from '../common/constants';
import { GUI } from '../common/gui';
import { HttpUtils } from '../common/http-utils';
import { LoaderService } from '../common/loader-service';
import { Toast } from '../common/toast';
import { Utils } from '../common/utils';

@Component({
  selector: 'app-guarantor',
  templateUrl: './guarantor.component.html',
  styleUrls: ['./guarantor.component.css']
})
export class GuarantorComponent {

  private encryptedApplicantId : string = this.constants.BLANK;
  
  guarantorFormDetailsObject : any = {};
  
  scale : number = 0.8;
  
  margin : string = "1cm";

  constructor(private activatedRoute: ActivatedRoute, private httpUtils: HttpUtils, private toast: Toast, public utils: Utils, public constants: Constants, 
              private loaderService: LoaderService, private gui: GUI) {
    activatedRoute.params.subscribe((params: Params) => {
      this.encryptedApplicantId = params['applicantId'];
    });
    this.storeGuarantorFormData();
  }

  private storeGuarantorFormData(){
    this.loaderService.startLoader();
    this.httpUtils.fetchGuarantorFormData(this.utils.decryptString(this.encryptedApplicantId)).then(serverResponse => {
      this.loaderService.stopLoader();
      if(serverResponse.responseCode === this.constants.STATUS_SUCCESS){
        this.guarantorFormDetailsObject = serverResponse;
      } else {
        this.toast.showErrorToast(serverResponse.responseMsg);
      }
    });
  }

  backToSearch(){
    this.gui.openApplicationSearch(this.constants.GUARANTOR_FORM);
  }

  printGurantorForm(){
    window.print();
  }
 
}