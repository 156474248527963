<app-header class="hide-at-print"></app-header>

<div class="banner-img-name hide-at-print"></div>
<div style="width:100%; float:left;">
  <div class="main">
    <h3 class="hide-at-print" style="text-align:center;">Cash At Bank</h3>
    <img src="assets/images/logo-nis.png" alt="logo" style="width:140px;float:left" class="h_logo">
    <h2 style="width:auto;text-align:center;color:#0d7836;">Acknowledgement Slip</h2>

    <div class="pay-recipt">

      <table class="">
        <tr>
          <th width="25%">Transaction No.</th>
          <th width="40%">Application Type</th>
          <th width="18%">Slip Expiry Date</th>
          <th width="17%" style="text-align: right;">Amount to Pay</th>
        </tr>
        <tr>
          <td width="25%" data-label="Transaction No." ><b>{{personalInfoDetailsObject.txnRefNumber}}</b></td>
          <td width="40%" data-label="Application Type"><b>{{passportDetailsObject.passportType}}</b></td>
          <td width="18%" data-label="Slip Expiry Date"><b>{{personalInfoDetailsObject.expiryDate}}</b></td>
          <td width="17%" data-label="Amount to Pay" style="text-align:right;"><b>{{feeDetailsObject.currencySymbol}}{{feeDetailsObject.totalFee}}</b></td>
        </tr>
      </table>

        <div>
          <h4>Application Details</h4>
          <table class="pay-recipt_table">
            <tr>
              <td>Application Number</td>
              <td><b>{{personalInfoDetailsObject.applicationId}}</b></td>
            </tr>
            <tr>
              <td>{{paymentType === constants.APPLICATION_PAYMENT ? 'Reference Number' : 'Passcode'}}</td>
              <td><b>{{personalInfoDetailsObject.referenceNumber}}</b></td>
            </tr>
            <tr>
              <td>Applicant Name</td>
              <td><b>{{personalInfoDetailsObject.fullName}}</b></td>
            </tr>
            <tr>
              <td>Applicant Date Of Birth</td>
              <td><b>{{personalInfoDetailsObject.dateOfBirth}}</b></td>
            </tr>
            <tr>
              <td>Slip Generated on</td>
              <td><b>{{personalInfoDetailsObject.receiptDate}}</b></td>
            </tr>
            <tr>
              <td>Processing Office</td>
              <td><b>{{processingInfoDetailsObject.processingOffice}}</b></td>
            </tr>
          </table>
        </div>
        <div>
          <h4>Payment Details</h4>
          <table class="pay-recipt_table">
            <tr>
              <td>Gateway Name</td>
              <td style="text-align:right;"><b>{{feeDetailsObject.gatewayName}}</b></td>
            </tr>
            <tr>
              <td>Payment Mode</td>
              <td style="text-align:right;"><b>{{feeDetailsObject.paymentMethod}}</b></td>
            </tr>
            <tr>
              <td>Application Charges</td>
              <td style="text-align:right;"><b>{{feeDetailsObject.currencySymbol}}{{feeDetailsObject.bookletFee}}</b></td>
            </tr>
            <tr *ngIf="feeDetailsObject.transactionFee > 0">
              <td>Transaction Fees</td>
              <td style="text-align:right;"><b>{{feeDetailsObject.currencySymbol}}{{feeDetailsObject.transactionFee}}</b></td>
            </tr>
            <tr *ngIf="feeDetailsObject.serviceCharge > 0">
              <td>Service Charge</td>
              <td style="text-align:right;"><b>{{feeDetailsObject.currencySymbol}}{{feeDetailsObject.serviceCharge}}</b></td>
            </tr>
            <tr *ngIf="paymentType === constants.APPLICATION_PAYMENT">
              <td>Address Verification Charges</td>
              <td style="text-align:right;"><b>{{feeDetailsObject.currencySymbol}}{{feeDetailsObject.addressVerificationFee}}</b></td>
            </tr>
            <tr *ngIf="feeDetailsObject.addressVerificationServiceCharge > 0">
              <td>Address Verification Service Charges</td>
              <td style="text-align:right;"><b>{{feeDetailsObject.currencySymbol}}{{feeDetailsObject.addressVerificationServiceCharge}}</b></td>
            </tr>
          </table>
        </div>

      <div class="bank-notes" *ngIf="bankNotesList.length > 0">
        <h5>Bank Note</h5>
        <ul>
          <li *ngFor="let note of bankNotesList">{{note}}</li>
        </ul>
      </div>

      <div class="client-notes" style="margin-bottom:0;" *ngIf="clientNotesList.length > 0">
        <h5>Client Note</h5>
        <ul>
          <li *ngFor="let note of clientNotesList">{{note}}</li>
        </ul>
      </div>

    </div>

  </div>

  <div class="hide-at-print" style="text-align:center; margin-bottom: 25px;">
    <input type="button" (click)="backToPaymentPage()" value="Back" class="previous action-button-previous back_btn">
    <input type="button" (click)="printReceipt()" value="Print" class="next action-button custom_btn">
  </div>
  <br/>
</div>

<app-footer class="hide-at-print"></app-footer>
