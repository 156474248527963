import { Injectable } from '@angular/core';
import { Constants } from './constants';
import { HttpUtils } from './http-utils';
import { LoaderService } from './loader-service';
import { LocalStorage } from './local-storage';
import { Tags } from './tags';
import { Toast } from './toast';
import { Utils } from './utils';

@Injectable({
    providedIn: 'root'
})
export class Captcha {

    constructor(private loaderService: LoaderService, private httpUtils: HttpUtils, private localStorage: LocalStorage, private tags: Tags, private constants: Constants){
    }

    handleCaptchaLoad(formObject: {}){

        this.httpUtils.fetchServerToken().then(serverResponse => {
          const serverResponseCode = serverResponse.responseCode;

              this.loaderService.stopLoader();
              this.localStorage.setStorageKey(this.tags.APPLICATION_TOKEN, serverResponse.bearerToken);
              formObject['captchaVerified'] = true;
              //formObject['captchaVerified'] = true;

      });
    }

    handleCaptchaResponse(formObject: {}, data: any){
        this.loaderService.startLoader();
        this.httpUtils.fetchServerToken().then(serverResponse => {
            const serverResponseCode = serverResponse.responseCode;

                this.loaderService.stopLoader();
                this.localStorage.setStorageKey(this.tags.APPLICATION_TOKEN, serverResponse.bearerToken);
                formObject['captchaVerified'] = true;

        });
    }

    handleCaptchaExpire(formObject: {}){
        formObject['captchaVerified'] = false;
    }

}
