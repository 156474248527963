<app-header class="hide"></app-header>

<div class="center_body">  
    <div class="card">
        <div style="border-radius:200px; height:100px; width:100px; background: #F8FAF5; margin:0 auto; border:1px solid #f0f0f0">
            <i class="checkmark">✓</i>
        </div>

        <h1>SUCCESS</h1> 
         
        <p style="font-weight: bold;"><span>Change request successfully initiated for your application.</span></p>
 
        <div>
            <p style="margin:30px 20px;">Please note down the following details for future correspondence:</p>
            <p><b>Application No:</b>{{applicationNumber}}</p>
            <p><b>Passcode:</b>{{passcode}}</p>
        </div>
 
        <input type="button" (click)="openCODMainPage()" value="Back to Home" class="custom_btn hide">
        <input type="button" (click)="printApplication()" value="Print" class="save_btn hide">
     </div>
 </div> 

<app-footer class="hide"></app-footer>