<nav>

  <div class="main" (window:beforeunload)="doBeforeUnload();" (window:unload)="doUnload();" style="padding-bottom:0!important;">

    <div class="header_box">
        <a href="https://immigration.gov.ng/"><img src="assets/images/logo-nis.png"></a>
        <hr class="header_line">
        <span>Domestic Passport Application Portal</span>
    </div>

    <div class="header_box menu">
        <div  class="hamburger" (click)="callMobileView()">
            <div class="line1"></div>
            <div class="line2"></div>
            <div class="line3"></div>
        </div>

        <ul  class="nav-links">
            <li><a routerLink="">Home</a></li>
            <!-- <li class="track_btn"><a href="javascript:void(0);" (click)="openTrackApplicationForm();">Track Application</a></li> -->
        </ul>
    </div>

  </div>

</nav>
